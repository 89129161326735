import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';
import { AllApisService } from '../all-apis.service'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DashGuard implements CanActivate {
  constructor(private allApis: AllApisService,
    private route: Router,
    private actRoute: ActivatedRoute) { }

  canActivate(): boolean {
    if ((this.allApis.isLoggedIn())) {
      console.log("superParent", (this.allApis.isLoggedIn()))
      // this.route.navigate(["/dashboard"]);
      return true
    }
    else {
      let superParent2 = (this.actRoute, window.location.href).split('/').join(',').split('?').join(',').split('=').join(',').split('&').join(',');
      let superParent = superParent2.split(',');
      console.log("superParentArray", superParent)
      if (superParent.includes('agentCode')) {
        return true
      }else if(superParent.includes('token') && superParent.includes('lat') && superParent.includes('long')){
        return true;
      }
      else {
        superParent.forEach(ele => {
          if (ele === "dashboard") {
            this.route.navigate(["/signup"]);
            console.log("superParent", (this.allApis.isLoggedIn()))
            return false;
          }
        })
      }
    }
  }
}