import { Component, OnInit } from '@angular/core';
import { padhaiPayQueryListService } from './padhaiPayQueryList.service';

declare var $: any;

@Component({
  selector: 'app-padhai-pay-query-list',
  templateUrl: './padhai-pay-query-list.component.html',
  styleUrls: ['./padhai-pay-query-list.component.scss']
})
export class PadhaiPayQueryListComponent implements OnInit {

  constructor(public PPLS:padhaiPayQueryListService) { }

  startDate = new Date().toISOString().substr(0, 10);
  endDate = new Date().toISOString().substr(0, 10);
  coloumns=[];
  queryList=[];
  PPLIST=[];

  ngOnInit() {
    this.coloumns=[   
      { data: 'mobileNo', defaultContent: '<i>-</i>',title:'Contact Number' },
      { data: 'purpose', defaultContent: '<i>-</i>',title:'Purpose' },
      { data: 'name', defaultContent: '<i>-</i>',title:'Name' },
      { data: 'message', defaultContent: '<i>-</i>',title:'Message' },
      { data: 'schoolName', defaultContent: '<i>-</i>',title:'School Name' },
      { data: 'subject', defaultContent: '<i>-</i>',title:'Subject' },
      { data: 'email', defaultContent: '<i>-</i>',title:'Email-Id' },
      { data: 'contactPerson', defaultContent: '<i>-</i>',title:'Contact Person' },
      { data: 'company', defaultContent: '<i>-</i>',title:'Company' },
      { data: 'address', defaultContent: '<i>-</i>',title:'Address' }, 
      { data: 'pinCode', defaultContent: '<i>-</i>',title:'Pinode' }, 
      { data: 'website', defaultContent: '<i>-</i>',title:'Website' },
      { data: 'category', defaultContent: '<i>-</i>',title:'Category' },  
    ]
    this.onSearch();
  }

  onSearch(){
    this.PPLS.getQuery(this.startDate, this.endDate)
    .subscribe(res=>{
      this.queryList=res;

      console.log("result",res)

      var finalResult=[];
     
      this.queryList.forEach(elem=>{
        for(var key in elem){
          if(key!=="_id" && key!=="createdAt" && key!=="updatedAt" && key!=="__v"){
            elem[key].forEach(obj=>{
              var result={};
              result["mobileNo"]=obj["mobileNo"];
              result["purpose"]=obj["purpose"];
              result["name"]=obj["name"];
              result["email"]=obj["email"];
              result["subject"]=obj["subject"];
              result["message"]=obj["message"];
              result["schoolName"]=obj["schoolName"];
              result["contactPerson"]=obj["contactPerson"];
              result["company"]=obj["company"];
              result["address"]=obj["address"];
              result["pinCode"]=obj["pinCode"];
              result["website"]=obj["website"];
              result["category"]=obj["category"];
              finalResult.push(result);
            })
          }
        }
      })
      console.log("queryList",finalResult);
      this.PPLIST=finalResult;
      console.log("PPLIST", this.PPLIST);
      this.populateTable(true);
      });
  }

  populateTable(active:boolean) {

    let self=this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.PPLIST,
        columns: this.coloumns ,
        scrollX: true,
        dom: 'Bfrtip',
   
        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']]
      });
  }

}
