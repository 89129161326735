import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AllApisService } from '../../all-apis.service'

@Injectable({
  providedIn: 'root'
})
export class FineService {

  constructor(private _http:HttpClient,
              private apis:AllApisService) { }

  errorData: {};

getDayWiseFineMeth(session){
  let params = new HttpParams();

  params = params.append('session',session);
//    console.log(params);
  this.apis.httpOptions.params = params;
  
  return this._http.get<any>(this.apis.getPatchDayWiseFine,this.apis.httpOptions).pipe(catchError(this.handleError));;
}

getMonthWiseFineMeth(session){
  let params = new HttpParams();

  params = params.append('session',session);
//    console.log(params);
  this.apis.httpOptions.params = params;
  console.log("jjjjjjjjjj");
  return this._http.get<any>(this.apis.getPatchMonthWiseFine,this.apis.httpOptions).pipe(catchError(this.handleError));;
}

getIntervalWiseMeth(session){
  let params = new HttpParams();

  params = params.append('session',session);
//    console.log(params);
  this.apis.httpOptions.params = params;
  return this._http.get<any>(this.apis.getPatchIntervalWise,this.apis.httpOptions).pipe(catchError(this.handleError));;
}

patchDaywisefineMeth(obj){
  return this._http.patch<any>(this.apis.getPatchDayWiseFine,obj,this.apis.httpOptions).pipe(catchError(this.handleError));;
}

patchMonthWiseFineMeth(obj){
  return this._http.patch<any>(this.apis.getPatchMonthWiseFine,obj,this.apis.httpOptions).pipe(catchError(this.handleError));;
}

patchIntervalWiseMeth(obj){
  return this._http.patch<any>(this.apis.getPatchIntervalWise,obj,this.apis.httpOptions).pipe(catchError(this.handleError));;
}



//  error handling
private handleError(error: HttpErrorResponse) {
  if (error.error instanceof ErrorEvent) {

    // A client-side or network error occurred. Handle it accordingly.

    console.error('An error occurred:', error.error.message);
  } else {

console.log( error);

    // The backend returned an unsuccessful response code.

    // The response body may contain clues as to what went wrong,

    console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
  }

  // return an observable with a user-facing error message

  this.errorData = {
    errorTitle: 'Oops! Request for document failed',
    errorDesc: 'Something bad happened. Please try again later.',
  };
  return throwError(this.errorData);
}
  }
