import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstallmentsComponent } from './installments/installments.component'

@NgModule({
  declarations: [
    InstallmentsComponent 
  ],
  imports: [
    CommonModule
  ],
  exports:[InstallmentsComponent]
})
export class GlobalControllersModule { }
