import { NgModule }      from '@angular/core';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';
import { CommonModule } from '@angular/common';
import { CommonImportsModule} from '../CommonImportsO/common-imports.module'
@NgModule({
  imports: [CommonModule,CommonImportsModule],
  declarations: [ConfirmDialogComponent],
  providers: [ConfirmDialogService],
  exports: [
    ConfirmDialogComponent,
  ]
})
export class SharedModule { }