export class BasicStudentDetail {
    id: string;
    studentName: string;
    std: string;
    admissionNo: string;
    rollNo: string;
    studentCode: string;
    section: string;
    contactNo: string;
    father: string;
    mother: string;
    classSection: string;
    studentSourceId: string;
    schoolId: string;

    constructor(userDetail: any, parentInfo: any = undefined) {
        // Assign input to our object BEFORE deserialize our cars to prevent already deserialized cars from being overwritten.
        Object.assign(this, userDetail);
        if (userDetail) {
            this.id = userDetail._id;
            this.studentName = userDetail.firstName + ' ' + userDetail.lastName
            this.std = userDetail.student.std;
            this.section = userDetail.student.section;
            this.admissionNo = userDetail.student.admissionNo;
            this.rollNo = userDetail.student.rollNo;
            this.studentCode = userDetail.student.studentCode;
            this.studentSourceId = userDetail.sourceId;
            this.schoolId = userDetail.schoolId[0];

            if (parentInfo) {
                this.contactNo = parentInfo.contactNo;
                this.father = parentInfo.fatherName;
                this.mother = parentInfo.motherName;
            }
            this.classSection = this.std + '-' + this.section;
        }



        // Iterate over all cars for our user and map them to a proper `Car` model
        //this.cars = input.cars.map(car => new Car().deserialize(car));

    }
}