import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(function(){
      var from,to,subject,text;
      $("#send_email").click(function(){		
        to=$("#to").val();		
        $("#message").text("Sending E-mail...Please wait");
        $.get("http://localhost:8080/send",{to:to},function(data){
        if(data=="sent")
        {
          $("#message").empty().html("<p>Email is been sent at "+to+" . Please check inbox !</p>");
        }
    
    });
      });
    });
  }

}
