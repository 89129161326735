import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { mapRouteService } from './map-transport.service';
import { routeSchedulingService } from '../CreateRoute/create-route.service';
import { OneStorage } from '../GlobalDataStorage/oneStorage.service';
import { groupBy} from '../shared/group-by.service'
import { NotifierService } from 'angular-notifier';
import * as xlsx from 'xlsx';
import { AllApisService } from '../all-apis.service';
import { OrderPipe } from 'ngx-order-pipe';

declare var $: any;
@Component({
  selector: 'app-map-transport',
  templateUrl: './map-transport.component.html',
  styleUrls: ['./map-transport.component.scss']
})
export class MapTransportComponent implements OnInit {

  @ViewChild('updateRoute', { static: false }) updateRoute: ElementRef;


  order: string = 'name';
  reverse: boolean = false;

  uri = "";
  uploader: FileUploader // = new FileUploader({url:this.uri});
  attachmentList: any = [];
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  mimeType: any;

  //months =["N/A","April","May","June","Jully","August","September"]
  months = ["April", "May", "June", "July", "August", "September", "October", "November", "December", "January", "February", "March"];
  
  allStandard = JSON.parse(localStorage.getItem('allClasses'));
  allSection = JSON.parse(localStorage.getItem('allSection'));
  standard;
  view: boolean = false;
  section;
  studentDetails = [];
  routeDetails = [];
  routeID;
  studentId;
  dropdownSettings={};
  currentStdDetail;
  updatedRouteID;
  session:any;
  sectionObj={};
  routeList = [];
  stopageList = [];
  routeAndStopages:any;
  selectedStopage:any;
  selectedRoute:any;

  sortedCollection: any[];
  // routeAmountNew={};
  constructor(private orderPipe: OrderPipe, private mapRouteServices: mapRouteService, private routeSchedulingServices: routeSchedulingService, public globals: OneStorage, public notifier: NotifierService, public _allApiService: AllApisService) {
    this.sortedCollection = orderPipe.transform(this.studentDetails, 'name');
        console.log(this.sortedCollection);
  }

  updateUsingStand(studentDetails){
    console.log("studentDetailsstudentDetails",studentDetails);
    this.mapRouteServices.updateCycleStandFee1(studentDetails).subscribe(res=>{
      this.notifier.notify('info','Cycle fee updated');
    })

  }

  private callback1(res: {}, that: any) {
    console.log(res);
    that.allStandard = res["standard"];
    that.sectionObj = res["sectionObj"];
    if(that.allStandard[0]){
      that.standard=that.allStandard[0];
      that.allSection=that.sectionObj[that.standard];
      that.section=that.allSection[0];
      console.log("section",that.section);
      that.findRoute();
      
    }
    
  }
  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      // selectAllText: 'All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    if (this.globals.standard === null || this.globals.standard.length === 0) {
      this.globals.initiateStandard(this.callback1, this);
    }
    else {
      this.allStandard = this.globals.standard;
      this.sectionObj = this.globals.sectionObj;
      if(this.allStandard[0]){
        this.standard=this.allStandard[0];
        this.allSection=this.sectionObj[this.standard];
        this.section=this.allSection[0];
        this.findRoute();
      }
    }


    this.routeSchedulingServices.getRouteDetails(this.globals.activeSession).subscribe
      (res => {
        this.routeDetails = res.data;
        this.routeList = this.routeDetails.map(function (el) { return el.name; });
        this.routeList = [...new Set(this.routeList)];
        this.selectedRoute = this.routeList[0];
        this.routeAndStopages = groupBy(this.routeDetails, 'name');
        this.stopageList = []
        this.stopageList.push(...this.routeAndStopages[this.routeList[0]]);
      },
        error => {
          console.log('Error', error);

        });

        this.uri = this._allApiService.baseurl + '/file/uploadFileToUpdateRouteCost';
        //"headers": [{ 'Authorization': 'Bearer ' + localStorage.getItem('token')}]
        this.uploader = new FileUploader({ url: this.uri, authToken: "Bearer " + localStorage.getItem('token') });
        //this.uploader.options.headers  =
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        this.attachmentList.push(JSON.parse(response));
        }
 
  }
  onClassChange(evt: string) {
    this.allSection = this.sectionObj[evt];
    if(this.standard!==undefined && this.section){
       this.findRoute();
    }
  }
  findRoute() {
    this.mapRouteServices.getStudentDetails(this.standard, this.section,this.globals.activeSession).subscribe    /// send current session instead
      (res => {
        this.studentDetails = res.data;
        console.log(this.studentDetails);
        let i;
        for (i = 0; i < this.studentDetails.length; i++) {
          let stdDetail: any = this.studentDetails[i];
          if(stdDetail.routeId) {
            let j;
            for (j = 0; j < this.routeDetails.length; j++) {
              if (this.routeDetails[j]._id === stdDetail.routeId) {
                stdDetail.routeName = this.routeDetails[j].name;
                stdDetail.routeCost = this.routeDetails[j].amount;
                stdDetail.stopage = this.routeDetails[j].stopage;
              }
            }
          }
        }
        // this.notifier.notify('success', 'Data Updated SuccessFully');
      },
        error => {
          console.log('Error', error);
          this.notifier.notify('error', 'Something went wrong.');
        });
  }

  confirmDetails(stdDetail:any) {
    this.currentStdDetail = stdDetail;
    let self=this;
          this.globals.confirmDialogService.confirmThis('Updating Transport Details :-'+stdDetail.name ,  'Want To Update Transport ?', function () {  
            self.saveChangeRoute();
          }, function () {  
          }) 
  }

  generateExcel() {
    const ws: xlsx.WorkSheet =
      xlsx.utils.table_to_sheet(this.updateRoute.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'updateRoute.xlsx');

  }

  changeStopageList(stdDetail, eventTarget) {
      var row = eventTarget.closest('tr');
      
      var selectTarget = $(row).find('select.stopage');
      let stopageList = [];
      stopageList.push(...this.routeAndStopages[eventTarget.value]);
      stdDetail.routeName = eventTarget.value;
      var option = $('<option></option>').attr("value", "").text("--Not Set--");
      selectTarget.empty().append(option);
      stopageList.forEach(function(eve) {
        stopageList
        var option = $('<option></option>').attr("value", eve._id).text(eve.stopage);
        selectTarget.append(option);
      })
  }

  onSearchTextChange() {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable");
    tr = table.getElementsByTagName("tr");
    td = table.getElementsByTagName("td");
  console.log("filterfilter",filter,td.length);
    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
     
        //console.log("heeelo",l);  
       
      td = tr[i].getElementsByTagName("td")[0];
    var   td1 = tr[i].getElementsByTagName("td")[3];
      if (td) {
        txtValue = td.textContent || td.innerText;
       var  txtValue1 = td1.textContent || td1.innerText;
        console.log("txtValue.toUpperCase(),txtValue.toUpperCase()",txtValue.toUpperCase(),txtValue1.toUpperCase());
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        }else
        if (txtValue1.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } 
         else {
          tr[i].style.display = "none";
        }
         
        
      }
    
  }
  }
  updateStopage(stdDetail, eventTarget) {
      stdDetail.routeId = eventTarget.value;
  }

  importExcelForUpdate() {
    $('#routeModel').modal('show');
  }

  saveChangeRoute() {
    console.log(this.currentStdDetail.routeId, this.currentStdDetail.id,
      this.currentStdDetail.inUse,this.currentStdDetail.concession,this.currentStdDetail.skipMonthForTransport)
    this.mapRouteServices.updateRouteDetails(this.currentStdDetail.routeId, this.currentStdDetail.id,this.currentStdDetail.inUse,this.currentStdDetail.concession,
      this.currentStdDetail.skipMonthForTransport  , this.currentStdDetail.skipMonthForCylcleStand,
     this.currentStdDetail. cycleStandConcession ,this.globals.activeSession).subscribe
      (res => {
        let i;
        for (i = 0; i < this.routeDetails.length; i++) {
          if (this.routeDetails[i]._id === this.currentStdDetail.routeId) {
            console.log(this.routeDetails[i]);
            this.currentStdDetail.routeCost = this.routeDetails[i].amount;
            this.currentStdDetail.routeName = this.routeDetails[i].name;
            this.currentStdDetail.stopage = this.routeDetails[i].stopage;
          }
        }
        this.notifier.notify('success', 'Data Updated SuccessFully');
      },
        error => {
          console.log('Error', error);
          this.notifier.notify('error', 'Something went wrong.');
        });
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  


}
