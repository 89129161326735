import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router'
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataTablesModule} from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SideBarSearchComponent } from './side-bar-search/side-bar-search.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DataTablesModule,
    RouterModule,
   
    NgMultiSelectDropDownModule
  ],
  declarations: [
    DashboardComponent,
    SideBarSearchComponent,
  ],
  exports:[
  DashboardComponent,
  // SelectStudentComponent,
  // SelectMonthComponent,
  // GenerateReceiptComponent,
  ]
})
export class DashboardModule { }
