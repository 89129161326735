import { Component, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader} from 'ng2-file-upload';
import { FileService } from '../CollectFeeO/importdata/file.service';
import {saveAs} from 'file-saver';

import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { AllApisService } from 'src/app/all-apis.service';


@Component({
  selector: 'app-import-student-scheduling-excel',
  templateUrl: './import-student-scheduling-excel.component.html',
  styleUrls: ['./import-student-scheduling-excel.component.scss']
})
export class ImportStudentSchedulingExcelComponent {

  uri = "";
uploader:FileUploader // = new FileUploader({url:this.uri});

attachmentList:any = [];


fileData: File = null;
previewUrl: any = null;
fileUploadProgress: string = null;
uploadedFilePath: string = null;
mimeType: any;
constructor( public _allApiService: AllApisService){

this.uri =this._allApiService.baseurl+'/file/uploadSchedulingDueFile';
//"headers": [{ 'Authorization': 'Bearer ' + localStorage.getItem('token')}]
this.uploader = new FileUploader( {url: this.uri , authToken: "Bearer " + localStorage.getItem('token') });
//this.uploader.options.headers  =
  this.uploader.onCompleteItem = (item:any, response:any , status:any, headers:any) => {
      this.attachmentList.push(JSON.parse(response));
  }
}

}
