import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { SiteV2Service } from '../site-v2/site-v2.service';
declare var $:any;

@Component({
  selector: 'app-app-link',
  templateUrl: './app-link.component.html',
  styleUrls: ['./app-link.component.scss']
})
export class AppLinkComponent implements OnInit {

  constructor(public _notify: NotifierService, private serv: SiteV2Service) { }

  ngOnInit() {
    if(navigator.userAgent.match(/Android/i)){                                    
      window.location.href='https://play.google.com/store/apps/details?id=org.school.mitra'
      }
      else if(navigator.userAgent.match(/iPhone|iPad|iPod/i)){                                  
        window.location.href='https://apps.apple.com/in/app/schoolmitra/id1382639822'
      }
  }

  contactDetails(name, number, emailAddress, subject, message) {
    var obj = {};
    obj[number] = {};
    obj[number]["name"] = name;
    obj[number]["mobileNo"] = number;
    obj[number]["email"] = emailAddress;
    obj[number]["subject"] = subject;
    obj[number]["message"] = message;
    obj[number]["purpose"] = "message"
    this.serv.addQuery(obj)
      .subscribe(res => {
        console.log(res);
        this._notify.notify('success', 'Thank you for registering.We will reach out to you soon.');
      },
        err => {
          this._notify.notify('error', 'Please try again');
        }
      )
    console.log(name, emailAddress, subject, message);
    $('#exampleModal').modal('hide');
  }

}
