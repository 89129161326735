import { Component, OnInit } from '@angular/core';
import {SMSUpdatesService} from './smsUpdates.service';
// import { AddUserService} from '../UserDetailsO/AddUser/adduser.service';
import { SenderIdModel } from './ModelClasses/smsUpdatesModel';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sms-updates',
  templateUrl: './sms-updates.component.html',
  styleUrls: ['./sms-updates.component.scss']
})
export class SmsUpdatesComponent implements OnInit {

  constructor(public SenderId:SenderIdModel, private SMSService:SMSUpdatesService, private notify: NotifierService, public router: Router,) { }

  schoolList=[];
  schoolId="all";
  ReadSenderId="NA";
  ReadSMSBalance=0;
  data=[];
  ReadSMEmployeeCode="NA";
  idforUpdate="";
  dropdownSettingsForSchool={};
  TotalSchool=0;
  ngOnInit() {
    this.detailCount(this.idforUpdate);
    this.getSchoolList();
    
    this.dropdownSettingsForSchool = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      allowSearchFilter: true
    };
    this.reset();
}

reset(){
  this.SenderId=new SenderIdModel;
}


detailCount(schoolId:any){
  console.log("schoolId",schoolId.item_id)
  this.idforUpdate=schoolId.item_id;
  this.SMSService.getSchoolName()
  .subscribe(res=>{
    var extra=res;
    var i=0;
    for(i=0; i<extra.length; i++){
      if(extra[i]._id==this.idforUpdate){
        console.log(extra[i]);
        if(extra[i].senderId!=undefined){
    this.ReadSenderId=extra[i].senderId;
        }
        if(extra[i].smsBalance!=undefined){
         this.ReadSMSBalance=Math.round(extra[i].smsBalance);
        }
        if(extra[i].smEmployeeId!=undefined){
       this.ReadSMEmployeeCode=extra[i].smEmployeeId;
        }
      }
      }
  })
}



getSchoolList(){
  this.SMSService.getSchoolName()
  .subscribe(res=>{
    this.schoolList=res;
    this.TotalSchool=this.schoolList.length;
    console.log(this.TotalSchool);
    console.log("schoolList",res);
    var i=0;
    this.data=[];
    for(i=0; i<this.schoolList.length; i++){
      this.data.push({ item_id: this.schoolList[i]._id, item_text: this.schoolList[i].name });
    }
    });
    console.log(this.data);
}


onSenderIdUpdate(){
  this.SenderId._id=this.idforUpdate;
  console.log("model", this.SenderId);
  if(this.SenderId._id!=undefined){
  this.SMSService.updateSchoolDetails(this.SenderId).subscribe(
    res => {
      this.SMSService.getSchoolName()
  .subscribe(res=>{
    var extra=res;
    var i=0;
    for(i=0; i<extra.length; i++){
      if(extra[i]._id==this.idforUpdate){
        console.log(extra[i]);
        if(extra[i].senderId!=undefined){
    this.ReadSenderId=extra[i].senderId;
        }
        if(extra[i].smsBalance!=undefined){
         this.ReadSMSBalance=Math.round(extra[i].smsBalance);
        }
        if(extra[i].smEmployeeId!=undefined){
       this.ReadSMEmployeeCode=extra[i].smEmployeeId;
        }
      }
      }
      this.notify.notify('success', 'Update Successfully');
  })
      console.log(res);
    });
  }
  this.reset();
}
onAddSMSUpdate(){
  this.SenderId._id=this.idforUpdate;
  console.log("model", this.SenderId);
  if(this.SenderId._id!=undefined){
  this.SMSService.updateAddSmsSchoolDetails(this.SenderId).subscribe(
    res => {
      this.SMSService.getSchoolName()
  .subscribe(res=>{
    var extra=res;
    var i=0;
    for(i=0; i<extra.length; i++){
      if(extra[i]._id==this.idforUpdate){
        console.log(extra[i]);
        if(extra[i].senderId!=undefined){
        this.ReadSenderId=extra[i].senderId;
        }
        if(extra[i].smsBalance!=undefined){
         this.ReadSMSBalance=Math.round(extra[i].smsBalance);
        }
        if(extra[i].smEmployeeId!=undefined){
       this.ReadSMEmployeeCode=extra[i].smEmployeeId;
        }
      }
      }
      this.notify.notify('success', 'Update Successfully');
  })
      console.log(res);
    });
  }
  this.reset();
}

School(){
  this.router.navigate(['/dashboard/smsUpdatesList']);
}

}