import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { SiteV2Service } from './site-v2.service';
import { ViewEncapsulation } from '@angular/core';
import { months } from 'moment';

declare var $: any;
@Component({
  selector: 'app-site-v2',
  templateUrl: './site-v2.component.html',
  styleUrls: ['./site-v2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SiteV2Component implements OnInit {
  dropdownSettings = {
    selectAllText: 'All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
  };
  partnerObj=new partner();
  categoryListVar: [];
  categoryList = ['elearning'];
  constructor(public _notify: NotifierService, private serv: SiteV2Service) { }

  ngOnInit() {
    $('.successMessage').css('display', 'none');
    $('#mobValidation1').focus();
    $('#homeBtn').on('click', function (event) {
     console.log('clciked');
     event.preventDefault();
     $('html, body').animate({ scrollTop: 0 }, 'slow');
     return false;
    });

  }
  check1Validation(mobnumber) {

    if (this.validMobileField("mobValidation1")) {
      this.submitNumber(mobnumber);
    }
  }
  check2Validation(mobnumber) {
    if (this.validMobileField("mobValidation2")) {
      this.submitNumber(mobnumber);
    }
  }
  check3Validation(mobnumber, schoolName) {
    if (this.validMobileField("mobValidation3")) {
      this.submitSchool(mobnumber, schoolName);
    }
  }
  check4Validation(name, number, emailAddress, subject, message) {
    if (this.validMobileField("mobValidation4")) {
      this.contactDetails(name, number, emailAddress, subject, message);
    }
  }
  submitNumber(mobnumber) {
    if (this.validMobileField("mobValidation1")) {
      var obj = {};
      obj[mobnumber] = {};
      obj[mobnumber]["mobileNo"] = mobnumber;
      obj[mobnumber]["purpose"] = "early access"
      this.serv.addQuery(obj)
        .subscribe(
          res => {
            console.log(res);
            this._notify.notify('success', 'Your Number Submitted Successfully');
            $('.successMessage').css('display', 'block');
            $('.aboutText').css('display', 'none');
          },
          err => {
            this._notify.notify('error', 'Please try again');
          }
        )
    }

  }


  contactDetails(name, number, emailAddress, subject, message) {
    var obj = {};
    obj[number] = {};
    obj[number]["name"] = name;
    obj[number]["mobileNo"] = number;
    obj[number]["email"] = emailAddress;
    obj[number]["subject"] = subject;
    obj[number]["message"] = message;
    obj[number]["purpose"] = "message"
    this.serv.addQuery(obj)
      .subscribe(res => {
        console.log(res);
        this._notify.notify('success', 'Thank you for registering.We will reach out to you soon.');
      },
        err => {
          this._notify.notify('error', 'Please try again');
        }
      )
    console.log(name, emailAddress, subject, message);
    $('#exampleModal').modal('hide');
  }

  submitSchool(mobumber, schoolName) {
    var obj = {};
    obj[mobumber] = {};
    obj[mobumber]["schoolName"] = schoolName;
    obj[mobumber]["mobileNo"] = mobumber;
    obj[mobumber]["purpose"] = "join";

    this.serv.addQuery(obj)
      .subscribe(res => {
        console.log(res);
        this._notify.notify('success', 'Thank you for registering.We will reach out to you soon.');
      },
        err => {
          this._notify.notify('error', 'Please try again');
        }
      )
    $('#exampleModal').modal('hide');
  }

  validMobileField(field: any) { //
    let that = this;
    //  $(document).ready(()=>{
    let mobNo = $('#' + field).val();
    console.log(mobNo);
    if (mobNo == null || mobNo.length < 10 || mobNo.length > 11) {
      that._notify.notify("error", "Please enter a valid mobile number");
      return false;
    } else {
      return true;
    }
    //  })

  }
  becomePartner(){
    console.log(this.partnerObj);
    if (this.partnerObj["mobileNo"] == null || this.partnerObj["mobileNo"].length < 10 || this.partnerObj["mobileNo"].length > 11) {
      this._notify.notify("error", "Please enter a valid mobile number");
      return false;
    } else {
      this.partnerObj.category=this.categoryList;
      this.partnerObj["purpose"]="partner";
      var obj={};
      obj[this.partnerObj["mobileNo"]]=this.partnerObj;
      this.serv.addQuery(obj)
      .subscribe(res=>{
        console.log(res);
          this._notify.notify('success', 'Thank you for registering.We will reach out to you soon.');
      },
      err=>{
        this._notify.notify('error', 'Please try again');
      })
    }
   

    
  }

}

export class partner{
  mobileNo:string;
  contactPerson:string;
  company:string;
  address:string;
  pinCode:number;
  website:string;
  message:string;
  category:Array<string>;
}