import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../environments/environment";

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class AllApisService {
  constructor(private router: Router , private _http: HttpClient ,) {}
  baseurl = environment.apiHost;
  reporturl = environment.reportHost;
  query = this.baseurl + "/query";

  file1: any
  errorData: {};

  signinUrl = this.baseurl + "/user/login";
  signupUrl = this.baseurl + "/user/admin";
  sendOtp = this.baseurl + "/user/sendOtp";
  verifyOtp = this.baseurl + "/user/verifyOtp";
  resetPass = this.baseurl + "/user/resetPass";
  updateToken = this.baseurl + "/user/updatedToken";

  signInSmUrl = this.baseurl + "/user/sm_login";
  signInWithSMToken = this.baseurl + "/user/sm_authenticate";

  getbalanceSheetreport =
    this.reporturl + "/transactions/getBalanceSheetreport";

  addSchoolUrl = this.baseurl + "/school";
  specialCategory = this.baseurl + "/school/specialCategory";

  fatchSchoolDetailsUrl = this.baseurl + "/school";
  schoolList = this.baseurl + "/school/schoolList";
  schoolNameListUrl = this.baseurl + "/school/display";
  sessionList = this.baseurl + "/school/session";
  editInstallment = this.baseurl + "/school/sessionInstallment";
  editMiscInstallment = this.baseurl + "/school/sessionMiscInstallment";
  addFeeCategoryGroups = this.baseurl + "/school/addFeeCategoryGroups";
  discount = this.baseurl + "/school/discount";

  onlinePayementSetting = this.baseurl + "/school/onlinePayementSetting";

  reportSettingUpdate = this.baseurl + "/configuration/reportSettingUpdate";
  getReportSetting = this.baseurl + "/configuration/reportSettingUpdate";

  commonConcession = this.baseurl + "/school/commonConcession";

  OneTimediscount = this.baseurl + "/school/oneTimeDiscount";
  deleteOneTImeConcession =  this.baseurl + "/school/deleteOneTimeDiscount";
  deleteFeeCategoryGroups = this.baseurl + "/school/deleteFeeCategoryGroups";
  // cahnge to add user @ abhiskek
  addShop = this.baseurl + "/school/addShop";
  addStudentUrl = this.baseurl + "/user";

  addStudent = this.baseurl + "/user/student";

  fatchStudentDetails = "";
  getSudents = this.baseurl + "/user/studentByClass";

  addUser = this.baseurl + "/user";
  fatchUserList = this.baseurl + "/user/userList";
  userRoleListUrl = this.baseurl + "/school/role";

  addFee = this.baseurl + "/feeCollection";
  transferStructureToNextSession =
    this.baseurl + "/feeCollection/transferStructureToNextSession";
  updateFeeDetails = this.baseurl + "/feeCollection";
  fatchFeeDetails = this.baseurl + "/feeCollection/all";
  getFeeCategory = this.baseurl + "/school/feeCategory";
  getInstallments = this.baseurl + "/school/sessionInstallment";
  getsessionMonths = this.baseurl + "/school/sessionMonths"; //sessionMonths
  // add concession
  studentCodeAdmissionNoInfo =
    this.baseurl + "/school/studentCodeAdmissionNoInfo";

  addRTEConcession = this.baseurl + "/school/RTEConcession";
  addStaffConcession = this.baseurl + "/school/StaffConcession";
  addSiblingConcession = this.baseurl + "/school/SiblingConcession";
  addCustomConcession = this.baseurl + "/school/customStudentConcession";
  customeConcession = this.baseurl + "/customeConcession";
  customeConcessionList = this.baseurl + "/customeConcession/list";
  customeConcessionListbyId = this.baseurl + "/customeConcession/listById";
  getStandardConcession = this.baseurl + "/school/standardConcession";
  // fetchFeeDetails=this.baseurl+'/feeCollection/data';
  // fetchFeeParticulars=this.baseurl+'/feeCollection/feeParticular';

  studentListUrl = this.baseurl + "/user/studentList";
  studentSkipMonth = this.baseurl + "/user/skipmonth";
  studentListToPromoteUrl = this.baseurl + "/user/studentListToPromote";
  promoteStudentUrl = this.baseurl + "/user/transfer/nextsession";
  dueStudentUrl = this.baseurl + "/user/dueStudentUrl";
  syncStudentUrl = this.baseurl + "/userSync/syncStudentUrl";
  syncAllStudentUrl = this.baseurl + "/userSync/syncAllStudentUrl";
  studentListReportingUrl = this.reporturl + "/user/reportingstudentList";
  studentListReportingrouteUrl =
    this.baseurl + "/user/reportingstudentList/route";
  studentListReportingUrl2 = this.baseurl + "/user/reportingstudentList2";
  addFeeToStudent = this.baseurl + "/user/addFee";
  getFeeAdjustReport = this.baseurl + "/user/getExtraAddFeeReport";
  getSkipMonthReport = this.baseurl + "/user/getSkipMonthReport";
  addFeeToStudent1 = this.baseurl + "/user/addFee1";
  deleteFeeToStudent = this.baseurl + "/user/deleteFee";
  getSection = this.baseurl + "/school/getSection";
  getClass = this.baseurl + "/school/getStandard";
  tcseting = this.baseurl + "/school/tcseting";
  updateSessionWiseReceiptNumber = this.baseurl + "/school/updateSessionWiseReceiptNumber";
  fetchFeeDetails = this.baseurl + "/feeCollection/data";
  fetchFeeParticulars = this.baseurl + "/feeCollection/feeParticular";
  copyScheduling = this.baseurl + "/feeCollection/copyScheduling";
  createRoute = this.baseurl + "/transport";
  deleteRoute = this.baseurl + "/transport/delete";
  updateRoute = this.baseurl + "/transport/update";
  enableRoute = this.baseurl + "/transport/enable";
  toggleIsDestinationRoute = this.baseurl + "/transport/destination";

  mapRoute = this.baseurl + "/user/userList";

  feeStructure = this.baseurl + "/feeCollection/getFeeStructuree";
  //
  itrReport = this.baseurl + "/feeCollection/itrReport";
  itrReportreciept = this.baseurl + "/feeCollection/itrReport/reciept";
  itrReports = this.baseurl + "/feeCollection/itrReports";
  ///inventory
  addCategory = this.baseurl + "/inventory/addCategory";
  addCategoryName = this.baseurl + "/inventory/addCategoryName";
  getCategory = this.baseurl + "/inventory/getCategory";
  addProduct = this.baseurl + "/inventory";
  createAsset = this.baseurl + "/inventory/createAsset";
  editAssetData = this.baseurl + "/inventory/editAsset";

  getAllProduct = this.baseurl + "/inventory/getSchoolProducts";
  addVariant = this.baseurl + "/variant";
  addShopDistribution = this.baseurl + "/shopData";
  postSupplies = this.baseurl + "/shopData/supplies";
  getSupplyList = this.baseurl + "/shopData/getSupplyReport";
  getSupplier = this.baseurl + "/shopData/supplies";
  addTransaction = this.baseurl + "/inventoryTransaction";
  getStudentPurchased =
    this.baseurl + "/inventoryTransaction/getStudentDetails";
  generateReceipt = this.baseurl + "/inventoryTransaction/generateReceipt";
  voucherReceipt = this.baseurl + "/inventoryTransaction/voucherReceipt";
  cancelReceipt = this.baseurl + "/inventoryTransaction/cancel";
  // getTransaction=this.baseurl+'/inventoryTransactionReport';
  addDueData = this.baseurl + "/inventoryTransaction/addDueAmount";

  //suppliers
  addSupplier = this.baseurl + "/supplier";

  //admission
  enquiryFormUrl = this.baseurl + "/enquiry";

  newEnquiryFormUrl = this.baseurl + "/generatePdf/newEnquiry";
  schoolDetailUrl = this.baseurl + "/generatePdf/schoolDetailUrl";
  mailNewServiceUrl = this.baseurl + "/generatePdf/sendMail";

  registrationFormUrl = this.baseurl + "/registration";
  admissionPageUrl = this.baseurl + "/admission";
  admissionFeeUrl = this.baseurl + "/admissionFee";
  messageUrl = this.baseurl + "/message";
  othersEnquiryUrl = this.baseurl + "/others";
  preAdmissionUrl = this.baseurl + "/preAdmission";
  mailServiceUrl = this.baseurl + "/sendMail";
  generatePdfUrl = this.baseurl + "/filterPdf";

  ///school Expenses
  addSchoolProductDistribution =
    this.baseurl + "/schoolExpense/schoolProductDistribution";
  manageSchoolInventory = this.baseurl + "/schoolExpense/schoolInventory";
  schoolProductTransaction =
    this.baseurl + "/schoolExpense/schoolProductTransaction";
  variantPrice = this.baseurl + "/schoolExpense";
  addExpense = this.baseurl + "/schoolExpense/createExpense";
  updateExpense = this.baseurl + "/schoolExpense/updateExpense";
  delDailyBookTransaction = this.baseurl + "/schoolExpense/delExpense";
  expenseReport = this.baseurl + "/schoolExpense/report";
  ledger = this.baseurl + "/schoolExpense/ledger";

  // asset managemnt
  asset = this.baseurl  + "/asset"
  delAsset = this.baseurl  + "/asset/delete"
  location = this.baseurl  + "/asset/location"
  delLocation = this.baseurl  + "/asset/location/delete"
  addInventoryQunatity = this.baseurl + "/asset/addInventoryQuantity";
  delAssetReport = this.baseurl + "/schoolExpense/deleteAssetReport";


  //Genral Setting For Category
  categoryGenralSetting = this.baseurl+"/asset/categorySetting";

  //Po General Setting
  poGeneralSetting = this.baseurl+"/asset/poSetting";


  //sold assets
  soldAsset = this.baseurl+"/schoolExpense/schoolProductTransaction/soldAsset";
  getSoldAsset = this.baseurl+"/schoolExpense/schoolProductTransaction/getSoldAsset";

  soldOrDonate = this.baseurl+"/schoolExpense/getSoldAssetOnly";
  assetListAdded = this.baseurl+"/schoolExpense/assetListAdded";
  assetListAddedHistoryById = this.baseurl+"/schoolExpense/assetListAddedHistoryById";

  //Asset Category
  fetchAssetCat = this.baseurl+"/asset/allCategory"

  //Vendor
  vendor = this.baseurl+"/asset/vendor";
  itemMaster = this.baseurl+"/asset/itemMaster";

  //Asset Purchase Order
  purchase = this.baseurl+"/asset/purchaseOrder";

  //vendor Quotation
  quotation = this.baseurl+"/asset/vendorQuotation";

  //Purchase Order Details and Vendor Quotation
  poAndQuotationDetails = this.baseurl+"/asset/getPurchaseOrderDetailsWithQuotation";

  //Purchase Order Reports
  poReports = this.baseurl+"/asset/getAllPurchaseOrderReports";
  poReportsByDate = this.baseurl+"/asset/getAllPurchaseOrderReportsByDate";



  getStudent = this.baseurl + "/user/studentNameList";
  getStudentData = this.baseurl + "/user/studentNameListData";
  studentShopFlagCheck = this.baseurl + "/configuration/studentShopFlagCheck";
  //passbook
  passbook = this.baseurl + "/schoolExpense/passbook";
  //Master Settings
  studentcolumnUrl = this.baseurl + "/school/studentColumn";
  presentsessionUrl = this.baseurl + "/school/presentsession";
  activesessionUrl = this.baseurl + "/school/activesession";

  getSmsInfoUrl = this.baseurl + "/school/getSmsInfo";
  getSmsInfoFromSMSUrl = this.baseurl + "/school/getSmsInfoFromSMS";
  getNsetSmsUseInfoUrl = this.baseurl + "/school/getSmsUseInfo";
  getNsetSchedulerSmsUseInfoUrl = this.baseurl + "/school/getSmsSchedulerUseInfo";
  getItrInfo = this.baseurl + "/school/getItrInfo";

  // receipt apis in general settings
  getNsetreceiptInfoUrl = this.baseurl + "/school/receiptInfo1";
  updateReceipt1 = this.baseurl + "/school/receiptNumber1";
  updateTransportReceipt = this.baseurl + "/school/transportReceiptNumber";
  otherReceiptUpdate  = this.baseurl + "/school/otherReceiptInfo";
  //=============//

  updateSchoolShopUrl = this.baseurl + "/school/schoolShopUpdate";
  updateTransportConfigUrl = this.baseurl + "/school/updateTransportConfig";
  updateTransportConfigUrlOther = this.baseurl + "/school/updateTransportConfigOther";

  //fine skip
  updateFineSkip = this.baseurl + "/school/updateFineSkipMonths";
  getFineSkip = this.baseurl + "/school/getFineSkipMonths";
  sendAdmitCardOnCollectFeeUrl =
    this.baseurl + "/configuration/updateOthersInfo";
  sendRouteInfoFeeUrl = this.baseurl + "/configuration/updateRouteInfo";
  sendScholarInfoFeeUrl = this.baseurl + "/configuration/updateScholarInfo";
  sendRemarkInfoFeeUrl = this.baseurl + "/configuration/updateRemarkInfo";
  sendFontSizeInfoUrl = this.baseurl + "/configuration/updateFontSizeInfo";
  sendShoppingForStudentUrl =
    this.baseurl + "/configuration/shoppingForStudent";
  aluminiStudentConfigUrl =
    this.baseurl + "/configuration/aluminiStudentConfig";
  getAdmitCardOnCollectFeeUrl = this.baseurl + "/configuration/getOthersInfo";

  updateFeeValueTypeUrl = this.baseurl + "/configuration/feeValueType";

  getFeeValueTypeUrl = this.baseurl + "/configuration/feeValueType";

  schoolPaymentModuleUrl = this.baseurl + "/school/schoolPaymentModule";
  schoolRoleModuleUrl = this.baseurl + "/school/schoolRoleModule";
  schoolOthersPaymentUrl = this.baseurl + "/school/schoolOthersPayment";

  presetsession = this.baseurl + "/school/presentsession";
  updateSession = this.baseurl + "/user/updateSession";

  session = this.baseurl + "/school/session";

  //addFine
  getPatchDayWiseFine = this.baseurl + "/school/daywisefine";
  getPatchMonthWiseFine = this.baseurl + "/school/monthwisefine";
  getPatchIntervalWise = this.baseurl + "/school/intervalwise";

  rtiReport = this.baseurl + "/school/rtiReport";
  feeRecieptSetting = this.baseurl + "/school/feeRecieptSetting";

  setRoleAccess = this.baseurl + "/role/roleGrants";
  getAllRoles = this.baseurl + "/role/fetchAllRoles";
  changeActivity = this.baseurl + "/role/changeRoleActivity";

  ///////////// Master setting///////////////
  getStudentFields = this.baseurl + "/configuration/getStudentFields";
  updateStudentFields = this.baseurl + "/configuration/updateStudentFields";
  updateFeeTemplate = this.baseurl + "/configuration/updateFeeTemplate";
  updateStudentFields1 =
    this.baseurl + "/configuration/updateStudentFieldsForStudentlist";

  updateDoc = this.baseurl + "/school/uploadEDoc"

  addNewDOc = this.baseurl + "/school/addNewDoc"

  updateNewDOc = this.baseurl + "/school/updateNewDoc"

  deleteDoc = this.baseurl + "/school/deleteDoc"

  getDOc = this.baseurl + "/school/getDoc"
  
  //Communication scheduler
  SMSScheduler = this.baseurl + "/school/SMSSettingsScheduler";
  SMSSchedulerCurrent = this.baseurl + "/school/SMSSettingsCurrentScheduler";
  mailScheduler = this.baseurl + "/school/mailSettingsScheduler";
  notificationScheduler = this.baseurl + "/school/notificationSettingsScheduler";
  SMSTemplateBySenderId = this.baseurl + "/school/fetchTemplateBySenderId";

  /////////////Token//////////////////////////////////
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: "Bearer " + this.getToken(),
      //localStorage.getItem('token')
      // 'ID': emp.UserID,
    }),
    params: new HttpParams(),
  };

  updateTokenFun() {
    console.log("this", this.getToken());
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.getToken(),
        //localStorage.getItem('token')
        // 'ID': emp.UserID,
      }),
      params: new HttpParams(),
    };
  }

  /*  to remove the token from the local storage */
  logoutUser() {
    localStorage.removeItem("token");
    localStorage.removeItem("userDetail");
    localStorage.clear();
    // this.httpOptions=null;
    //this.router.navigate(["/signin"]);
    window.location.href = "https://schoolmitra.com/users/force_logout";
  }

  /* to retrive token to send to the server */
  getToken() {
    return localStorage.getItem("token");
  }

  /* to check token is present in local storage */
  isLoggedIn() {
    return !!localStorage.getItem("token"); /* true or false */
  }

  getSchoolId() {
    return localStorage.getItem("schoolId");
  }

  getDocs(session:string) {
    let params = new HttpParams();
    params = params.append("session", session);
    this.httpOptions.params = params;
    return this._http
      .get<any>(this.getDOc, this.httpOptions)
      .pipe(catchError(this.handleError));
  }


  uploadEdoc(reqFile: any) {
    let formData = new FormData();
    if (reqFile) {
      formData.append("content_file", reqFile);
    }
    return this._http.post<any>(
      this.updateDoc,
      formData,
      this.httpOptions
    ).pipe(catchError(this.handleError));
  }

 

  addNewEDoc(body){
    return this._http.post<any>(
      this.addNewDOc,
      body,
      this.httpOptions
    ).pipe(catchError(this.handleError));
  }

  updateNewEDoc( docId , title ){
    return this._http.patch<any>(
      this.updateNewDOc,
      {docId : docId , title : title},
      this.httpOptions
    ).pipe(catchError(this.handleError));
  }

  deleteEDoc(docId){
    console.log("this hgere", docId)
    return this._http.patch<any>(
      this.deleteDoc,
      {docId : docId},
      this.httpOptions
    ).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.',
    };
    return throwError(this.errorData);
  }
}
