import { Injectable, ɵConsole } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AllApisService } from '../all-apis.service';
@Injectable({
  providedIn: 'root'
})
export class mapRouteService {
  constructor(private _http: HttpClient, private allApis: AllApisService) { }
  /* mapRoute = this.allApis.mapRoute; */
  mapRoute = this.allApis.baseurl+'/user/studentList/transport';
  mapRoute1 = this.allApis.baseurl+'/user/studentList/transport1';
  mapRoute2 = this.allApis.baseurl+'/user/studentList/transport2';
  updateCycleStandFee= this.allApis.baseurl+'/user/updateCycleStandFee';

  getStudentLedger = this.allApis.baseurl+'/user/studentLedger';
  
  updateRoute =this.allApis.baseurl +'/user/addtransport';
  deleteUserRoute=this.allApis.baseurl +'/user/toggle';
  errorData: {};
  
  getStudentDetails(standard: string, section: string, session:any) {
    let params = new HttpParams();
    params = params.append('class', standard);
    params = params.append('section', section);
    params = params.append('session', session);
    this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.mapRoute, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  getStudentDetails1(standard: string, section: string, session:any) {
    let params = new HttpParams();
    params = params.append('class', standard);
    params = params.append('section', section);
    params = params.append('session', session);
    this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.mapRoute1, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  getStudentLedgerDetails(standard: string, section: string, session:any , userId : string) {
    let params = new HttpParams();
    params = params.append('class', standard);
    params = params.append('section', section);
    params = params.append('session', session);
    params = params.append('userId', userId);
    this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.getStudentLedger, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  getStudentDetails2(standard: string, section: string, session:any) {
    let params = new HttpParams();
    params = params.append('class', standard);
    params = params.append('section', section);
    params = params.append('session', session);
    this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.mapRoute2, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  deleteUser(userId:any, isDeleted: boolean) {
    return this._http.patch<any>(this.deleteUserRoute, { 'userId': userId, isDeleted: isDeleted}, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  updateCycleStandFee1(value) {
    return this._http.patch<any>(this.updateCycleStandFee, { 'value':value}, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  updateRouteDetails(routeID: string, selectedstudentID: string,check:any,concession:any,
    skipMonthForTransport :any,skipMonthForCylcleStand,
    cycleStandConcession,session) {
    // tslint:disable-next-line: max-line-length
    return this._http.patch<any>(this.updateRoute, { 'routeId': routeID, 
    'studentId': selectedstudentID,'inUse':check,'concession':concession 
    ,skipMonthForTransport:skipMonthForTransport,skipMonthForCylcleStand:skipMonthForCylcleStand,
    cycleStandConcession:cycleStandConcession,'session':session}, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  // error handling
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
}
