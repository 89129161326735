import { Component, OnInit } from '@angular/core';
import { SMSUpdatesService } from '../sms-updates/smsUpdates.service';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { MerchantService } from 'src/app/merchant-module/merchantService/merchant.service';
import { paymentModel } from '../modelClasses/paymentModule';

declare var $: any;

@Component({
  selector: 'app-sm-agent',
  templateUrl: './sm-agent.component.html',
  styleUrls: ['./sm-agent.component.scss']
})
export class SmAgentComponent implements OnInit {

  constructor(public merchantService: MerchantService, private SMSService: SMSUpdatesService, private notify: NotifierService, public router: Router, public moduleObject: paymentModel) { }

  agentList = [];
  coloumns = [];
  agentCode:any;
  agentName:any;



  ngOnInit() {

    this.coloumns = [
      { data: 'name', defaultContent: '<i>Not Set</i>', title: 'Agent Name' },
      { data: 'agentCode', defaultContent: '<i>Not Set</i>', title: 'Agent Code' },
    ]
    this.getAgentList();
  }

  addAgent(){
    let obj={};
    obj["name"] = this.agentName;
    obj["agentCode"] = this.agentCode;
    this.SMSService.postAgentName(obj)
    .subscribe(res => {
      if (res["status"]) {
        this.notify.notify("success", "Agent Added Successfully");
      }
    }, error => {
      this.notify.notify("error", "Oops! Network Error.");
    });
  }

  getAgentList() {
    this.SMSService.getAgentName()
      .subscribe(res => {
        if (res["status"]) {
          this.agentList = res["result"]
          this.notify.notify("success", "Get Agent List Successfully");
          this.populateTable(true);
        }
      }, error => {
        this.notify.notify("error", "Oops! Network Error.");
      });
  }

  populateTable(active: boolean) {

    let self = this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.agentList,
        columns: this.coloumns,
        scrollX: true,
        dom: 'Bfrtip',

        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, 'All']]
      });
  }

}
