import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router
} from "@angular/router";
import {
  UserService
} from '../Services/user.service';
import {
  SignInModel
} from '../ModelClasses/signinModel';
import {
  AllApisService
} from '../../all-apis.service';
import { NotifierService } from "angular-notifier";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import {OneStorage} from '../../GlobalDataStorage/oneStorage.service';


@Component({
  selector: 'app-signin',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css', '../loginstyle.css']
})
export class SignInComponent implements OnInit {
  validLoginId: boolean;

  constructor(private router: Router,private userService: UserService,
    private allApies: AllApisService,
    private notifier:NotifierService,
    private oneStorage:OneStorage) {}   //, private global:OneStorage


  signInModel = new SignInModel()
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  serverErrorMessages: string;
  //server Data
  sData: Object;
  role:string;
  ngOnInit() {
    // if(this.userService.isLoggedIn())
    // this.router.navigateByUrl('/userprofile');
  }

  validateInput() {
    var val = this.signInModel.emailId;
    if(val.match(this.emailRegex) || (/^\d{10}$/.test(val))) {
      this.validLoginId = true;
     } else {
      this.validLoginId = false;
      }

    //return val.match(this.emailRegex) || val.match("other regex") && dosomething();
  }

  //form method (html)
  onLogin() {
    this.userService.logMeIn(this.signInModel)
      .subscribe(res => {
          const isSucess = res.msg;
          console.log(res);
          //  console.log('token : ' + res.token);
          this.role=res.role;
          if (res.token !== null && this.signInModel.password !== null) {
            localStorage.setItem('token', res.token);

            //Initilization Token
            let headers= new HttpHeaders({
              'Authorization': 'Bearer ' + this.allApies.getToken()
            });
            this.allApies.httpOptions.headers=headers;
            console.log(this.allApies.httpOptions);

          }else{
            this.notifier.notify("error","Invalid E-mail/Password");
          }
        },
        error => {
          console.log('Error', error)
          this.notifier.notify("info","Oops! Network Error.");
             },
        () =>{
           //To initilize Storage;
          this.oneStorage.getFeecCatagory();
          this.oneStorage.storeData();
          localStorage.setItem("userType",this.role);
          if(this.role==="Super Admin"){
            this.router.navigate(['/dashboard/welcomeScreen']);
          }
          else if(this.role==="Admission In-charge"){
            this.router.navigate(['/dashboard/admission']);
          }
          else if(this.role==="PPAdmin"){
            this.router.navigate(['/dashboard/smsUpdates']);
          }
          else{
            this.router.navigate(['/dashboard/collect/CollectFeeMiscComponent']);
          }
             }

      )
  }
  backToHome(){
    console.log('clicked');
    this.router.navigate(['']);
  }



  //   private fatchUserRoles(){
  //   this.userService.getUserRoles().subscribe(
  //     res=>{
  //    console.log("User role Names: ",res);
  //     },
  //     err=>{
  //       console.log("User role Error: ",err);
  //     }
  //   )
  // }


  onSignup() {
    this.router.navigate(["/signup"]);
    console.log(this.allApies.addSchoolUrl);
  }

  //  if(this.model.email == "name@gmail.com" && this.model.password == "aaaa") {
  //   this.router.navigateByUrl('/dashboard')
  //   console.log("Working  ..............."+ this.model.email);;
  //  }


}
