import { Component, OnInit } from '@angular/core';
import {SMSUpdatesService} from '../sms-updates/smsUpdates.service';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-online-payment',
  templateUrl: './online-transaction.component.html',
  styleUrls: ['./online-transaction.component.scss']
})
export class OnlineTransactionComponent implements OnInit {

  constructor(private SMSService:SMSUpdatesService, private notify: NotifierService, public router: Router) { }

  paymentList=[];
  TotalSchool=0;
  coloumns=[];
  SchoolName="";

  startDate = new Date().toISOString().substr(0, 10);
  endDate = new Date().toISOString().substr(0, 10);

  ngOnInit() {

    let action_btn = 'No Update';
    
    this.coloumns=[   
      { data: 'paymentId', defaultContent: '<i>Not Set</i>',title:'Ref Id' },
      { data: 'razorpay_payment_id', defaultContent: '<i>Not Set</i>',title:'Razorpay Payment Id' },
      { data: 'razorpay_order_id', defaultContent: '<i>Not Set</i>',title:'Razorpay Order Id' },
      { data: 'amount', defaultContent: '<i>Not Set</i>',title:'Amount' },
      { data: 'name', defaultContent: '<i>Not Set</i>',title:'Name' },
      { data: 'email', defaultContent: '<i>Not Set</i>',title:'Email' },
      { data: 'contact', defaultContent: '<i>Not Set</i>',title:'Contact' },
      { data: 'merchantName', defaultContent: '<i>Not Set</i>',title:'Merchant Name' },
      { data: 'payObjName', defaultContent: '<i>Not Set</i>',title:'Pay Obj Name' },
      { data: 'payStatus', defaultContent: '<i>Not Set</i>',title:'Pay Status' },
      { data: 'testMode', defaultContent: '<i>Not Set</i>',title:'Test Mode' },
      { data: 'createdAt', defaultContent: '<i>Not Set</i>',title:'Time' },
      {
        data: 'paymentUpdate',
        className: 'center',
        defaultContent: action_btn
      },  
    ]
    this.getPaymentList();
  }

  getPaymentList(){
    console.log("pre fetch",this.paymentList)
    this.SMSService.getPaymentList(this.startDate, this.endDate)
    .subscribe(res=>{
      this.paymentList=res;
      console.log("schoolist",this.paymentList)
      this.populateTable(true);
      });
  }

  populateTable(active:boolean) {

    let self=this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.paymentList,
        columns: this.coloumns ,
        scrollX: true,
        dom: 'Bfrtip',
   
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, 'All']]
      });
      $('.example').on('click', 'a.Update', function () {
        console.log("clicked............");
       var p = this.closest('tr');
       var data = $('.example').DataTable().row(p).index();
       self.updateOnlinePayment(self.paymentList[data].paymentId);
     });
  }

  updateOnlinePayment(id:any){

    console.log("paymentId", id);

    var obj={}

    obj["paymentId"] = id;

    this.SMSService.updateOnlinePaymentStatus(obj).subscribe(
      res =>{
        console.log("res",res);
        this.notify.notify("info", 'Successful Updated');
      }
    )
  }

  SMSUpdates(){
    this.router.navigate(['/dashboard/smsUpdates']);
  }


}
