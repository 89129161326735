import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transaction-history-transport',
  templateUrl: './transaction-history-transport.component.html',
  styleUrls: ['./transaction-history-transport.component.scss']
})
export class TransactionHistoryTransportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
