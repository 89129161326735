/* Group object array by property
* Example, groupBy(array, 'property');
* @param array
* @param property
*/
export const groupBy = <T>(array: Array<T>, property: string): { [key: string]: Array<T> } => {
 return array.reduce((memo: { [key: string]: Array<T> }, x: T) => {
   if (!memo[x[property]]) {
     memo[x[property]] = [];
   }
   memo[x[property]].push(x);
   return memo;
 }, {});
};

export default groupBy;