import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { AllApisService } from '../../all-apis.service'

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {
  role=this.allApis.setRoleAccess;
  fetchAllRoles=this.allApis.getAllRoles;
  roleGrants: any;
  constructor(private _http:HttpClient,
              private allApis:AllApisService) { }
          
 addRoleAccess(obj:any,evt:string,schoolId:any){
  let params=new HttpParams();
  params=params.append('role',evt);
  params=params.append('schoolId',schoolId);
  this.allApis.httpOptions.params=params;
   return this._http.post<any>(this.role,obj,this.allApis.httpOptions)
 } 
 getRoleAccess(evt:string){
   let params=new HttpParams();
   params=params.append('role',evt);
   this.allApis.httpOptions.params=params;
  return this._http.get<any>(this.role,this.allApis.httpOptions)
} 
fetchRoles(schoolId:any){
   this.allApis.httpOptions.params=new HttpParams();
  let params=new HttpParams();
  params=params.append('schoolId',schoolId);
  this.allApis.httpOptions.params=params;
  return this._http.get<any>(this.fetchAllRoles,this.allApis.httpOptions);
}
            
}
