import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { UserService } from '../../SigninSignoutO/Services/user.service';
import { AllApisService } from '../../all-apis.service';
import { OneStorage } from '../../GlobalDataStorage/oneStorage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessControlService } from '../../MasterSettingO/rollAccess/access-control.service';
import { AddStudentService } from 'src/app/admission/services/add-student.service';
import { MasterServiceService } from 'src/app/MasterSettingO/Services/master-service.service';
declare var $: any;

@Component({
  selector: 'app-side-bar-search',
  templateUrl: './side-bar-search.component.html',
  styleUrls: ['./side-bar-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SideBarSearchComponent implements OnInit {
  preadmisionShow=true;
  isAgentCode = true;
  isParentAdmission = true;
  agentCode:any;
  lat:any;
  long:any;
  sideBarVar = true;
  constructor(private userService: UserService,
    private allapis: AllApisService,
    private router: Router,
    private roleServ: AccessControlService,
    public addStudentService: AddStudentService,
    public globals: OneStorage,//take this to the 'signinComponent'
    private maservice:MasterServiceService,
    public route: ActivatedRoute,
  ) {
    //console.log("userDetail(from deshboard)",this.userDetail);
  };


  
  ngOnInit() {

    if (this.route.queryParams) {
      this.route.queryParams.subscribe(params => {
        console.log('side',params.agentCode)
        if (params.agentCode) {
          this.isAgentCode = false;
          this.agentCode = params.agentCode;
          for(var key in this.map){
            this.map[key]=false;
          }
          this.preadmisionShow=false;
          this.map["agentSchool"] = true;
          return;
        }
        if (params.token) {
          localStorage.setItem('token', params.token);

        }
        if (params.lat && params.long){
          for (var key in this.map) {
            this.map[key] = false;
          }
          this.isAgentCode = false;
          this.lat = params.lat;
          this.long = params.long;
          this.map["parentAdmission"] = true;
        }
      })
    }
    this.checknavInitial();


    $('.br-sideleft').addClass(localStorage.getItem('sideleftColor'));
    $('.br-menu-sub').addClass(localStorage.getItem('menusubColor'));
    $('.br-menu-link').addClass(localStorage.getItem('hoverColor'));
    $('.br-menu-link.active').addClass(localStorage.getItem('menusubActiveColor'));
    $('.ec-handle').addClass(localStorage.getItem('themeBtnColor'));
    $('.user-profile-nav .icon ').addClass(localStorage.getItem('headerCtrColor'));
    $('.br-header-center .defaultHdrColr').addClass(localStorage.getItem('headerCtrColor'));
    $('.br-header-left .navicon-left .menu li a i').addClass(localStorage.getItem('headerCtrColor'));
    $('.currentDate').addClass(localStorage.getItem('headerCtrColor'));
    $('.show-sub + .br-menu-sub').slideDown();
    $('#btnLeftMenu').on('click', function () {
      var menuText = $('.menu-item-label,.menu-item-arrow');
      if ($('body').hasClass('collapsed-menu')) {
        $('body').removeClass('collapsed-menu');
        $('.sidebar-footer').css('visibility', 'visible');
        $('.show-sub + .br-menu-sub').slideDown();
        $('.br-sideleft').one('transitionend', function (e) {
          menuText.removeClass('op-lg-0-force');
          menuText.removeClass('d-lg-none');
        });
      } else {
        $('body').addClass('collapsed-menu');
        $('.show-sub + .br-menu-sub').slideUp();
        menuText.addClass('op-lg-0-force');
        $('.br-sideleft').one('transitionend', function (e) {
          menuText.addClass('d-lg-none');
        });
      }
      return false;
    });
    $(document).on('mouseover', function (e) {
      e.stopPropagation();
      if ($('body').hasClass('collapsed-menu') && $('#btnLeftMenu').is(':visible')) {
        var targ = $(e.target).closest('.br-sideleft').length;
        if (targ) {
          $('body').addClass('expand-menu');
          // show current shown sub menu that was hidden from collapsed
          $('.show-sub + .br-menu-sub').slideDown();
          var menuText = $('.menu-item-label,.menu-item-arrow');
          menuText.removeClass('d-lg-none');
          menuText.removeClass('op-lg-0-force');
          $('.sidebar-footer').css('visibility', 'visible');
        } else {
          $('body').removeClass('expand-menu');
          // hide current shown menu
          $('.show-sub + .br-menu-sub').slideUp();
          var menuText = $('.menu-item-label,.menu-item-arrow');
          menuText.addClass('op-lg-0-force');
          menuText.addClass('d-lg-none');
          $('.sidebar-footer').css('visibility', 'hidden');
        }
      }
    });

    $('#btnLeftMenuMobile').on('click', function () {
      $('body').addClass('show-left');
      return false;
    });
    $(document).on('click', function (e) {
      e.stopPropagation();
      // closing left sidebar
      if ($('body').hasClass('show-left')) {
        var targ = $(e.target).closest('.br-sideleft').length;
        if (!targ) {
          $('body').removeClass('show-left');
        }
      }
      // closing right sidebar
      if ($('body').hasClass('show-right')) {
        var targ = $(e.target).closest('.br-sideright').length;
        if (!targ) {
          $('body').removeClass('show-right');
        }
      }
    });

    $('.js-search').click(function () {

      $('.search-bar').addClass('open');
    });
    $('.close-search').click(function () {

      $('.search-bar').removeClass('open');
    });

    (function ($) {
      "use strict";
      $('.ec-handle').on('click', function () {
        $('.ec-colorswitcher').trigger('click')
        $(this).toggleClass('btnclose');
        $('.ec-colorswitcher').toggleClass('sidebarmain');
        return false;
      });

    })($);

    $('.styleswitch').click(function () {
      switchStylestyle(this.getAttribute("data-rel"));
      return false;
    });

    function switchStylestyle(styleName) {

      var sideleftColrArr = ['color-one', 'color-two', 'color-three', 'color-four'];
      const menusubColrArr = ['cs-menu-one', 'cs-menu-two', 'cs-menu-three', 'cs-menu-four'];
      const menusubActiveColorArr = ['active-menu-one', 'active-menu-two', 'active-menu-three', 'active-menu-four'];
      const hoverColArr = ['hover-menu-one', 'hover-menu-two', 'hover-menu-three', 'hover-menu-four'];
      const themeBtnColArr = ['color-theme-one', 'color-theme-two', 'color-theme-three', 'color-theme-four'];
      const hederCtrColorArr = ['active-hdrCtr-one', 'active-hdrCtr-two', 'active-hdrCtr-three', 'active-hdrCtr-four'];
      for (var i = 0; i < sideleftColrArr.length; i++) {
        if (styleName === sideleftColrArr[i]) {
          if (typeof (Storage) !== 'undefined') {
            localStorage.setItem('sideleftColor', sideleftColrArr[i]);
            localStorage.setItem('menusubColor', menusubColrArr[i]);
            localStorage.setItem('hoverColor', hoverColArr[i]);
            localStorage.setItem('menusubActiveColor', menusubActiveColorArr[i]);
            localStorage.setItem('themeBtnColor', themeBtnColArr[i]);
            localStorage.setItem('headerCtrColor', hederCtrColorArr[i]);
          }
          $('.br-sideleft').addClass(sideleftColrArr[i]);
          $('.br-menu-sub').addClass(menusubColrArr[i]);
          $('.br-menu-link').addClass(hoverColArr[i]);
          $('.br-menu-link.active').addClass(menusubActiveColorArr[i]);
          $('.ec-handle').addClass(themeBtnColArr[i]);
          $('.user-profile-nav .icon ').addClass(hederCtrColorArr[i]);
          $('.br-header-center .defaultHdrColr').addClass(hederCtrColorArr[i]);
          $('.br-header-left .navicon-left .menu li a i').addClass(hederCtrColorArr[i]);
          $('.currentDate').addClass(hederCtrColorArr[i]);
        } else {
          $('.br-sideleft').removeClass(sideleftColrArr[i]);
          $('.br-menu-sub').removeClass(menusubColrArr[i]);
          $('.br-menu-link').removeClass(hoverColArr[i]);
          $('.br-menu-link.active').removeClass(menusubActiveColorArr[i]);
          $('.ec-handle').removeClass(themeBtnColArr[i]);
          $('.user-profile-nav .icon ').removeClass(hederCtrColorArr[i]);
          $('.br-header-center .defaultHdrColr').removeClass(hederCtrColorArr[i]);
          $('.br-header-left .navicon-left .menu li a i').removeClass(hederCtrColorArr[i]);
          $('.currentDate').removeClass(hederCtrColorArr[i]);
        }

      }

    }
    $('#btnAppsMenu').on('click', function () {
      console.log('Showing AppMenu...');
    });
    var date = new Date();
    var months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL',
      'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
    ];
    var day = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    var val = day[date.getDay()] + "," + date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
    $('.currentDate').text(val);


  }
  schoolListParent(val:any){
    this.router.navigate(['/preAdmission/admission/searchSchool/'], { queryParams: { lat: this.lat, long: this.long } });
    this.clickOnNavigation(val)
  }
  statusParent(val:any){
    this.router.navigate(['/preAdmission/admission/parentStudentStatus/'], { queryParams: { lat: this.lat, long: this.long } });
    this.clickOnNavigation(val)
  }
  sideAddSchool(){
    this.router.navigate(['/preAdmission/admission/addSchool/'], { queryParams: { agentCode: this.agentCode } });
  }

  searchSchool() {
    this.router.navigate(['/preAdmission/admission/schoolListForAgent/'], { queryParams: { agentCode: this.agentCode } });
  }

  cd() {
    console.log(this.sideBarVar);
  }

  checknavInitial() {
    let path = window.location.href.split('#')[1].split('/')[2];
    console.log(path);
    let classObj = {

      trackApplication: "activeIcon2",

    }
    let activeCls = "activeIcon1"
    if (classObj[path]) {
      activeCls = classObj[path]
    }
    console.log(path, "current path");
    this.clickOnNavigation(activeCls)
  }
  clickOnNavigation(key) {
    let imageDeactivateObj = {
      activeIcon1: "../../../assets/searchSchool/schoolList_deactive.png",
      activeIcon2: "../../../assets/searchSchool/track_deactive.png",
    }
    let imageObj = {
      activeIcon1: "../../../assets/searchSchool/schoolList.png",
      activeIcon2: "../../../assets/searchSchool/track.png"
    }
    console.log(key)
    for (let key1 of Object.keys(imageDeactivateObj)) {
      $('.' + key1 + ' img').prop('src', imageDeactivateObj[key1]);
      $('.' + key1).removeClass('active');
    }
    $('.' + key + ' img').prop('src', imageObj[key]);
    $('.' + key).addClass('active');
  }

  onSignOut() {
    this.globals.destroyVariables();
    this.allapis.logoutUser();
    //this.router.navigate(['']);
  }

  changeIcon() {
    if (this.sideBarVar === true) {
      this.sideBarVar = false;
    } else {
      this.sideBarVar = true;
    }
  }

  has_access(module) {
    switch (module) {
      case "School SetUp":
        return true;
      case 'User Details':
        return true;
      case "Master Setting":
        return true;
      case "General Setting":
        return true;
      default:
        return false;
    }
  }
  map = { "School Setup": false, "User Details": false, "Student Details": false, "Transport": false, "Scheduling": false, "Inventory": false, "Daily Book": false, "School Shop": false, "Reporting": false, "Master Setting": false, "General Setting": false, "Pre Admission": true,"ppSchoolList":false,"ppQueryList":false,"ppSmsUpdates":false, "ppSyncAll":false, "ppMerchantList":false, "parentAdmission":false }
}
