import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AllApisService } from "../../all-apis.service";

@Injectable({
  providedIn: "root",
})
export class MasterServiceService {
  constructor(private _http: HttpClient, private apis: AllApisService) {}

  getCocnessionList() {
    return this._http.get<any>(
      this.apis.customeConcessionListbyId,
      this.apis.httpOptions
    );
  }
  getMonthListForSession(ses) {
    let params = new HttpParams();

    params = params.append("session", ses);
    this.apis.httpOptions.params = params;
    var a = this.apis.getsessionMonths; //+"?"+"session="+ses;
    console.log(a);

    return this._http.get<any>(a, this.apis.httpOptions);
  }

  getStudentFields() {
    return this._http.get<any>(
      this.apis.getStudentFields,
      this.apis.httpOptions
    );
  }

  updateFeeTemplate(feeTemplate: any) {
    return this._http.patch<any>(
      this.apis.updateFeeTemplate,
      { feeTemplate: feeTemplate },
      this.apis.httpOptions
    );
  }

  addRtiSetting(feeTemplate: any) {
    return this._http.patch<any>(
      this.apis.rtiReport,
      { value: feeTemplate },
      this.apis.httpOptions
    );
  }

  addFeeRecipetSettings(feeTemplate: any) {
    return this._http.patch<any>(
      this.apis.feeRecieptSetting,
      { value: feeTemplate },
      this.apis.httpOptions
    );
  }

  addFeeCatgoryGroup(groupname, feeCategory) {
    return this._http.patch<any>(
      this.apis.addFeeCategoryGroups,
      { groupName: groupname, feeCategory: feeCategory },
      this.apis.httpOptions
    );
  }

  addonlinePayementSetting(skipClassesForOnline, onlineSkipClassTransport , showFeeHistoryAndFeeSummery) {
    return this._http.patch<any>(
      this.apis.onlinePayementSetting,
      {
        skipClassesForOnline: skipClassesForOnline,
        skipClassesForOnlineTransport : onlineSkipClassTransport,
        showFeeHistoryAndFeeSummery: showFeeHistoryAndFeeSummery,
      },
      this.apis.httpOptions
    );
  }

  updateReportSetting(obj){

    return this._http.patch<any>(
      this.apis.reportSettingUpdate,
      obj,
      this.apis.httpOptions
    );


  }

  getReportSetting(){
    return this._http.get<any>(
      this.apis.getReportSetting,
      this.apis.httpOptions
    );
  }

  addDiscount(
    discountName,
    dicscountCategrory,
    discountmonth,
    discountPercentage,
    discountClass,
    skipCategrory,
    switchOffDicount,
    discountSkipSession
  ) {
    return this._http.patch<any>(
      this.apis.discount,
      {
        discountName: discountName,
        discountCategory: dicscountCategrory,
        discountMonth: discountmonth,
        discountPercent: discountPercentage,
        discountClass: discountClass,
        skipCategory: skipCategrory,
        switchOffDicount: switchOffDicount,
        discountSkipSession: discountSkipSession,
      },
      this.apis.httpOptions
    );
  }
  addCommonConcession(
    selectedConcessionCategory,
    commonConcessionSkipCategory,
    commonConcessionClassSkip,
    allowMultipleCommonConcession,
    commonConcessionSkipSession
  ) {
    return this._http.patch<any>(
      this.apis.commonConcession,
      {
        value: selectedConcessionCategory,
        commmonConcessionSkipCategory: commonConcessionSkipCategory,
        commonConcessionClassSkip: commonConcessionClassSkip,
        allowMultipleCommonConcession,
        commonConcessionSkipSession: commonConcessionSkipSession,
      },
      this.apis.httpOptions
    );
  }

  updateOneTimeDiscount(
    selectedConcessionCategory,
    commonConcessionSkipSession,
    installmentCount,
    currentSession
  ) {
    return this._http.patch<any>(
      this.apis.OneTimediscount,
      {
        value: selectedConcessionCategory,
        commonConcessionSkipSession: commonConcessionSkipSession,
        count : installmentCount,
        session: currentSession
      },
      this.apis.httpOptions
    );
  }

  getOneTimeDiscount(session){
    let params = new HttpParams();
    params = params.append("session", session);

    //

    this.apis.httpOptions.params = params;
    return this._http.get<any>(
      this.apis.OneTimediscount,
      this.apis.httpOptions
    );
  }

  // copyScheduling
  // copySed
  //             var oldsession =req.body.oldsession;
  // var newSession = req.body.newsession;
  copyScheduling(oldsession, newsession) {
    return this._http.patch<any>(
      this.apis.copyScheduling,
      { oldsession: oldsession, newsession: newsession },
      this.apis.httpOptions
    );
  }
  addtcsetting(
    tcfeeCost,
    admissionfeeCost = 0,
    chequeFlow = false,
    dueNotification = false,
    stopPartiallPayement,
    stopnextsessiondue,
    skipSessionForOnlinePayment,
    useSpecialCategory,
    cyclefeeCost,
    cashFlow,
    useTransportCollection
  ) {
    return this._http.patch<any>(
      this.apis.tcseting,
      {
        tcfeeCost: tcfeeCost,
        cyclefeeCost: cyclefeeCost,
        admissionfeeCost: admissionfeeCost,
        chequeFlow: chequeFlow,
        dueNotification: dueNotification,
        stopPartiallPayement: stopPartiallPayement,
        stopnextsessiondue: stopnextsessiondue,
        useSpecialCategory: useSpecialCategory,
        skipSessionForOnlinePayment: skipSessionForOnlinePayment,
        cashFlow: cashFlow,
        useTransportCollection: useTransportCollection,
      },
      this.apis.httpOptions
    );
  }

  updateSessionWiseReceipt(useSessionWiseReceiptNo){
    return this._http.patch<any>(
      this.apis.updateSessionWiseReceiptNumber,
      {
        useSessionWiseReceiptNo: useSessionWiseReceiptNo,
      },
      this.apis.httpOptions
    );
  }

  deleteFeeCatgoryGroup(groupname, id) {
    return this._http.patch<any>(
      this.apis.deleteFeeCategoryGroups,
      { groupName: groupname, goupid: id },
      this.apis.httpOptions
    );
  }


  deleteOneTimeConcession(id){
    return this._http.patch<any>(
      this.apis.deleteOneTImeConcession,
      {concessionId: id },
      this.apis.httpOptions
    );
    
  }

  getFeeCategoryGroup() {
    return this._http.get<any>(
      this.apis.addFeeCategoryGroups,
      this.apis.httpOptions
    );
  }

  // updateStudentFields(headerList:any, searchFields: any){
  // return this._http.patch<any>(this.apis.updateStudentFields,{headerList: headerList, searchFields: searchFields},this.apis.httpOptions);
  //}

  getPresentSession() {
    return this._http.get<any>(this.apis.presetsession, this.apis.httpOptions);
  }

  getAllSession() {
    return this._http.get<any>(this.apis.session, this.apis.httpOptions);
  }

  updatePresentSession(obj: any) {
    return this._http.patch<any>(
      this.apis.presetsession,
      obj,
      this.apis.httpOptions
    );
  }

  updateActiveSession(obj: any) {
    return this._http.patch<any>(
      this.apis.updateSession,
      obj,
      this.apis.httpOptions
    );
  }

  updateStudentFields(
    searchFields: any = null,
    headerList: any = null,
    transectionList: any = null,
    transectionCategoryList: any = null,
    categoryList: any = null,
    paymentModeList: any = null,
    defaulterList: any = null,
    admissionList: any = null
  ) {
    return this._http.patch<any>(
      this.apis.updateStudentFields,
      {
        headerList: headerList,
        searchFields: searchFields,
        transectionList: transectionList,
        transectionCategoryList: transectionCategoryList,
        categoryList: categoryList,
        paymentModeList: paymentModeList,
        defaulterList: defaulterList,
        admissionList: admissionList,
      },
      this.apis.httpOptions
    );
  }
  saveStuListHeaderFields(
    searchFields: any = null,
    headerList: any = null,
    transectionList: any = null,
    transectionCategoryList: any = null,
    categoryList: any = null,
    paymentModeList: any = null,
    defaulterList: any = null,
    studentList: any = null,
    openBalance: any = null,
    reconcilationReport: any = null,
    categoryWisePaid: any = null,
    transportWisePaid: any = null
  ) {
    console.log(
      "reconcilationReportreconcilationReportreconcilationReport",
      reconcilationReport
    );
    return this._http.patch<any>(
      this.apis.updateStudentFields,
      {
        headerList: headerList,
        searchFields: searchFields,
        transectionList: transectionList,
        transectionCategoryList: transectionCategoryList,
        categoryList: categoryList,
        paymentModeList: paymentModeList,
        defaulterList: defaulterList,
        studentList: studentList,
        openBalance: openBalance,
        reconcilationReport: reconcilationReport,
        categoryWisePaid: categoryWisePaid,
        transportWisePaid: transportWisePaid,
      },
      this.apis.httpOptions
    );
  }

  getStudentColoumn() {
    return this._http.get<any>(
      this.apis.studentcolumnUrl,
      this.apis.httpOptions
    );
  }
  patchStudentColoumn(obj: any) {
    return this._http.patch<any>(
      this.apis.studentcolumnUrl,
      obj,
      this.apis.httpOptions
    );
  }
  getSession() {
    return this._http.get<any>(
      this.apis.presentsessionUrl,
      this.apis.httpOptions
    );
  }
  patchPresentsession(obj: any) {
    return this._http.patch<any>(
      this.apis.presentsessionUrl,
      obj,
      this.apis.httpOptions
    );
  }
  getActivesession() {
    return this._http.get<any>(
      this.apis.activesessionUrl,
      this.apis.httpOptions
    );
  }
  patchActivesession(obj: any) {
    return this._http.patch<any>(
      this.apis.activesessionUrl,
      obj,
      this.apis.httpOptions
    );
  }

  //  Sms
  getSmsInfo() {
    return this._http.get<any>(this.apis.getSmsInfoUrl, this.apis.httpOptions);
  }

  getSmsInfoFromSMS() {
    return this._http.get<any>(this.apis.getSmsInfoFromSMSUrl, this.apis.httpOptions);
  }

  getSmsUseInfo() {
    return this._http.get<any>(
      this.apis.getNsetSmsUseInfoUrl,
      this.apis.httpOptions
    );
  }
  getreceiptInfo(rsession = "2022-23") {
    let params = new HttpParams();
    params = params.append("rsession", rsession);


    this.apis.httpOptions.params = params;
    return this._http.get<any>(
      this.apis.getNsetreceiptInfoUrl,
      this.apis.httpOptions
    );
  }
  sendSmsUseInfo(obj) {
    console.log("sending sms use info", obj);
    return this._http.patch<any>(
      this.apis.getNsetSmsUseInfoUrl,
      obj,
      this.apis.httpOptions
    );
  }
  sendSmsSchedulerUseInfo(obj) {
    console.log("sending sms use info", obj);
    return this._http.patch<any>(
      this.apis.getNsetSchedulerSmsUseInfoUrl,
      obj,
      this.apis.httpOptions
    );
  }
  sendreceiptInfo(obj) {
    return this._http.put<any>(
      this.apis.otherReceiptUpdate,
      obj,
      this.apis.httpOptions
    );
  }

  updateReceipt(obj){
    return this._http.put<any>(
      this.apis.updateReceipt1,
      obj,
      this.apis.httpOptions
    );
  }

  updateTransportReceipt(obj){
    return this._http.put<any>(
      this.apis.updateTransportReceipt,
      obj,
      this.apis.httpOptions
    );
  }

  updateSchoolShop(obj){
    return this._http.patch<any>(
      this.apis.updateSchoolShopUrl,
      obj,
      this.apis.httpOptions
    );
  }
  updateTransportConfig(obj) {
    return this._http.patch<any>(
      this.apis.updateTransportConfigUrl,
      obj,
      this.apis.httpOptions
    );
  }

  updateFineSkip(obj){
    return this._http.patch<any>(
      this.apis.updateFineSkip,
      obj,
      this.apis.httpOptions
    );
  }

  getFineSkip(session){
    let params = new HttpParams();
    params = params.append("session", session);


    this.apis.httpOptions.params = params;
    return this._http.get<any>(
      this.apis.getFineSkip,
      this.apis.httpOptions
    );
  }

  updateTransportConfigOther(obj) {
    return this._http.patch<any>(
      this.apis.updateTransportConfigUrlOther,
      obj,
      this.apis.httpOptions
    );
  }

  getTransportConfig(session) {
    let params = new HttpParams();
    params = params.append("session", session);
    return this._http.get<any>(
      this.apis.updateTransportConfigUrl,
      this.apis.httpOptions
    );
  }

  getItrInfo(session) {
    let params = new HttpParams();
    if (!session) {
      session = "2022-23";
    }
    params = params.append("session", session);

    this.apis.httpOptions.params = params;
    return this._http.get<any>(this.apis.getItrInfo, this.apis.httpOptions);
  }

  //Others
  sendOthersInfo(obj: any) {
    return this._http.patch<any>(
      this.apis.sendAdmitCardOnCollectFeeUrl,
      obj,
      this.apis.httpOptions
    );
  }
  sendShopingForstudent(obj: any) {
    return this._http.patch<any>(
      this.apis.sendShoppingForStudentUrl,
      obj,
      this.apis.httpOptions
    );
  }

  updateFeeValueType(flag){
    return this._http.patch<any>(this.apis.updateFeeValueTypeUrl,{feeTypeflag : flag},this.apis.httpOptions)
  }

  getFeeValueType(){
    return this._http.get<any>(this.apis.getFeeValueTypeUrl,this.apis.httpOptions)
  }
  
  updateAluminiStudentConfig(obj: any) {
    return this._http.patch<any>(
      this.apis.aluminiStudentConfigUrl,
      obj,
      this.apis.httpOptions
    );
  }
  getOthersInfo() {
    return this._http.get<any>(
      this.apis.getAdmitCardOnCollectFeeUrl,
      this.apis.httpOptions
    );
  }

  //routeinfo
  sendRouteInfo(obj: any) {
    return this._http.patch<any>(
      this.apis.sendRouteInfoFeeUrl,
      obj,
      this.apis.httpOptions
    );
  }
  sendScholarInfo(obj: any) {
    return this._http.patch<any>(
      this.apis.sendScholarInfoFeeUrl,
      obj,
      this.apis.httpOptions
    );
  }

  sendReceiptReamrkInfo(obj: any) {
    return this._http.patch<any>(
      this.apis.sendRemarkInfoFeeUrl,
      obj,
      this.apis.httpOptions
    );
  }

  sendFontSizeInfo(obj: any) {
    return this._http.patch<any>(
      this.apis.sendFontSizeInfoUrl,
      obj,
      this.apis.httpOptions
    );
  }

  updateOnlinePaymentModule(obj) {
    return this._http.patch<any>(
      this.apis.schoolPaymentModuleUrl,
      obj,
      this.apis.httpOptions
    );
  }

  updateOnlineRoletModule(obj) {
    return this._http.patch<any>(
      this.apis.schoolRoleModuleUrl,
      obj,
      this.apis.httpOptions
    );
  }

  updateOthersPaymentModule(obj) {
    return this._http.patch<any>(
      this.apis.schoolOthersPaymentUrl,
      obj,
      this.apis.httpOptions
    );
  }

  setAssetCategorySetting(obj) {
    return this._http.patch<any>(this.apis.categoryGenralSetting,obj,this.apis.httpOptions);
  }

  getAssetCategorySetting(){
    return this._http.get<any>(this.apis.categoryGenralSetting,this.apis.httpOptions)
  }

  setAssetPoGeneralSetting(obj) {
    return this._http.patch<any>(this.apis.poGeneralSetting,obj,this.apis.httpOptions);
  }

  getAssetPoGeneralSetting(){
    return this._http.get<any>(this.apis.poGeneralSetting,this.apis.httpOptions)
  }

  // Comminication Scheduler
  
  updateSchedulerSMS(obj){
    return this._http.patch<any>(this.apis.SMSScheduler, obj,this.apis.httpOptions);
  }
  
  updateSchedulerSMSCurrent(obj){
    return this._http.patch<any>(this.apis.SMSSchedulerCurrent, obj,this.apis.httpOptions);
  }

  getSchedulerSMS(category: string){
    let params = new HttpParams();
    params = params.append("category", category);
    this.apis.httpOptions.params = params;
    return this._http.get<any>(this.apis.SMSScheduler,this.apis.httpOptions)
  }

  getTemplateBySenderId(category: string, senderId: string){
    let params = new HttpParams();
    params = params.append("category", category);
    params = params.append("senderId", senderId);
    this.apis.httpOptions.params = params;
    return this._http.get<any>(this.apis.SMSTemplateBySenderId,this.apis.httpOptions)
  }

  updateSchedulerMail(obj){
    return this._http.patch<any>(this.apis.mailScheduler, obj,this.apis.httpOptions);
  }

  getSchedulerMail(category: string){
    let params = new HttpParams();
    params = params.append("category", category);
    this.apis.httpOptions.params = params;
    return this._http.get<any>(this.apis.mailScheduler,this.apis.httpOptions)
  }

  updateSchedulerNotification(obj){
    return this._http.patch<any>(this.apis.notificationScheduler, obj,this.apis.httpOptions);
  }

  getSchedulerNotification(category : string){
    let params = new HttpParams();
    params = params.append("category", category);
    this.apis.httpOptions.params = params;
    return this._http.get<any>(this.apis.notificationScheduler,this.apis.httpOptions)
  }

  
}
