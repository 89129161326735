export class RegisterModel {
	firstName: "";
	contactNo:any;
	emailId: "";
  password: "";
  uniqueSchoolId:"";
  smEmployeeId:""
  school:School
  _id:"";
  singleSchool:any;
}


export class School{
  name:string;
  schoolGroup:string;
  adresss={
    line1:""
  }
}
