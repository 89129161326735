import { Component } from '@angular/core';
import { TypeOfFine, IntervalFine } from './Model/addFine';
import { NotifierService } from 'angular-notifier';
import { FineService } from './service/FineService'
import { OneStorage } from 'src/app/GlobalDataStorage/oneStorage.service';
import { interval } from 'rxjs';
import { SharedService } from "../dashboardO/dashboard/shared.service";

declare var $: any;
@Component({
  selector: '<app-add-fine>',
  templateUrl: 'add-fine.component.html',
  styleUrls: ['add-fine.component.css']
})
export class AddFineComponent {

  monthWiseFeeActive = false;
  dayWiseFeeActive = false;
  intervalWiseFeeActive = false;
  useIntervalCost=false;
  //month wise
  monthVar: number;
  feeCategoryName="Fine -";

  useFeeCategory=false;
  avoidMonthwiseFineForFirstInterval=false;
  interFinePerInterval=false;
  interFineOnlyForFirstMonth=false;
  firstMonth= false;
  monthRadio = "All"
  //day Wise
  daytxtVar: number;
  dayRadio = ""
  //INterval Wise
  intervalWiseArr=[];
  intervalWiseMonthArr=[];
  applicableType: any; //Initialize with month fine
  fineType: any;
  addFineObj: TypeOfFine;
  intervalRadio = "";
  checkEmptyFieldVar:boolean=true;
  //catching server data
  daywiseRes:any;
  monthwiseRes:any;
  intervalwiseRes:any;
  monthWiseMutipleDay=0;
  clickEventsubscription: any;
  // session : any;

  constructor(private notifie: NotifierService,
    private fineServ: FineService ,private globals: OneStorage , public sharedService : SharedService) {
    this.addFineObj = new TypeOfFine();

    this.clickEventsubscription = this.sharedService
      .getClickEvent()
      .subscribe(() => {
        this.globals.activeSession = this.sharedService.getSession();

        this.getDataFromTheServer();
      });
    
  }
  private callback3(res: {}, that: any) {
    that.schoolName = res["schoolDetails"]["name"];
    that.logoUrl = res["schoolDetails"]["logoUrl"];

  }

  ngOnInit() {
    this.globals.initiateUserDetails(this.callback3, this);
   this.notZeroFieldsValidations();
   this.getDataFromTheServer();
  }


  activeToggal(activeToggle: { name: any; model: boolean; }) {
    let toggBtn = activeToggle.name;
    // console.log("Which toggle :",activeToggle)
    switch (toggBtn) {
      case 'monthWise':
        //console.log("Which toggle :",toggBtn);
        activeToggle.model = this.monthWiseFeeActive
      //  this.dayWiseFeeActive = false
       // this.intervalWiseFeeActive = false
        this.addFineObj.finetype = "MonthWise";
        this.monthRadio = "All"
        this.intervalWiseMonthArr= [new IntervalFine()];
        break;
      case 'dayWise':
        //console.log("Which toggle :",toggBtn);
        // this.monthWiseFeeActive = false
        activeToggle.model = this.dayWiseFeeActive
        // this.intervalWiseFeeActive = false
        this.addFineObj.finetype = "DayWise";//default Value
        this.dayRadio = "All Day"//default Value
        break;
      case 'intervalWise':
        //console.log("Which toggle :",toggBtn);
        //this.monthWiseFeeActive = false;
       // this.dayWiseFeeActive = false
        activeToggle.model = this.intervalWiseFeeActive
        this.addFineObj.finetype = "intervalWise";//default Value
        this.intervalRadio = "Per Day"; //default Value
        this.intervalWiseArr = [new IntervalFine()];
        break;
    }

    // to neglect Other the values
    if (this.monthWiseFeeActive == false) {
      this.monthRadio = ""; //to null all radio buttons value
      this.monthVar = null
    }
    if (this.dayWiseFeeActive == false) {
      this.dayRadio = ""; //to null all radio buttons value
      this.daytxtVar = null;
    }
    if (this.intervalWiseFeeActive == false) {
      //console.log("vvvvvvvvvvvvvvv");
      this.intervalRadio = ""; //to null all radio buttons value
      this.intervalWiseArr = [];
     // this.addFineObj.interval =[];
    }
  }

  addNewRow() {
    // console.log('SSSS');
    this.intervalWiseArr.push(new IntervalFine());
  }
  addNewRowmon(){
    this.intervalWiseMonthArr.push(new IntervalFine());

  }
  upDateBtn() {
    let flag:boolean= this.emptyFieldValidations();
    console.log("flag",flag);
    if(flag){
      //applicableType
      //this.addFineObj.applicableType = this.intervalRadio + this.dayRadio + this.monthRadio; //text
      //amount
     // this.addFineObj.amount = this.daytxtVar + this.monthVar; //number
      // if (this.intervalWiseArr.length > 0) {
      // this.addFineObj.interval = this.intervalWiseArr; // Array type
      // }

      this.sendDataToTheServer(this.addFineObj);
      //console.log("-----------------", this.addFineObj);
    }
  }

  //Fields Should Not Be Zero validations
  private notZeroFieldsValidations(){
    $(document).ready(function () {
      $("#monthwise_amo").bind("paste keyup", function () {
        let monthVar: string = $(this).val()
        if(monthVar ===""){
          alert("Please Enter a Valid Input (Field can't be empty or '0')");
        }
        else if (parseInt(monthVar) <= 0) {
         // alert("Please Enter a Valid Input (Greater then '0')");
          $("#monthwise_amo").val(1);
        }
      });

      $("#daywise_amo").bind("paste keyup", function () {
        let daywiseVar: string = $(this).val()
        if(daywiseVar === ""){
          alert("Please Enter a Valid Input (Field can't be empty or '0')");
        }
        else if (parseInt(daywiseVar) <= 0) {
          //alert("Please Enter a Valid Input (Greater then '0')");
          $("#daywise_amo").val(1)
        }
      });

    });
  }
  startdayVar(value:string,ind:number){
    //console.log(value)
    if(value === null){
      alert("Please Enter a Valid Input (Field can't be empty or '0')");
    }
    else if (parseInt(value) <= 0) {
      //alert("Please Enter a Valid Input (Greater then '0')");
      this.intervalWiseArr[ind].startday = 1;
    }
  }
  enddayVar(value: string,ind:number){
    //console.log(ind)
    if(value === null){
      alert("Please Enter a Valid Input (Field can't be empty or '0',)");
    }
    else if (parseInt(value) <= 0) {
      //alert("Please Enter a Valid Input (Greater then '0')");
      this.intervalWiseArr[ind].enddate = 1;
    }else if(parseInt(value) >= 366){
      this.intervalWiseArr[ind].enddate = 365;
    }
  }
  interAmountVar(value: string,ind:number){
   // console.log(ind)
    if(value === null){
      alert("Please Enter a Valid Input (Field can't be empty or '0',");
    }
    else if (parseInt(value) <= 0) {
      //alert("Please Enter a Valid Input (Greater then '0')");
      this.intervalWiseArr[ind].amount = 1;
    }
  }
  startdayVarMon(value:string,ind:number){
    //console.log(value)
    if(value === null){
      alert("Please Enter a Valid Input (Field can't be empty or '0')");
    }
    else if (parseInt(value) <= 0) {
      //alert("Please Enter a Valid Input (Greater then '0')");
      this.intervalWiseMonthArr[ind].startday = 1;
    }
  }
  enddayVarMon(value: string,ind:number){
    //console.log(ind)
    if(value === null){
      alert("Please Enter a Valid Input (Field can't be empty or '0',)");
    }
    else if (parseInt(value) <= 0) {
      //alert("Please Enter a Valid Input (Greater then '0')");
      this.intervalWiseMonthArr[ind].enddate = 1;
    }
  }
  interAmountVarMon(value: string,ind:number){
   // console.log(ind)
    if(value === null){
      alert("Please Enter a Valid Input (Field can't be empty or '0',");
    }
    else if (parseInt(value) <= 0) {
      //alert("Please Enter a Valid Input (Greater then '0')");
      this.intervalWiseMonthArr[ind].amount = 1;
    }
  }
  interAmountVarMon1(value: string,ind:number){
    // console.log(ind)
     if(value === null){
      value="0";
     }
      if (parseInt(value) <= 0) {
       //alert("Please Enter a Valid Input (Greater then '0')");
       this.intervalWiseMonthArr[ind].amount = 0;
     }
   }
  //Fields Should Not Be Empty validations
  private emptyFieldValidations():boolean{
    //console.log(this.monthWiseFeeActive + " " + this.intervalWiseFeeActive + " " + this.dayWiseFeeActive);
    // console.log(this.monthWiseFeeActive +" "+ this.monthVar);
    // if (this.monthWiseFeeActive == false && this.intervalWiseFeeActive == false && this.dayWiseFeeActive == false) {
    //   this.notifie.notify("warning", "Please! choose any type of fine");
    //   return false;
    // }
     if(this.monthWiseFeeActive==true && this.monthVar==null){
      this.notifie.notify("info", "Please! Empty Fields Can't be Send");
      return false;
    } //intervalWiseArr
    else if(this.dayWiseFeeActive==true && this.daytxtVar==null){
      this.notifie.notify("info", "Please! Empty Fields Can't be Send");
      return false;
    }
    else if(this.intervalWiseFeeActive==true){
     let tempVar:boolean = true;
      this.intervalWiseArr.forEach(ele=>{
        if(ele.startday==null || ele.enddate==null || ele.amount==null ){
          this.notifie.notify("info", "Please! Empty Fields Can't be Send");
          tempVar = false;
        }
      })
      return tempVar;
    } else{
      return true
    }
  }

  private getDataFromTheServer(){
  try{
    this.fineServ.getMonthWiseFineMeth(this.globals.activeSession).subscribe(
      res=>{this.monthwiseRes=res.data;},
      err=>{console.log(err)},
      ()=>{
        console.log("ejjjj-----",  this.monthwiseRes);
        this.monthWiseFeeActive = this.monthwiseRes.inUse;
        this.monthVar = this.monthwiseRes.amount;
        this.monthWiseMutipleDay  =   this.monthwiseRes.monthWiseMutipleDay
        this.firstMonth = this.monthwiseRes.firstMonth;
       // this.intervalWiseMonthArr =[new interval()]
        this.intervalWiseMonthArr= [new IntervalFine()];
        if( typeof  this.monthwiseRes.interval !=='undefined')
        this.intervalWiseMonthArr = this.monthwiseRes.interval;
        this.useIntervalCost = this.monthwiseRes.useIntervalCost

      });

    this.fineServ.getDayWiseFineMeth(this.globals.activeSession).subscribe(
          res=>{this.daywiseRes=res.data;},
          err=>{console.log(err)},
          ()=>{
          this.dayWiseFeeActive = this.daywiseRes.inUse;
          this.dayRadio = this.daywiseRes.applicableType;      //to null all radio buttons value
          this.daytxtVar = this.daywiseRes.amount;
          //applicableType
          console.log("-----------------this.daywiseRes", this.daywiseRes);
          });

    this.fineServ.getIntervalWiseMeth(this.globals.activeSession).subscribe(
      res=>{this.intervalwiseRes=res.data},
      err=>{console.log(err)},
      ()=>{
        console.log("-----------------this.intervalwiseRes1111", this.intervalWiseFeeActive);
        this.intervalWiseFeeActive = this.intervalwiseRes.inUse;
        this.useFeeCategory = this.intervalwiseRes.useFeeCategory;
        this.feeCategoryName = "Fine -";
        this.avoidMonthwiseFineForFirstInterval= this.intervalwiseRes.avoidMonthwiseFineForFirstInterval;
        this.interFinePerInterval = this.intervalwiseRes.interFinePerInterval;
        this. interFineOnlyForFirstMonth = this.intervalwiseRes.interFineOnlyForFirstMonth;
        this.intervalRadio = this.intervalwiseRes.applicableType;  //to null all radio buttons value
        this.intervalWiseArr = this.intervalwiseRes.interval;
          console.log("-----------------this.intervalwiseRes", this.intervalwiseRes);
        });
      }
      catch(error){
        this.notifie.notify("error","Error! Fatching Data from the server,"+error);
      }
  }

  private sendDataToTheServer(oneData:any){
    console.log(this.monthWiseFeeActive,this.dayWiseFeeActive,this.intervalWiseFeeActive);

    console.log("oneData", oneData)
    oneData.amount = this.monthVar
    oneData.monthWiseMutipleDay= this.monthWiseMutipleDay;
    oneData.inUse = this.monthWiseFeeActive;
   // oneData.inUse = this.intervalWiseFeeActive;
    oneData.interval = this.intervalWiseMonthArr;
    oneData.session = this.globals.activeSession;
    oneData.useIntervalCost = this.useIntervalCost;
    oneData.firstMonth= this.firstMonth
    oneData.applicableType=this.monthRadio;
    this.fineServ.patchMonthWiseFineMeth(oneData).subscribe(
      res=>{//console.log(res);
        if(this.monthWiseFeeActive){
          this.notifie.notify("success","Data Updated Successfully month wise" )
        }
           },
      err=>{console.log(err);
        if(this.monthWiseFeeActive){
          this.notifie.notify("error","Error: "+err)
        }
      }
    );

    oneData.amount= this.daytxtVar
    oneData.inUse = this.dayWiseFeeActive;
    oneData.applicableType=this.dayRadio;
    this.fineServ.patchDaywisefineMeth(oneData).subscribe(
      res=>{
        //console.log(res);
        if(this.dayWiseFeeActive){
          this.notifie.notify("success","Data Updated Successfully Day wise")
        }
      },
      err=>{
       // console.log(err);
       if(this.dayWiseFeeActive){
        this.notifie.notify("error","Error: "+err)
      }
      }
    );


    oneData.interval = this.intervalWiseArr;
    oneData.inUse = this.intervalWiseFeeActive;
    oneData.feeCategoryName ="Fine -";
    
    oneData.useFeeCategory = this.useFeeCategory
    oneData.avoidMonthwiseFineForFirstInterval= this.avoidMonthwiseFineForFirstInterval
     oneData.interFinePerInterval = this.interFinePerInterval
     oneData.interFineOnlyForFirstMonth = this.interFineOnlyForFirstMonth;
    oneData.amount = '0'
    oneData.applicableType=this.intervalRadio;
    console.log("What I send",oneData)
    this.fineServ.patchIntervalWiseMeth(oneData).subscribe(
      res=>{
        //console.log(res);
        if(this.intervalWiseFeeActive){
          this.notifie.notify("success","Data Updated Successfully Interal Wise")
        }
      },
      err=>{
       // console.log(err);
       if(this.intervalWiseFeeActive){
        this.notifie.notify("error","Error: "+err)
      }
      }
    );

  }

}
