import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FileSelectDirective, FileUploader } from "ng2-file-upload";
import { ViewEncapsulation } from "@angular/core";
import { routeSchedulingService } from "./create-route.service";
import { createRouteModel } from "./model/create-route";
import { vehicleRouteModel } from "./model/vehicleModel";
import { FormsModule } from "@angular/forms";
import { NotifierService } from "angular-notifier";
import { from } from "rxjs";
import { OneStorage } from "../GlobalDataStorage/oneStorage.service";
import * as xlsx from "xlsx";
import { AllApisService } from "../all-apis.service";
import { AddStudentService } from "../admission/services/add-student.service";
import { SharedService } from "../dashboardO/dashboard/shared.service";
declare var $: any;

@Component({
  selector: "<app-create-route>",
  templateUrl: "create-route.component.html",
  styleUrls: ["create-route.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateRouteComponent implements OnInit {
  @ViewChild("addExcelRoute", { static: false }) addExcelRoute: ElementRef;
  createRoute: createRouteModel;
  tableOptions = [];
  routeList = [];
  uri = "";
  schoolId;
  uploader: FileUploader; // = new FileUploader({url:this.uri});
  attachmentList: any = [];
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  mimeType: any;
  pdfDataObj: any;
  voucherAvailable: boolean = false;
  coloumns = [];
  vehicle = "";

  vehModel = new vehicleRouteModel();

  vehicleRegistraion = [];
  vehicles = [];
  clickEventsubscription: any;

  session : any;

  constructor(
    public addStudentService: AddStudentService,
    private routeSchedulingServices: routeSchedulingService,
    public allApies: AllApisService,
    private globals: OneStorage,
    private notifier: NotifierService,
    public _allApiService: AllApisService,
    public sharedService : SharedService
  ) {
    this.createRoute = new createRouteModel();

    this.clickEventsubscription = this.sharedService
      .getClickEvent()
      .subscribe(() => {
        this.session = this.sharedService.getSession();

        // this.ngOnInit();
        this.routeSchedulingServices
      .getRouteDetails(this.session)
      .subscribe(
        (res) => {
          console.log("routes shedulng lis ", res);
          this.tableOptions = res.data;
          this.coloumns = res.coloumns;
          console.log(res);
          this.routeList = this.tableOptions.map(function (el) {
            return el.name;
          });
          console.log(this.routeList);
          this.routeList = [...new Set(this.routeList)];
          this.createRoute.name = this.routeList[0];
          this.populateTable();
        },
        (error) => {
          console.log("Error", error);
        }
      );
      });
  }

  addVehicle(vehModel) {
    var obj = {
      name: "",
      busNo: vehModel.busNo,
      registrationNo: vehModel.registrationNo,
      model: "",
      driverInfo: { name: vehModel.driverName, remark: "" },
      conducterInfo: { name: vehModel.conducterName, remark: "" },
    };

    console.log("heeelllo ------------------", obj);
    this.routeSchedulingServices.addVehicle(obj).subscribe((data) => {
      console.log("datac-------", data);
      this.notifier.notify("info", "Vehicle Added SuccessFully");
      this.vehicles.push(obj);
    });

    $("#vehicleDetails").removeClass("in");
    $(".modal-backdrop").remove();
    $("body").removeClass("modal-open");
    $("#vehicleDetails").hide();
  }
  ngOnInit(): void {
    $(".showhideTable").css("display", "none");
    $(".closebtn").on("click", function () {
      $(".announceMentContent").animate({ "margin-right": "-=288" });
    });

    this.routeSchedulingServices.getVehicle().subscribe((data) => {
      console.log("vehicle nlisy", data);
      if (data.length !== 0) this.vehicles = data["vehicles"];
      else {
        var obj = {
          name: "",
          vehicalNo: "N/A",
          registrationNo: "N/A",
          model: "",
          driverInfo: { name: "", remark: "" },
          conducterInfo: { name: "", remark: "" },
        };
        this.vehicles.push(obj);
      }
      console.log("this.vehicles", this.vehicles);
    });
    this.routeSchedulingServices
      .getRouteDetails(this.globals.activeSession)
      .subscribe(
        (res) => {
          console.log("routes shedulng lis ", res);
          this.tableOptions = res.data;
          this.coloumns = res.coloumns;
          console.log(res);
          this.routeList = this.tableOptions.map(function (el) {
            return el.name;
          });
          console.log(this.routeList);
          this.routeList = [...new Set(this.routeList)];
          this.createRoute.name = this.routeList[0];
          this.populateTable();
        },
        (error) => {
          console.log("Error", error);
        }
      );

    console.log(
      "this.addStudentService.schoolId",
      this.addStudentService.schoolId
    );
    this.schoolId = this.addStudentService.schoolId;
    // this.,

    this.uri = this._allApiService.baseurl + "/file/uploadFileToCreateRoute";
    //"headers": [{ 'Authorization': 'Bearer ' + localStorage.getItem('token')}]
    this.uploader = new FileUploader({
      url: this.uri,
      authToken: "Bearer " + localStorage.getItem("token"),
    });
    //this.uploader.options.headers  =
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      this.attachmentList.push(JSON.parse(response));
    };
  }
  editRoute() {
    $(".announceMentContent").animate({ "margin-right": "+=288" });
  }

  addRoute() {
    console.log("one:", this.createRoute);
    var selectedVehicle = {};

    var vehicleDetails = {
      vehicalId: "",
      status: true,
      vehicleNo: "",
      registartionNo: "",
      gpsId: "",
      driverName: "",
      conducterName: "",
    };
    //driverInfo:{name :vehModel.driverName , remark :"" },
    //conducterInfo:{name  :vehModel.conducterName,remark:"" }
    this.vehicles.forEach((element) => {
      if (element.registrationNo === this.vehicle) {
        vehicleDetails.vehicalId = element._id;
        vehicleDetails.registartionNo = element.registrationNo;
        vehicleDetails.driverName = element.driverInfo.name;
        vehicleDetails.conducterName = element.conducterInfo.name;
      }
    });
    this.createRoute.session = this.globals.activeSession;
    this.createRoute.vehicleDetails.push(vehicleDetails); // =vehicleDetails;
    this.routeSchedulingServices.sendRouteDetails(this.createRoute).subscribe(
      (res) => {
        console.log("res", res);
        this.notifier.notify("success", "Data Updated SuccessFully");
        this.createRoute = new createRouteModel();
      },
      (error) => {
        console.log("Error", error);
      },
      () => {
        console.log("update Table");
        this.updateTable();
      }
    );
  }

  updateTable() {
    this.routeSchedulingServices
      .getRouteDetails(this.globals.activeSession)
      .subscribe(
        (res) => {
          this.tableOptions = res.data;
          this.populateTable();
        },
        (error) => {
          console.log("Error", error);
        }
      );
  }
  populateTable() {
    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    let self = this;
    const example = $(".example").DataTable({
      data: this.tableOptions,
      columns: this.coloumns,
      language: { search: '', searchPlaceholder: "Search" },
      columnDefs: [
        {
          targets: [0],
          visible: false,
          searchable: false,
        },
      ],
      order: [],
      paging: true,
      dom: "Bfrtip",
      buttons: [
        "copy",
        "csv",
        {
          extend: 'excelHtml5',
          exportOptions: {
              columns: ':not(:nth-child(9)):not(:nth-child(10))'
          }
        },
        {
          text: "Pdf",
          action: function () {
            generatePdf();
          },
        },
        {
          text: "Excel For Create Route",
          action: function () {
            self.generateExcel();
          },
        },
      ],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
    });
    async function generatePdf() {
      self.voucherAvailable = true;
      try {
        var title = null;
        var reportName = "Create Route";
        await self.globals
          .getPdfDataObj(self.coloumns, self.tableOptions, title, reportName)
          .then((res) => {
            self.pdfDataObj = res;
          });
      } catch (e) {
        console.log(e);
      }
    }
    $(".example tbody").on("click", "tr i.delete", function () {
      console.log("came here");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.deleteRoute(data);
    });
    $(".example tbody").on("click", "tr i.edit", function () {
      console.log("came here");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.edit(data);
    });
    $(".example tbody").on("click", "tr i.enable", function () {
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.enableRoute(data, false);
    });
    $(".example tbody").on("click", "tr i.disable", function () {
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.enableRoute(data, true);
    });
    $(".example tbody").on("click", "tr i.destination", function () {
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.toggleIsDestinationRoute(data, false);
    });
    $(".example tbody").on("click", "tr i.add-destination", function () {
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      self.toggleIsDestinationRoute(data, true);
    });
  }

  deleteRoute(data: any) {
    let self = this;
    console.log(
      "data and list",
      data,
      self.tableOptions,
      self.tableOptions[data]._id
    );
    this.globals.confirmDialogService.confirmThis(
      "This will Remove Stopage.",
      "Are you sure ?",
      function () {
        self.routeSchedulingServices
          .deleteRoute(self.tableOptions[data]._id)
          .subscribe(
            (res) => {
              self.tableOptions.splice(data, 1);
              console.log("updated list", self.tableOptions);
              self.populateTable();
              self.notifier.notify("success", "Stopage Removed Successfully.");
            },
            (error) => {
              self.notifier.notify(
                "error",
                "Unable to remove stopage currently."
              );
            }
          );
      },
      function () {}
    );
  }

  updateTransportId;
  updateRouteTag = false;
  updateRoute() {
    var obj = {
      name: this.createRoute.name,
      amount: this.createRoute.amount,
      remark: this.createRoute.remark,
      stopage: this.createRoute.stopage,
      isDestination: this.createRoute.isDestination,
      session: this.globals.activeSession,
    };
    this.routeSchedulingServices
      .updateRoute(this.updateTransportId, obj)
      .subscribe((res) => {
        this.notifier.notify("info", "Route updated Successfully ");
        this.createRoute = new createRouteModel();
        this.updateTable();
      });
  }
  edit(data) {
    let self = this;
    console.log(
      "data and list",
      data,
      self.tableOptions,
      self.tableOptions[data]
    );
    this.createRoute.name = self.tableOptions[data].name;
    this.createRoute.amount = self.tableOptions[data].amount;
    this.createRoute.remark = self.tableOptions[data].remark;
    this.createRoute.stopage = self.tableOptions[data].stopage;
    if ((self.tableOptions[data].isDestination = "Yes"))
      this.createRoute.isDestination = true;
    // this.createRoute.vehicleDetails.vehicleNo  = self.tableOptions[data].vehicleNo;
    this.updateRouteTag = true;
    this.updateTransportId = self.tableOptions[data]._id;
  }
  enableRoute(data: any, enable: boolean) {
    let self = this;
    this.globals.confirmDialogService.confirmThis(
      "This will update Enable Status For Stopage.",
      "Are you sure ?",
      function () {
        self.routeSchedulingServices
          .enableRoute(self.tableOptions[data]._id, enable)
          .subscribe(
            (res) => {
              self.tableOptions[data].isEnable = enable;
              self.populateTable();
              self.notifier.notify("success", "Updated Successfully.");
            },
            (error) => {
              self.notifier.notify("error", "Unable to update currently.");
            }
          );
      },
      function () {}
    );
  }

  toggleIsDestinationRoute(data: any, isDestination: boolean) {
    let self = this;
    self.routeSchedulingServices
      .toggleIsDestinationRoute(self.tableOptions[data]._id, isDestination)
      .subscribe(
        (res) => {
          self.tableOptions[data].isDestination = isDestination;
          self.populateTable();
          self.notifier.notify("success", "Updated Successfully.");
        },
        (error) => {
          self.notifier.notify("error", "Unable to update currently.");
        }
      );
  }
  getPdf() {
    this.generatePdf();
  }

  generatePdf() {
    this.voucherAvailable = true;
    console.log("hello--", this.tableOptions, this.coloumns);
    try {
      let colNameOnly = [
        {
          data: "route",
          title: "ROUTE NAME",
          defaultContent: "<i>Not set</i>",
        },
        {
          data: "stopage",
          title: "STOPPAGE",
          defaultContent: "<i>Not set</i>",
        },
        { data: "amount", title: "AMOUNT", defaultContent: "<i>Not set</i>" },
      ];

      var title = "Transport Routes Details";
      var finalList = [];
      this.tableOptions.forEach((v) => {
        var a = { route: v.name, stopage: v.stopage, amount: v.amount };
        finalList.push(a);
      });
      var reportName = "Transport Routes Details";
      this.globals
        .getPdfDataObj(colNameOnly, finalList, title, reportName)
        .then((res) => {
          this.pdfDataObj = res;
          this.pdfDataObj.sortingName = "classSection";
        });
    } catch (e) {
      console.log(e);
    }
  }
  generateExcel() {
    const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(
      this.addExcelRoute.nativeElement
    );
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "addExcelRoute.xlsx");
  }

  importExcelForRoute() {
    $("#routeModel").modal("show");
  }

  addNewRoute(route: any) {
    $("#fineModal").removeClass("in");
    $(".modal-backdrop").remove();
    $("body").removeClass("modal-open");
    $("#fineModal").hide();
    this.routeList.push(route);
    this.routeList = [...new Set(this.routeList)];
    this.notifier.notify("success", "Route Added SuccessFully");
  }
}
