
import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router
} from "@angular/router";

import { UserService } from '../Services/user.service';
import {
  SignInModel
} from '../ModelClasses/signinModel';
import {
  AllApisService
} from '../../all-apis.service';
import { NotifierService } from "angular-notifier";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { OneStorage } from '../../GlobalDataStorage/oneStorage.service';
import { ActivatedRoute } from '@angular/router';
import { BasicUserDetail } from 'src/app/shared/models/basic-user-detail.model';
import { BasicStudentDetail } from 'src/app/shared/models/basic-student-detail.model';
import { BasicSchoolDetail } from 'src/app/shared/models/basic-school-detail.model';

@Component({
  selector: 'app-signin',
  templateUrl: './sm-sign-in.component.html',
  styleUrls: ['./sm-sign-in.component.css', '../loginstyle.css']
})
export class SmSignInComponent implements OnInit {
  validLoginId: boolean;
  showLoader: boolean = true;
  schoolId: any;

  loadContent = "Signing in...";

  constructor(private router: Router, private activeRoute: ActivatedRoute, private userService: UserService,
    private allApies: AllApisService,
    private notifier: NotifierService,
    private oneStorage: OneStorage) { }   //, private global:OneStorage


  signInModel = new SignInModel()
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  serverErrorMessages: string;
  //server Data
  sData: Object;
  role: string;
  ngOnInit() {
    localStorage.clear();
    this.showLoader = true;
    localStorage.removeItem('hideLeftNav');
    if (this.activeRoute.queryParams) {
      this.activeRoute.queryParams.subscribe(params => {
        console.log("params.auth_token", params.auth_token);
        console.log("params.smitra_id", params.smitra_id);
        console.log("params.source_id",params.source_id);
        if(params.iframe) {
          localStorage.setItem('hideLeftNav', 'Y');
        }

        if (params.auth_token) {
          var paramObj = {}
          paramObj['authToken'] = params.auth_token;
          paramObj['smitraId'] = params.smitra_id;
          // paramObj['sourceId'] = params.source_id;
          if(params.user_source_id){
            paramObj['sourceId'] = params.user_source_id;
          }else{
            paramObj['sourceId'] = params.source_id;
          }
          this.userService.signInWithSMToken(paramObj)
            .subscribe(res => {
              const isSucess = res.msg;
              console.log("userService",res);
              console.log('res.userDetail : ', res.userDetail);
              if(res.userDetail) {
                this.oneStorage.basicUserDetail = new BasicUserDetail(res.userDetail);
                console.log('this.oneStorage.basicUserDetail ', this.oneStorage.basicUserDetail);
                this.schoolId =  this.oneStorage.basicUserDetail.schoolId;
                localStorage.setItem("userDetail", JSON.stringify(res.userDetail));
                if(res.userDetail.roles) {
                  localStorage.setItem("role", res.userDetail.roles[0]);
                }
                localStorage.setItem("schoolId", this.schoolId);
                localStorage.setItem("authToken", paramObj['authToken']);
              }
              if(res.stuUserDetail) {
                this.oneStorage.basicStudentDetail = new BasicStudentDetail(res.stuUserDetail);
              }
              if(res.schoolDetail) {
                this.oneStorage.activeSession = res.schoolDetail.presentSession;
                this.oneStorage.basicSchoolDetail = new BasicSchoolDetail(res.schoolDetail);
                this.schoolId = res.schoolDetail._id
                localStorage.setItem("schoolId", this.schoolId);
              }
              this.role = res.role;
              if (res.token !== null) {
                localStorage.setItem('token', res.token);

                //Initilization Token
                let headers = new HttpHeaders({
                  'Authorization': 'Bearer ' + this.allApies.getToken()
                });
                this.allApies.httpOptions.headers = headers;
                console.log(this.allApies.httpOptions);

              } else {
                this.notifier.notify("error", res.message);
              }
              this.callAfterSignIn(params);
            },
              error => {
                console.log('Error', error)
                this.notifier.notify("info", "Oops! Network Error.");
              });
        } else if(params.token && params.studentId) {
          this.userService.signInWithMobileToken(params.token, params.studentId)
            .subscribe(res => {
              const isSucess = res.msg;
              console.log(res);
              if(res.userDetail) {
                this.oneStorage.basicUserDetail = new BasicUserDetail(res.userDetail);
                this.schoolId = this.oneStorage.basicUserDetail.schoolId
                localStorage.setItem("schoolId", this.schoolId);
                localStorage.setItem("userDetail", JSON.stringify(res.userDetail));
                if(res.userDetail.roles) {
                  localStorage.setItem("role", res.userDetail.roles[0]);
                }
              }
              this.role = res.role;
              if (res.token !== null) {
                localStorage.setItem('token', res.token);

                //Initilization Token
                let headers = new HttpHeaders({
                  'Authorization': 'Bearer ' + this.allApies.getToken()
                });
                this.allApies.httpOptions.headers = headers;
                console.log(this.allApies.httpOptions);
                this.callAfterSignIn(params);
              } else {
                this.notifier.notify("error", res.message);
                this.showLoader = false;
              }
            },
              error => {
                console.log('Error', error)
                this.notifier.notify("info", "Oops! Network Error.");
                this.showLoader = false;
              });
        }
        else {
          this.showLoader = false;
        }
      });
    } else {
      this.showLoader = false;
    }
  }

  callAfterSignIn(params) {
    //To initilize Storage;
      //this.oneStorage.getFeecCatagory();
      //this.oneStorage.storeData();
      localStorage.setItem("userType", this.role);
      localStorage.removeItem("preAdmission");

      if (
        params.whitelabel &&
        (params.whitelabel == true || params.whitelabel == "true")
      ) {
        localStorage.setItem("whitelabel", "Y");
      }
      if (this.role === "Super Admin") {
        this.router.navigate(['/dashboard/welcomeScreen']);
      }
      else if (this.role === "Admission In-charge") {
        localStorage.setItem("preAdmission", "Y");
        this.router.navigate(['/dashboard/admission']);
      }
      else if (this.role === "Inventory Controller") {
        this.router.navigate(['/dashboard/expense/addCategory']);
      }
      else if (this.role === "Agent") {
        localStorage.removeItem("schoolId");
        this.router.navigate(['/dashboard/admission/addSchool'], { queryParams: { agentCode: params.agentCode } });
      }
      else {
        if (this.role === "Admin" && params.page_to && params.page_to == 'edit-school') {
          this.router.navigate(['/admission/addSchool'], { queryParams: {schoolId: this.schoolId} });
        } else if (params.page_to && params.page_to == 'admission') {
          localStorage.setItem("preAdmission", "Y");
          this.router.navigate(['/dashboard/admission']);
        } else if (params.page_to && params.page_to == 'm-pay-fee' && (params.source_id || params.studentId)) {
          var redParams = {}
          redParams['studentId'] = params.studentId
          redParams['page_to'] = params.page_to
          redParams['stuSourceId'] = params.source_id
          if(params.isSdk || params.isSdk == "true"){
            redParams['isSdk'] = true
          }else{
            redParams['isSdk'] = false
          }
          this.router.navigate(['/payment/feepayment'], { queryParams: redParams });
        } else if (params.page_to && params.page_to == 'm-fee-sum' && (params.source_id || params.studentId)) {
          var redParams = {}
          redParams['studentId'] = params.studentId
          redParams['page_to'] = params.page_to
          redParams['stuSourceId'] = params.source_id
          this.router.navigate(['/payment/fee-summary'], { queryParams: redParams });
        } else if (params.page_to && params.page_to == 'm-fee-detail' && (params.source_id || params.studentId)) {
          var redParams = {}
          redParams['studentId'] = params.studentId
          redParams['page_to'] = params.page_to
          redParams['stuSourceId'] = params.source_id
          this.router.navigate(['/payment/fee-detail'], { queryParams: redParams });
        } else if (params.page_to && params.page_to == 'pay-fee') {
          this.router.navigate(['/payment/feepayment'], { queryParams: { page_to: params.page_to } });
        } else if(this.role === "Admin") {
          this.router.navigate(['/dashboard/collect/CollectFeeMiscComponent']);
        }else if(this.role === "Principal") {
          this.router.navigate(['/dashboard/collect/CollectFeeMiscComponent']);
        }else {
          this.router.navigate(['/dashboard/blankContent']);
        }
      }
      //this.showLoader = false;
  }

  validateInput() {
    var val = this.signInModel.emailId;
    if (val) {
      this.validLoginId = true;
    } else {
      this.validLoginId = false;
    }

    //return val.match(this.emailRegex) || val.match("other regex") && dosomething();
  }

  //form method (html)
  onLogin() {
    this.userService.logMeInWithSm(this.signInModel)
      .subscribe(res => {
        const isSucess = res.msg;
        console.log(res);
        //  console.log('token : ' + res.token);
        this.role = res.role;
        if (res.token !== null && this.signInModel.password !== null) {
          localStorage.setItem('token', res.token);
          this.schoolId = res.schoolId;
          localStorage.setItem('schoolId', res.schoolId);

          //Initilization Token
          let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this.allApies.getToken()
          });
          this.allApies.httpOptions.headers = headers;
          console.log(this.allApies.httpOptions);

        } else {
          this.notifier.notify("error", "Invalid E-mail/Password");
        }
      },
        error => {
          console.log('Error', error)
          this.notifier.notify("info", "Oops! Network Error.");
        },
        () => {
          //To initilize Storage;
          this.oneStorage.getFeecCatagory();
          this.oneStorage.storeData();
          localStorage.removeItem("preAdmission");
          localStorage.setItem("userType", this.role);
          localStorage.setItem("role", this.role);
          if (this.role === "Super Admin") {
            this.router.navigate(['/dashboard/welcomeScreen']);
          }
          else if (this.role === "Admission In-charge") {
            localStorage.setItem("preAdmission", "Y");
            this.router.navigate(['/dashboard/admission']);
          }
          else if (this.role === "PPAdmin") {
            this.router.navigate(['/dashboard/smsUpdates']);
          }
          else {
            this.router.navigate(['/dashboard/collect/CollectFeeMiscComponent']);
          }
        }

      )
  }
  backToHome() {
    console.log('clicked');
    this.router.navigate(['']);
  };

}
