import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fee-historyComponent',
  templateUrl: './fee-historyComponent.component.html',
  styleUrls: ['./fee-historyComponent.component.css']
})
export class FeeHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
