import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AllApisService } from '../../all-apis.service'


@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  errorData: {};

  constructor(private _http: HttpClient,
    private allApi: AllApisService) { }


  public file;
  public file1;
  public file2;

  merchantProfileId:any;


  setfile(logo) {

    this.file = logo;
  };

  

  setfile1(banner) {

    this.file1 = banner;
  };

  
  setfile2(footer) {

    this.file2 = footer;
  };




  getUserTransactionsListUrl = this.allApi.baseurl + '/pay/transactions';
  getMerchantProfileUrl = this.allApi.baseurl + '/merchant/forEdit';
  getQrCodeImageUrl = this.allApi.baseurl + '/merchant/qrCodeImage';
  getDownloadQRUrl = this.allApi.baseurl + '/merchant/downloadQR';
  getMerchantListUrl = this.allApi.baseurl + '/merchant/merchantList';

  updateMerchantUrl = this.allApi.baseurl + '/merchant/editProfile';
  postMerchantUrl = this.allApi.baseurl + '/merchant/forSchool';
  
  postOffersUrl = this.allApi.baseurl + '/';

    postOffers(obj:any) {
      return this._http.post<any>(this.postOffersUrl,obj,this.allApi.httpOptions).pipe(catchError(this.handleError));
    }

  getUserTransactionsList() {
    return this._http.get<any>(this.getUserTransactionsListUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  getMerchantProfile(id: string) {
    let params = new HttpParams();

    params = params.append('_id', id);

    // console.log(params);

    this.allApi.httpOptions.params = params;
    return this._http.get<any>(this.getMerchantProfileUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  getQrCodeImage() {
    return this._http.get<any>(this.getQrCodeImageUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  getDownloadQR() {
    return this._http.get<any>(this.getDownloadQRUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  getMerchantList() {
    return this._http.get<any>(this.getMerchantListUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }


  updateMerchantDetails(merchantProfile:any){
    return this._http.patch<any>(this.updateMerchantUrl,merchantProfile,this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  postMerchantDetails(merchantProfile:any){
    return this._http.post<any>(this.postMerchantUrl,merchantProfile,this.allApi.httpOptions).pipe(catchError(this.handleError));
  }



  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }


}
