import { Component, OnInit, Inject } from "@angular/core";

import { AllApisService } from "../all-apis.service";

import { NotifierService } from "angular-notifier";
import { OneStorage } from "../GlobalDataStorage/oneStorage.service";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: "app-upload-edocs",
  templateUrl: "./upload-edocs.component.html",
  styleUrls: ["./upload-edocs.component.scss"],
})
export class UploadEdocsComponent implements OnInit {
  fileToUpload: File | null = null;
  eDoc: any;
  docTitle: any = "";
  sessionOption : any = "2034-24";
  tableData : any
  edit: Boolean = false;
  button : String = "";
  docId : String = "";
  showErrorMessage : any = "";
  searchQuery: string = '';
  filteredTableData: any[] = [];

  constructor(
    public allApiServ: AllApisService,
    public notifier: NotifierService,
    public globals: OneStorage,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.sessionOption = this.globals.activeSession;

    if(!this.sessionOption) {
      this.sessionOption = "2024-25"
    }

    this.getEDocs()
    this.edit = false;
    this.button = "Save Document"
    this.searchQuery = ""
  }

  getEDocs(){
    this.allApiServ.getDocs(this.sessionOption).subscribe(res => {
      this.tableData = res.data
      this.filteredTableData = this.tableData;
      console.log("response" , this.tableData)
    })
  }

  onFileChange(event) {
    this.fileToUpload = event.target.files[0];
    console.log("filename", this.fileToUpload);
    if (this.fileToUpload && this.fileToUpload.name.includes("pdf")) {
    } else {
      this.fileToUpload = null;
      this.notifier.notify("error", "Please select only pdf file");
    }
  }

  onUpdate() {
    if(this.button == "Save Document"){
      if (!this.fileToUpload || !this.docTitle || this.docTitle == "") {
        return this.notifier.notify("error", "Please Enter Required Fields");
      }
  
      if (this.fileToUpload) {
        // this.showLoader = true;
        // this.loaderMessage = "Uploading file please wait.....";
        this.allApiServ.uploadEdoc(this.fileToUpload).subscribe((res) => {
          if (res["status"]) {
            // this.showLoader = false;
            this.fileToUpload = null;
            this.showErrorMessage = "";
            this.eDoc = res.data.url;
            let obj = {session : this.sessionOption,  doc: this.eDoc, title: this.docTitle };
            this.allApiServ.addNewEDoc(obj).subscribe(
              (res) => {
                if (res.status) {
                  this.reset();
                  this.getEDocs()
                  this.notifier.notify("success", "Document Saved.");
                }
              },
              (err) => {
                this.reset();
                console.log("err" ,err)
                this.notifier.notify("error", "Failed to save document");
              }
            );
          } else {
            this.fileToUpload = null;
            this.notifier.notify("error", "Failed To Upload File Please Retry");
          }
        });
      } else {
        this.fileToUpload = null;
        this.notifier.notify("error", "Please select a File");
      }
    }else{
      let objTitle = this.docTitle;
      if (!objTitle || objTitle == "") {
        return this.notifier.notify("error", "Please Enter Required Fields");
      }

            this.allApiServ.updateNewEDoc(this.docId , objTitle).subscribe(
              (res) => {
                if (res.status) {
                  this.reset();
                  this.getEDocs()
                  this.notifier.notify("success", res.msg);
                }else{
                  this.reset();
                  this.getEDocs()
                  this.notifier.notify("error", res.msg);
                }
              },
              (err) => {
                this.reset();
                console.log("err" ,err)
                this.notifier.notify("error", "Failed to update document");
              }
            );
    }
  }

  updateFilteredData() {
    if (!this.searchQuery) {
      this.filteredTableData = this.tableData;
    } else {
      this.filteredTableData = this.tableData.filter(row => {
        // Implement your own logic here for filtering rows
        return (
          row.title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          row.session.toLowerCase().includes(this.searchQuery.toLowerCase()) 
        );
      });
    }
  }

  editEntry(entry: any) {
    this.scrollToTop();
    // Set the entry to be edited
    console.log("entry", entry)
    this.button = "Update Document Title";
    this.edit = true;
    this.docId = entry._id;
    this.docTitle = entry.title;
    this.sessionOption = entry.session;
  }

  

  deleteEntry(entry: any) {
    let self = this;
    self.docId = entry._id
    this.globals.confirmDialogService.confirmThis(
      "Are You Sure You Want To Delete item?",
      "Confirm Deletion",
      function () {
        // self.();
        self.allApiServ.deleteEDoc(self.docId ).subscribe(
          (res) => {
            if (res.status) {
              self.reset();
              self.getEDocs()
              self.notifier.notify("success", res.msg);
            }else{
              self.reset();
              self.getEDocs()
              self.notifier.notify("error", res.msg);
            }
          },
          (err) => {
            self.reset();
            console.log("err" ,err)
            self.notifier.notify("error", "Failed to update document");
          }
        );
      },
      function () {}
    );
  }

  

  

  

  reset() {
    this.docTitle = ""
    this.fileToUpload = null;
    this.edit = false
    this.button = "Save Document"
  }

  removeFile() {
    this.fileToUpload = null;
  }

  scrollToTop() {
    this.document.body.scrollTop = 0; 
    this.document.documentElement.scrollTop = 0; 
  }

  onSessionChange() {
    this.getEDocs()
  }

}
