 
export class vehicleRouteModel {
name="";
vehicleNo="";
busNo= "";
registrationNo=""
model="";

driverName ="";
conducterName=""

}
//vehicleNo ,registrationNO
