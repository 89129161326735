import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UserService } from '../Services/user.service';
import {RegisterModel,School} from '../ModelClasses/registerModel';
import {NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
declare var $:any;
// import { UserService } from '../../shared/user.service'

@Component({
  selector: 'app-signup',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css','../loginstyle.css']
})
export class SignUpComponent implements OnInit {
  confirmOtp: number;
  otp: number;
  invalidOtp: boolean;
  validOtp: boolean;
  invalidMobile: boolean;
  schoolGroup: string;
  userDetail: any;
  validSmEmpCode: any;
needSchoolId:boolean =false;
schoolId  :String ="";

  constructor(private router : Router,
    private userService:UserService,
    private notifier:NotifierService,private activeRoute: ActivatedRoute,
    ) { }

  regModel = new RegisterModel();
  school=new School();

  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  mob_no_field:boolean = false;
  mobileNo:any;

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
      // console.log("params.auth_token", params.auth_token);
      // console.log("params.smitra_id", params.smitra_id);
      // console.log("params.smitra_id", params.smitra_id);
      console.log("params",params.addSchoolId);
    this.needSchoolId=params.addSchoolId;
    });


  }
  vv(hh){
console.log(hh);
  }

onSubmit(){
  if(this.validOtp && this.userDetail && this.userDetail._id && this.validSmEmpCode) {
    console.log(this.regModel.uniqueSchoolId);
    this.regModel.contactNo=[];
    this.regModel._id=this.userDetail._id;
    this.regModel.contactNo.push(this.mobileNo);
    this.regModel.school=this.school;
    if(this.needSchoolId)
this.regModel['schoolId']= this.schoolId;
    this.userService.registerMe(this.regModel)
        .subscribe( res =>{
            let isSuccess = res.msg;
            console.log("Success "+res.msg);
          if(isSuccess == "success"){
            console.log("Success:",isSuccess );
            this.router.navigateByUrl('/signin');
          }else{
            this.notifier.notify("info",res.msg);
          }
          },
          error => {
            console.log("Error", error);
            this.notifier.notify("error","Network Error");
          }
        );
        console.log(this.regModel);
    } else if (!this.validOtp){
      this.notifier.notify("error","Please verify otp.");
    }
}

 onSignin (){ //header Link
  this.router.navigate(['/signin']);
}

sentOtp() {
    if(this.mobileNo && this.mobileNo > 1000000000 && this.mobileNo < 10000000000) {
      this.invalidMobile = false;
      console.log(this.invalidMobile);
      this.userService.sendOtp(this.mobileNo).subscribe(res => {
        console.log(res);
        if(res.status) {
          this.confirmOtp = Number(res.otp);
          this.userDetail = res.userDetail;
          this.notifier.notify('success', 'Otp Sent Successfully.');
        } else {
          this.notifier.notify('warning', res.message);
        }
    },
    error => {
      this.notifier.notify('error', error);
    });
    } else {
      this.invalidMobile = true;
    }
}

randomNum() {
  var rand = Math.floor(Math.random() * (999999 -  + 1)) + 100000;
  console.log('generated otp', rand);
  return rand;
}

verifyOtp() {
  if (this.confirmOtp && this.otp && this.confirmOtp === this.otp) {
    this.invalidOtp = false;
    this.validOtp = true;
  } else {
    this.invalidOtp = true;
    this.validOtp = false;
  }
}

verifySmEmpCode() {
  var smEmpCodeList = ["EC10012", "EC10025", "EC10033", "EC10041", "EC10057", "EC10065", "EC10066", "EC10069", "EC10071", "EC10089", "EC10105", "EC10106", "EC10120", "EC10011", "EC10013", "EC10037", "EC10044", "EC10015", "EC10016", "EC10017", "EC10018", "EC10096", "EC10001", "EC10002", "EC10052", "EC10141", "EC10127", "EC10142"];
  this.validSmEmpCode = smEmpCodeList.includes(this.regModel.smEmployeeId);
}
backToHome(){
  console.log('clicked');
  this.router.navigate(['']);
}

}
