import { Injectable, ɵConsole } from '@angular/core';
import { Router } from '@angular/router'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AllApisService } from '../all-apis.service';

// import {addFeeModel} from './ModelClass/addFeeModel';

@Injectable({
  providedIn: 'root'
})
export class routeSchedulingService {

  constructor(private _http: HttpClient,
    private router: Router,
    private allApis: AllApisService
  ) { };
  // baseUrl = "http://192.168.0.32:8080";

  createRoute = this.allApis.createRoute;    //"http://localhost:8080";
  //fatchTransportApi = this.allApis.fatchTransportDetails;
  createRoute1 = this.allApis.createRoute+"/routes";  
  errorData: {};

 vehicleRoute = this.allApis.baseurl+"/vehicle";
   

  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('token')
      // 'ID': emp.UserID,
    }),
    params: new HttpParams()
  };

  getVehicle() {

    return this._http.get<any>(this.vehicleRoute, this.httpOptions).pipe(catchError(this.handleError));
  }
  addVehicle(newObject: any) {
    console.log("this.vehicleRoutethis.vehicleRoute",this.vehicleRoute);
    return this._http.post<any>(this.vehicleRoute, newObject, this.httpOptions).pipe(catchError(this.handleError));

  }
  updateRoute(routeId:any ,value) {
    return this._http.patch<any>(this.allApis.updateRoute, {routeId: routeId,value:value}, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  sendRouteDetails(newObject: any) {
    return this._http.post<any>(this.createRoute, newObject, this.httpOptions).pipe(catchError(this.handleError));

  }
  getRouteDetails(session) {
    let params = new HttpParams();
    console.log("hello",session)
    params = params.append('session', session);
    this.allApis.httpOptions.params = params;
   return this._http.get<any>(this.createRoute1, this.allApis.httpOptions).pipe(catchError(this.handleError));
   // return this._http.post<any>(this.fatchTransportApi, objBody, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteRoute(routeId:any) {
    return this._http.patch<any>(this.allApis.deleteRoute, {routeId: routeId}, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  enableRoute(routeId:any, isEnable:boolean) {
    return this._http.patch<any>(this.allApis.enableRoute, {routeId: routeId, isEnable: isEnable}, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  toggleIsDestinationRoute(routeId, isDestination) {
    return this._http.patch<any>(this.allApis.toggleIsDestinationRoute, {routeId: routeId, isDestination: isDestination}, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  // getPageTemplate(uniqueId: string, pageTemplate: string) {
  //   this.selectedData.homePage = pageTemplate;
  //   /*console.log(uniqueId);*/
  //   const options = {params: new HttpParams().set('id', uniqueId), headers: new HttpHeaders().set('Content-Type', 'application/json')}
  //   /*console.log(options);*/
  //   return this.http.post(environment.apiBaseUrl + '/update2', this.selectedData, options);
  // }

  // error handling
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }

}
