import { Injectable } from '@angular/core';
import { Router} from '@angular/router';
import { AddUser} from '../../SigninSignoutO/ModelClasses/addUserModel';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError,observable, timer} from 'rxjs';
import { catchError,retry,delay,retryWhen, scan, delayWhen} from 'rxjs/operators';
import { AllApisService } from '../../all-apis.service';

// import 'rxjs/add/operators/Observable';
// import 'rxjs/add/operator/retryWhen'
// import 'rxjs/add/operator/delay'
// import 'rxjs/add/operator/retry'

@Injectable({
  providedIn: 'root'
})
export class AddUserService {
  errorData: {};

  constructor(private _http: HttpClient,
              private router:Router,
              private allApi:AllApisService ) { }

 /*  Add User */
 addUser(userDetail ) {
   console.log(this.allApi.httpOptions);
  // const formData = new FormData();
  //this.allApi.httpOptions.append('Content-Type', 'multipart/form-data');


    return this._http.post<any>(this.allApi.addUser, userDetail,this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  editUser(userDetail: any) {
    // console.log(this.allApi.httpOptions);
     return this._http.patch<any>(this.allApi.addUser, userDetail,this.allApi.httpOptions).pipe(catchError(this.handleError));
   }
/*  get User Roles */
 getUserRoles() {
   console.log("Token: ",this.allApi.httpOptions);
  return this._http.get<any>(this.allApi.getAllRoles,this.allApi.httpOptions)
  .pipe(retryWhen(err =>err.pipe(scan(retryCount =>{if(retryCount >3){throw (err);}else{
    retryCount++;return retryCount;}},0),delayWhen(()=> timer(1000))))).pipe(catchError(this.handleError));
}

/*  get School */
getUserSchoolNames(){
    return this._http.get<any>(this.allApi.schoolNameListUrl,this.allApi.httpOptions)
   .pipe(retryWhen(err =>err.pipe(scan(retryCount =>{if(retryCount >3){throw (err);}else{
     retryCount++;return retryCount;}},0),delayWhen(()=> timer(1000))))).pipe(catchError(this.handleError));
}

getUserListSchoolWise(SchoolId:string){
  let params= new HttpParams();
  params=params.append('schoolId',SchoolId);
  this.allApi.httpOptions.params=params;
  // params=params.append('schoolId',SchoolId)
  return this._http.get<any>(this.allApi.fatchUserList,this.allApi.httpOptions).pipe(catchError(this.handleError));
}
getUserAndSchoolDetails(){
  return this._http.get<any>(this.allApi.baseurl+'/user/single',this.allApi.httpOptions).pipe(catchError(this.handleError));
}

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }

}
