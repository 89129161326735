export class TypeOfFine{

        finetype:string;
        applicableType:string;
        amount:number;
        inUse:false;
        session:string;
        firstMonth:false
        feeCategoryName:string;
useFeeCategory:false;
interFinePerInterval=false;
avoidMonthwiseFineForFirstInterval=false;
interFineOnlyForFirstMonth =false;
        interval=[
                // {
                //   startday:number,
                //   enddate:number,
                //    amount:number
                // }
                
                 ]


}

export class IntervalFine{

        startday:number;
        enddate:number;
        amount:number;
        

}
