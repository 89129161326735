import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { SiteV2Service } from '../site-v2/site-v2.service';
declare var $:any;

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor(public _notify: NotifierService, private serv: SiteV2Service) { }

  ngOnInit() {
    $('#homeBtn').on('click', function (event) {
      console.log('clciked');
      event.preventDefault();
      $('html, body').animate({ scrollTop: 0 }, 'slow');
      return false;
     });
  }
  contactDetails(name, number, emailAddress, subject, message) {
    var obj = {};
    obj[number] = {};
    obj[number]["name"] = name;
    obj[number]["mobileNo"] = number;
    obj[number]["email"] = emailAddress;
    obj[number]["subject"] = subject;
    obj[number]["message"] = message;
    obj[number]["purpose"] = "message"
    this.serv.addQuery(obj)
      .subscribe(res => {
        console.log(res);
        this._notify.notify('success', 'Thank you for registering.We will reach out to you soon.');
      },
        err => {
          this._notify.notify('error', 'Please try again');
        }
      )
    console.log(name, emailAddress, subject, message);
    $('#exampleModal').modal('hide');
  }

}
