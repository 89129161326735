import { Component, OnInit } from '@angular/core';
import { monthWiseStatusService } from './status-month-wise.service';
import { OneStorage } from '../GlobalDataStorage/oneStorage.service';
import { AllApisService } from '../all-apis.service';

declare var $: any;

@Component({
  selector: 'app-status-month-wise',
  templateUrl: './status-month-wise.component.html',
  styleUrls: ['./status-month-wise.component.scss']
})
export class StatusMonthWiseComponent implements OnInit {
  link: any;
  startDate;
  endDate;
  useDate=false;

  constructor(private monthWise:monthWiseStatusService,
              private globals: OneStorage, public allApies: AllApisService, ) { }

  allClasses = [];
  allSectionsObj:any;
  allSections=[];
  sessionOption='2021-22';
  monthList = ["April", "May", "June", "July", "August", "September", "October", "November", "December", "January", "February", "March"];
  coloumns=[];
  StudentList:any;
  dropdownSettings = {
    //singleSelection: false,
    //textField: 'title',
    selectAllText: 'All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
  };
  classSearch:any;
  sectionSearch:any;
  monthSearch:any;
  pdfDataObj: any;
  monthListVar: [];
  voucherAvailable: boolean = false;
  showLoader:boolean = false;
  loadContent = "Fee Report Month-Wise...";

  private callback1(res: {}, that: any) {

    that.allClasses = res["standard"].filter((x: string) => x != "");

    that.allSectionsObj = res["sectionObj"]
    console.log("jasxjd",that.allSectionsObj);

    console.log(that.allClasses);
  }

  ngOnInit() {
    if (this.globals.standard === null || this.globals.standard.length === 0) {
      this.globals.initiateStandard(this.callback1, this);
    }
    else {
      this.allClasses = (this.globals.standard).filter((v: string) => v != '');
      this.allSectionsObj = this.globals.sectionObj;
    }
    let action_btn = '<i  class="fas fa-download text-info download" title="Download Admit Card"></i>'
    this.coloumns=[   
      
      { data: 'serial', defaultContent: '<i>-</i>',title:'Sr.No.' },
      { data: 'studentName', defaultContent: '<i>-</i>',title:'Student Name' },
      { data: 'father', defaultContent: '<i>-</i>',title:'Father Name' },
      { data: 'rollNo', defaultContent: '<i>-</i>',title:'Roll Number' },
      { data: 'mother', defaultContent: '<i>-</i>',title:'Mother Name' },
      { data: 'classSection', defaultContent: '<i>-</i>',title:'class Section' },
      { data: 'admissionNo', defaultContent: '<i>-</i>',title:'Admission Number' },
      { data: 'studentCode', defaultContent: '<i>-</i>',title:'Student Code' },
      { data: 'contactNo', defaultContent: '<i>-</i>',title:'Contact Number' },
      { data: 'paid', defaultContent: '<i>-</i>',title:'Amount Paid' },
      
    ]
    // this.getMonthWiseStatus();
    var a = [];
    
    var a = [];
    this.monthWise.getSessionInstallments( this.globals.activeSession)
      .subscribe(res => {
        console.log("----------------------", res);
        res.installment.forEach(element => {
       
          a.push(element.title);
        });
        this.monthList = a;

      });
   

  }

  getMonthWiseStatus(){
    this.showLoader = true;
    this.monthWise.monthWiseStatus({"classSearch":this.classSearch, "section":this.sectionSearch, "month":this.monthListVar 
    ,session:this.sessionOption,startDate:this.startDate,endDate:this.endDate,useDate:this.useDate}).subscribe(res=>{
      this.StudentList=res;
      this.showLoader = false;
      this.populateTable(true);
    });
    
  }

  populateTable(active:boolean) {
    let that=this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.StudentList,
        columns: this.coloumns ,
        scrollX: true,
        dom: 'Bfrtip',
        "order": [],
        paging:true,
        language: { search: "", searchPlaceholder: "Search Here" },
        buttons: ['pageLength', 'copy', 'csv', {extend: 'excelHtml5', title: "Fee(monthwise) Report", exportOptions: {columns: ':gt(0)', orthogonal: 'export'}}, { text: 'Pdf', action: function () { generatePdf(); } }],
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, 'All']]
      });

      async function generatePdf() {
        that.voucherAvailable = true;
        try {
          
          let trtdArr = {
            'thArr': that.coloumns,//onlyColName,
            'trtdArr': that.StudentList,//arrArrData
            // 'startDate':, //that.startDate,
            //   'endDate': that.endDate,//that.endDate,
              'schoolName': that.globals.schoolDetails["name"],//that.schoolName,
              'logoUrl':  that.allApies.baseurl + '/' + that.globals.schoolDetails["logoUrl"],//that.logoUrl,
              // "title": 'Report is from ' + that.startDate + ' to ' + that.endDate,
              'address':that.globals.schoolDetails["adresss"]["line1"],
              'contactNo':that.globals.schoolDetails["mobileNo"],
              'reportName':'Fee Report Month Wise Report',
              'session':that.globals.activeSession
          }
          var title = null;
          var reportName = 'Fee Report Month Wise';
          if (that.classSearch != "Select All" && that.sectionSearch != "Select All") {
            reportName = 'Fee Report Month Wise (' + that.classSearch + '-' + that.sectionSearch + ')';
          } else if (that.classSearch != "Select All" && that.sectionSearch == "Select All") {
            reportName = 'Fee Report Month Wise (' + that.classSearch + ')';
          } else {
            reportName = 'Fee Report Month Wise';
          }
          await that.globals.getPdfDataObj(that.coloumns, that.StudentList, title, reportName,that.sessionOption).then(res => {
            that.pdfDataObj = res;
          })
        } catch (e) { console.log(e); }
      }

      $('.example tbody').on('click', 'tr i.download', function () {
        //  console.log("clicked............");
        var p = this.closest('tr');
        var data = $('.example').DataTable().row(p).index();
        that.downloadAdmitCard(that.StudentList[data].id);
      });
  }

  downloadAdmitCard(id: any) {
    this.monthWise.admitCard(id).subscribe(res=>{
      console.log("AdmitCard",res);
        this.link = res.link
        console.log(res.link);
        $('#pdfModal').modal('show');
        $('#pdfModal #pdfViewer').prop('src', this.link);
    });
  }


  onChangeClass(sele_class:string){
    
    this.allSections=this.allSectionsObj[sele_class];

  }

}
