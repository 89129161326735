import { Component, OnInit } from '@angular/core';
import {superAdminService} from './dash-content.service'
import { AddUserService} from '../UserDetailsO/AddUser/adduser.service';

@Component({
  selector: 'app-dash-content',
  templateUrl: './dash-content.component.html',
  styleUrls: ['./dash-content.component.scss']
})
export class DashContentComponent implements OnInit {

  constructor(private serv:superAdminService, private serv2:AddUserService) { }
  student:number;
  teacher=0;
  dailyTransaction:number;
  user:number;
  monthlyTransaction:number;
  schoolId="all";
  schoolCount:number;
  schoolList=[];
  dailyCollection:number;
  monthlyCollection:number;
  ngOnInit() {
    this.detailCount(this.schoolId);
    this.getSchoolList();

  }
 detailCount(schoolId:any){
   this.serv.getDetails()
   .subscribe(res=>{
     if(schoolId==="all"){
      this.student=0;
      this.user=0;
      this.dailyTransaction=0;
      this.monthlyTransaction=0;
      this.schoolCount=0;
      this.dailyCollection=0;
      this.monthlyCollection=0;
         for(var key in res){
          this.student+=res[key].student;
          this.user+=res[key].user;
          this.dailyTransaction+=res[key].dailyTransaction;
          this.monthlyTransaction+=res[key].monthlyTransaction;
          this.dailyCollection=res[key].dailyCollection;
          this.monthlyCollection=res[key].monthlyCollection;
          this.schoolCount++;
         }
  
     }
     else{
        var details=res[schoolId];
        this.student=details.student;
        this.user=details.user;
        this.dailyTransaction=details.dailyTransaction;
        this.monthlyTransaction=details.monthlyTransaction;
        this.dailyCollection=details.dailyCollection;
        this.monthlyCollection=details.monthlyCollection;
     }
     
     console.log(res);
   })
 }
 getSchoolList(){
   this.serv2.getUserSchoolNames()
   .subscribe(res=>{
     this.schoolList=res;
   })
 }
 
}
