import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UserService } from '../Services/user.service';
import {NotifierService } from 'angular-notifier'
declare var $:any;
// import { UserService } from '../../shared/user.service'

@Component({
  selector: 'app-signup',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css','../loginstyle.css']
})
export class ForgetPasswordComponent implements OnInit {
  confirmOtp: number;
  otp: number;
  invalidOtp: boolean;
  validOtp: boolean;
  invalidMobile: boolean;
  schoolGroup: string;
  userDetail: any;
  passwordVar: any;
  confirmPasswordVar: string;
  

  constructor(private router : Router,
    private userService:UserService,
    private notifier:NotifierService
    ) { }

  showSucessMessage: boolean;
  serverErrorMessages: string;
  mob_no_field:boolean = false;
  mobileNo:any

  ngOnInit() {
    
  }


onSubmit(){
  console.log('submitted')
  this.validOtp = true;
  if(this.validOtp && this.userDetail && this.userDetail._id) {
    this.userService.resetPassward(this.mobileNo, this.passwordVar, this.userDetail._id)
        .subscribe( res =>{
            let isSuccess = res.msg;
            console.log(res.message);
          if(res.status){
            console.log("success",isSuccess );
            this.router.navigateByUrl('/signin');
          }else{
            this.notifier.notify("warning", res.message);
          }
          },
          error => {
            console.log("Error", error)
            this.notifier.notify("error","Network Error");
          }
        )
    }
}

 onSignin (){ //header Link
  this.router.navigate(['/signin']);
}

sentOtp() {    
    if(this.mobileNo && this.mobileNo > 1000000000 && this.mobileNo < 10000000000) {
      this.invalidMobile = false;
      this.userService.sendOtp(this.mobileNo, true).subscribe(res => {
        console.log(res)
        if(res.status) {
          this.confirmOtp = Number(res.otp);
          this.userDetail = res.userDetail;
          this.notifier.notify('success', 'Otp Sent Successfully.');
        } else {
          this.notifier.notify('warning', res.message);
        }
    },
    error => {
      this.notifier.notify('error', error);
    }); 
    } else {
      this.invalidMobile = true;
    }
}

verifyOtp() {
  if(this.confirmOtp === this.otp) {
    this.invalidOtp = false;
    this.validOtp = true;
  } else {
    this.invalidOtp = true;
    this.validOtp = false;
  }
}

}
