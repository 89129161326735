import { Injectable } from '@angular/core';
import {Router} from '@angular/router'
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AllApisService} from '../all-apis.service';

@Injectable({
  providedIn: 'root'
})
export class monthWiseStatusService {

  // category="";
  errorData: {};
  constructor(private _http: HttpClient ,private router:Router,private allApis:AllApisService) 
  { }

  monthWiseStatusURL = this.allApis.reporturl+'/transactions/monthWiseStatus';
  admitCardURL = this.allApis.baseurl+'/transactions/getAdmitCard';
  sessionInstallments=this.allApis.baseurl + '/school/sessionInstallment' ;
  monthWiseStatus({"classSearch":classSearch, "section":section, "month":month,session:session, "startDate":startDate,"endDate":endDate,useDate:useDate}) {

    return this._http.post<any>(this.monthWiseStatusURL,{"classSearch":classSearch, "section":section, "month":month,session:session,"startDate":startDate,"endDate":endDate,useDate:useDate},this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  admitCard(id:string) {

    let params = new HttpParams();

    params = params.append('id', id);

    this.allApis.httpOptions.params = params;

    return this._http.get<any>(this.admitCardURL,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  getSessionInstallments(session){
    let params = new HttpParams();
 
    params = params.append('session',session);
//    console.log(params);
    this.allApis.httpOptions.params = params;

    return this._http.get<any>(this.sessionInstallments, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
//  error handling
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }

}
