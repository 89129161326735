export class BasicUserDetail {
    id: string;
    userName: string;
    emailId: string;
    contactNo: string;
    schoolId: string;

    constructor(userDetail: any) {
        if (userDetail && Object.keys(userDetail).length > 0) {
            this.id = userDetail._id;
            this.userName = userDetail.firstName + ' ' + userDetail.lastName;
            this.emailId = userDetail.emailId;
            this.contactNo = userDetail.contactNo[0];
            this.schoolId = userDetail.schoolId[0];
        }
    }
}