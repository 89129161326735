import { Injectable } from '@angular/core';  
import { Router, NavigationStart } from '@angular/router';  
import { Observable } from 'rxjs';  
import { Subject } from 'rxjs/Subject';  

@Injectable({
  providedIn: 'root'
})

export class ConfirmDialogService {
     yes ='Yes';
     no ='No';
    private subject = new Subject<any>();  
    constructor() { }  

    confirmThis(message: string, headerMessage: string, siFn: () => void, noFn: () => void) {  
        this.setConfirmation(message, headerMessage, siFn, noFn);  
    }  
   
    setConfirmation(message: string, headerMessage: string, siFn: () => void, noFn: () => void) {  
       this.yes ='Yes';
       this. no ='No';
        let that = this;  
        this.subject.next({  
            type: "confirm",  
            text: message, 
            header: headerMessage, 
          yes :'Yes',
    no :'No',
            siFn:  
                function () {  
                    that.subject.next(); //this will close the modal  
                    siFn();  
                },  
            noFn: function () {  
                that.subject.next();  
                noFn();  
            }  
        });  
  
    }  
    setConfirmation1(v1,v2,message: string, headerMessage: string, siFn: () => void, noFn: () => void) { 
        this.yes=v1;
        this.no=v2; 
        let that = this;  
        this.subject.next({  
            type: "confirm",  
            text: message,
            yes :v1,
            no :v2, 
            header: headerMessage, 
            siFn:  
                function () {  
                    that.subject.next(); //this will close the modal  
                    siFn();  
                },  
            noFn: function () {  
                that.subject.next();  
                noFn();  
            }  
        });  
  
    }  
  
    getMessage(): Observable<any> {  
        return this.subject.asObservable();  
    } 
}
