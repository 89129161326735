import { Injectable } from '@angular/core';

 @Injectable({
    providedIn: 'root'
  })

export class SenderIdModel {
    _id: string;
    sendDetails:string;
    sendSMS:number;
}