import { Injectable } from '@angular/core';
import {Router} from '@angular/router'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import {AllApisService} from '../all-apis.service';

@Injectable({
  providedIn: 'root'
})
export class SiteV2Service {


  constructor(private http:HttpClient, private allApis:AllApisService) { }

  addQuery(query:any){
    return this.http.post<any>(this.allApis.query,query,this.allApis.httpOptions)
  }

  getQuery(queryDate:any){
    return this.http.get<any>(this.allApis.query,this.allApis.httpOptions);
  }


}
