import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class paymentModel{
    _id:string;
    module:string;
    onlinePay:boolean;
}