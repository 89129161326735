import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-categorywisepaireport',
  templateUrl: './categorywisepaireport.component.html',
  styleUrls: ['./categorywisepaireport.component.scss']
})
export class CategorywisepaireportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
