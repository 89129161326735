import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, retry, delay, retryWhen, scan, delayWhen } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { AllApisService } from '../../all-apis.service';



import { StudentModel } from '../modelClasses/studentModel';

@Injectable({
  providedIn: 'root'
})
export class AddStudentService {

  errorData: {};
  schoolId;
  editRegStorage;
  selectedStudent: StudentModel;
  Students: StudentModel[];
  // readonly baseURL = 'http://localhost:8080/registration';

  staticStates = ['Andhra Pradesh', 'Arunachal', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana',
    'Himachal', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram',
    'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh',
    'Uttarakhand', 'West Bengal', 'Andaman and Nicobar', 'Chandigarh', 'Dadar and Nagar', 'Daman and Diu', 'Delhi',
    'Lakshadweep', 'Puducherry', 'Jammu and Kashmir', 'Ladakh'];
  staticClasses = ["Study at Home", "PLAY-GROUP", "KINDERGARTEN", "NUR", "BABYSTEPS", "WALKERS", "RUNNERS", "Flyers"
    , "PG", "LKG", "Ukg", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"];
  allSessions = ['2019-20', '2020-21', '2021-22', '2022-23', '2023-24'];

  constructor(public http: HttpClient, public allApi: AllApisService) { }

  registrationByDateUrl = this.allApi.baseurl + '/registration/getByDate';
  eligibleByDateUrl = this.allApi.baseurl + '/registration/getByDateEligible';
  notEligibleByDateUrl = this.allApi.baseurl + '/registration/getByDateNotEligible';
  qualifiedByDateUrl = this.allApi.baseurl + '/registration/getByDateQualified';
  notQualifiedByDateUrl = this.allApi.baseurl + '/registration/getByDateNotQualified';
  interviewByDateUrl = this.allApi.baseurl + '/registration/getByDateInterview';
  registrationWithPaymentByDateUrl = this.allApi.baseurl + '/registration/getByDateWithPayment';
  registrationWithFormPaymentUrl = this.allApi.baseurl + '/registration/formPayment';
  admittedStudentUrl = this.allApi.baseurl + '/registration/admission';
  admittedWithPaymentStudentUrl = this.allApi.baseurl + '/registration/admissionWithPayment';
  admissionByDateUrl = this.allApi.baseurl + '/registration/admission/getByDate';
  admissionWithPaymentByDateUrl = this.allApi.baseurl + '/registration/admission/getByDateWithPayment';
  generateRegPdfUrl = this.allApi.baseurl + '/generatePdf/regPdf';
  generateAdmitPdfUrl = this.allApi.baseurl + '/generatePdf/admitPdf';
  sendGeneratePdfUrl = this.allApi.baseurl + '/admissionSendPdf/sendPdf';
  sendGenerateOtherPdfUrl = this.allApi.baseurl + '/admissionSendPdf/sendOtherPdf';
  sendGenerateRegPdfUrl = this.allApi.baseurl + '/admissionSendPdf/sendRegPdf';
  sendGenerateRegWithPaymentPdfUrl = this.allApi.baseurl + '/admissionSendPdf/sendRegWithPaymentPdf';
  sendGenerateAdmitPdfUrl = this.allApi.baseurl + '/admissionSendPdf/sendAdmitPdf';
  sendGenerateAdmitWithPaymentPdfUrl = this.allApi.baseurl + '/admissionSendPdf/sendAdmitWithPaymentPdf';
  eligibleStudentUrl = this.allApi.baseurl + '/registration/eligible';
  notEligibleStudentUrl = this.allApi.baseurl + '/registration/notEligible';
  getEligibleListUrl = this.allApi.baseurl + '/registration/getEligible';
  getNotEligibleListUrl = this.allApi.baseurl + '/registration/getNotEligible';

  getInterviewListUrl = this.allApi.baseurl + '/registration/getInterview';
  interviewStudentUrl = this.allApi.baseurl + '/registration/interview';

  qualifieStudentUrl = this.allApi.baseurl + '/registration/qualifie';
  notQualifieStudentUrl = this.allApi.baseurl + '/registration/notQualifie';
  notExamStudentUrl = this.allApi.baseurl + '/registration/notExam';
  getQualifieListUrl = this.allApi.baseurl + '/registration/getQualifie';
  getNotQualifieListUrl = this.allApi.baseurl + '/registration/getNotQualifie';
  sendSmsUrl = this.allApi.baseurl + '/sendSms';
  sendRegistrationMailUrl = this.allApi.baseurl + '/sendMail/registrationMail';
  sendAdmissionMailUrl = this.allApi.baseurl + '/sendMail/admissionMail';

  sendGenerateEligiblePdfUrl = this.allApi.baseurl + '/admissionSendPdf/sendEligiblePdf';
  sendGenerateInterviewPdfUrl = this.allApi.baseurl + '/admissionSendPdf/sendInterviewPdf';
  sendGenerateNotEligiblePdfUrl = this.allApi.baseurl + '/admissionSendPdf/sendNotEligiblePdf';
  sendGenerateQualifiePdfUrl = this.allApi.baseurl + '/admissionSendPdf/sendQualifiePdf';
  sendGenerateNotQualifiePdfUrl = this.allApi.baseurl + '/admissionSendPdf/sendNotQualifiePdf';

  formPaymentUrl = this.allApi.baseurl + '/registration/formPayment';
  feePaymentUrl = this.allApi.baseurl + '/registration/feePayment';

  updateToPadhaiPayUrl = this.allApi.baseurl + '/registration/updateToPadhaiPay';
  deleteStudentUrl = this.allApi.baseurl + '/registration/deleteStudent';

  getAgentNameUrl = this.allApi.baseurl + '/smAgent';
  sendSMSUrl = this.allApi.baseurl + '/registration/admissionSendSMS';
  
  getAgentName() {
    return this.http.get<any>(this.getAgentNameUrl)
  }

  // postSetStudent(student) {
  //   //  console.log( "engilry model in service",student);
  //   //  console.log(this.allApi.registrationFormUrl);
  //   // console.log(student);
  //   this.editRegStorage =student;
  //   // console.log(this.editRegStorage);
  //  // return this.http.post<any>(this.allApi.registrationFormUrl,student,this.allApi.httpOptions).pipe(catchError(this.handleError));
  // }
  // getStudent() {
  //   //  console.log( "engilry model in service",student);
  //   //  console.log(this.allApi.registrationFormUrl);
  //   // console.log(".......geting studsnetr -------",this.editRegStorage);
  //  return this.editRegStorage ;//=student;
  //   //console.log(this.editRegStorage);
  //  // return this.http.post<any>(this.allApi.registrationFormUrl,student,this.allApi.httpOptions).pipe(catchError(this.handleError));
  // }

  getAllSession() {
    return this.allSessions;
  }
  postStudent(student) {
    //  console.log( "engilry model in service",student);
    //  console.log(this.allApi.registrationFormUrl);
    return this.http.post<any>(this.allApi.registrationFormUrl, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  postAddSchool(addSchool) {
    return this.http.post<any>(this.allApi.addSchoolUrl + '/admission', addSchool);
  }

  updateAddSchool(addSchool) {
    return this.http.patch<any>(this.allApi.addSchoolUrl + '/updateAdmission', addSchool);
  }

  postSchoolImage(addSchool) {
    return this.http.post<any>(this.allApi.addSchoolUrl + '/schoolLogo', addSchool);
  }

  postSendOtp(obj) {
    return this.http.post<any>(this.allApi.addStudentUrl + '/send_otp', obj);
  }

  postVerifyOtp(obj) {
    return this.http.post<any>(this.allApi.addStudentUrl + '/verifyOtpSchool', obj);
  }

  getSchoolDetails(obj) {
    return this.http.post<any>(this.allApi.addSchoolUrl + '/schoolListForAdmission', obj);
  }

  getFilterCount() {
    return this.http.get<any>(this.allApi.addSchoolUrl + '/schoolFilterCount');
  }

  getSchoolDetailsAgent(obj) {
    return this.http.post<any>(this.allApi.addSchoolUrl + '/schoolListForAdmissionAgent', obj);
  }

  sendAdmissionSMS(student) {
    return this.http.post<any>(this.sendSMSUrl, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  getSchoolByText(obj) {
    return this.http.post<any>(this.allApi.addSchoolUrl + '/searchByText', obj);
  }

  getSchoolDetailsById(obj) {
    return this.http.post<any>(this.allApi.addSchoolUrl + '/schoolById', obj);
  }

  getStudentListOfParent(obj) {
    return this.http.post<any>(this.allApi.addStudentUrl + '/studentListAdmission', obj, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  setToken() {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.allApi.getToken()
    });
    this.allApi.httpOptions.headers = headers;
  }

  getStudentDetails(obj) {
    return this.http.post<any>(this.allApi.addStudentUrl + '/studentDetailsAdmission', obj, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  addStudentForAdmission(obj) {
    return this.http.post<any>(this.allApi.addStudentUrl + '/addStudentAdmission', obj, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  postConfig(student) {
    //  console.log( "engilry model in service",student);
    //  console.log(this.allApi.registrationFormUrl);
    return this.http.post<any>(this.allApi.preAdmissionUrl, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  updateConfig(student) {
    //  console.log( "engilry model in service",student);
    //  console.log(this.allApi.registrationFormUrl);
    return this.http.put<any>(this.allApi.preAdmissionUrl + `/${student._id}`, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postAdmittedStudent(student) {
    //  console.log( "engilry model in service",student);
    //  console.log(this.allApi.registrationFormUrl);
    return this.http.post<any>(this.allApi.admissionPageUrl, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getAdmittedStudentList() {
    //  console.log( "engilry model in service",student);
    //  console.log(this.allApi.registrationFormUrl);
    return this.http.get<any>(this.admittedStudentUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getAdmittedWithPaymentStudentList() {
    //  console.log( "engilry model in service",student);
    //  console.log(this.allApi.registrationFormUrl);
    return this.http.get<any>(this.admittedWithPaymentStudentUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getSearchAdmittedStudentList(startDate: string, endDate: string) {

    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.admissionByDateUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getSearchAdmittedWithPaymentStudentList(startDate: string, endDate: string) {

    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.admissionWithPaymentByDateUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postAdmissionFee(student) {
    //  console.log( "engilry model in service",student);
    //  console.log(this.allApi.registrationFormUrl);
    return this.http.post<any>(this.allApi.admissionFeeUrl, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getAdmissionFee() {
    //  console.log( "engilry model in service",student);
    //  console.log(this.allApi.registrationFormUrl);
    return this.http.get<any>(this.allApi.admissionFeeUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getSearchRegStudentList(reqObj) {

    let params = new HttpParams();
    
    params = params.append('appliedClass', reqObj['appliedClass']);
    params = params.append('startDate', reqObj['startDate']);
    params = params.append('endDate', reqObj['endDate']);
    params = params.append('subCategory', reqObj['subCategory']);
    // console.log(params);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.registrationByDateUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getSearchEligibleStudentList(startDate: string, endDate: string) {

    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    // console.log(params);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.eligibleByDateUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getSearchNotEligibleStudentList(startDate: string, endDate: string) {

    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    // console.log(params);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.notEligibleByDateUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getSearchQualifiedStudentList(startDate: string, endDate: string) {

    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    // console.log(params);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.qualifiedByDateUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getSearchNotQualifiedStudentList(startDate: string, endDate: string) {

    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    // console.log(params);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.notQualifiedByDateUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getSearchInterviewStudentList(startDate: string, endDate: string) {

    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    // console.log(params);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.interviewByDateUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getSearchRegWithPaymentStudentList(reqObj) {

    let params = new HttpParams();

    params = params.append('startDate', reqObj['startDate']);
    params = params.append('endDate', reqObj['endDate']);
    params = params.append('subCategory', reqObj['subCategory']);
    // console.log(params);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.registrationWithPaymentByDateUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  // getSearchAdmittedStudentList(startDate: string, endDate: string) {

  //   let params = new HttpParams();

  //   params = params.append('startDate', startDate);
  //   params = params.append('endDate', endDate);

  //   // console.log(params);

  //   this.allApi.httpOptions.params = params;
  //   return this.http.get<any>(this.registrationByDateUrl,this.allApi.httpOptions).pipe(catchError(this.handleError));
  // }
  postAcknowledgeMessage(student) {
    //  console.log( "engilry model in service",student);
    //  console.log(this.allApi.registrationFormUrl);
    return this.http.post<any>(this.allApi.messageUrl, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  updateAcknowledgeMessage(student) {
    //  console.log( "engilry model in service",student);
    //  console.log(this.allApi.registrationFormUrl);
    return this.http.put<any>(this.allApi.messageUrl + `/${student._id}`, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getAcknowledgeMessage() {
    //  console.log( "engilry model in service",student);
    //  console.log(this.allApi.registrationFormUrl);
    return this.http.get<any>(this.allApi.messageUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  getStudentList() {
    return this.http.get<any>(this.allApi.registrationFormUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getStudentWithFormPaymentList() {
    return this.http.get<any>(this.registrationWithFormPaymentUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  updateRegistrationDetails(student: any) {
    return this.http.put<any>(this.allApi.registrationFormUrl + `/${student._id}`, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getConfiguration() {
    return this.http.get<any>(this.allApi.preAdmissionUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  getConfigurationForEnquiry(schoolIdObj = null) {
    if(schoolIdObj) {
      let params = new HttpParams();

      params = params.append('schoolId', schoolIdObj);
  
      this.allApi.httpOptions.params = params;
    }
    return this.http.get<any>(this.allApi.baseurl + "/preAdmissionEnquiry", this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  
  postMail(obj) {

    return this.http.post<any>(this.allApi.mailServiceUrl, obj, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }



  postRegistrationMail(emailId: string, contactNo: string, subject: string) {
    let params = new HttpParams();

    params = params.append('emailId', emailId);
    params = params.append('contactNo', contactNo);
    params = params.append('subject', subject);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.sendRegistrationMailUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postAdmissionMail(emailId: string, contactNo: string, subject: string) {
    let params = new HttpParams();

    params = params.append('emailId', emailId);
    params = params.append('contactNo', contactNo);
    params = params.append('subject', subject);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.sendRegistrationMailUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postSms(contactNo: string) {
    let params = new HttpParams();

    params = params.append('contactNo', contactNo);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.sendSmsUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postPdf(startDate: string, endDate: string) {
    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.sendGeneratePdfUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postOtherPdf(startDate: string, endDate: string) {
    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.sendGenerateOtherPdfUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postRegPdf(startDate: string, endDate: string) {
    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.sendGenerateRegPdfUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postRegWithPaymentPdf(startDate: string, endDate: string) {
    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.sendGenerateRegWithPaymentPdfUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postAdmitPdf(startDate: string, endDate: string) {
    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.sendGenerateAdmitPdfUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postAdmitWithPaymentPdf(startDate: string, endDate: string) {
    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.sendGenerateAdmitWithPaymentPdfUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  generatePdf(startDate: string, endDate: string) {

    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;

    return this.http.get<any>(this.allApi.generatePdfUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  generateRegPdf(startDate: string, endDate: string) {

    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;

    return this.http.get<any>(this.generateRegPdfUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  generateAdmitPdf(startDate: string, endDate: string) {

    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;

    return this.http.get<any>(this.generateAdmitPdfUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  updateEligibleStudent(student: any) {
    return this.http.put<any>(this.eligibleStudentUrl + `/${student._id}`, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  updateNotEligibleStudent(student: any) {
    return this.http.put<any>(this.notEligibleStudentUrl + `/${student._id}`, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getEligibleStudent() {
    return this.http.get<any>(this.getEligibleListUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getNotEligibleStudent() {
    return this.http.get<any>(this.getNotEligibleListUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getInterviewStudent() {
    return this.http.get<any>(this.getInterviewListUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  updateInterviewStudent(student: any) {
    return this.http.put<any>(this.interviewStudentUrl + `/${student._id}`, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  updateQualifieStudent(student: any) {
    return this.http.put<any>(this.qualifieStudentUrl + `/${student._id}`, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  updateDeleteStudent(student: any) {
    return this.http.put<any>(this.deleteStudentUrl + `/${student._id}`, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  updateNotQualifieStudent(student: any) {
    return this.http.put<any>(this.notQualifieStudentUrl + `/${student._id}`, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  updateNotExamStudent(student: any) {
    return this.http.put<any>(this.notExamStudentUrl + `/${student._id}`, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getQualifieStudent() {
    return this.http.get<any>(this.getQualifieListUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  getNotQualifieStudent() {
    return this.http.get<any>(this.getNotQualifieListUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  updateFormPayment(student: any) {
    return this.http.put<any>(this.formPaymentUrl + `/${student._id}`, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  updateFeePayment(student: any) {
    return this.http.put<any>(this.feePaymentUrl + `/${student._id}`, student, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  updateToPadhaiPay(id) {
    return this.http.patch<any>(this.updateToPadhaiPayUrl, id, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postEligiblePdf(startDate: string, endDate: string) {
    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.sendGenerateEligiblePdfUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postInterviewPdf(startDate: string, endDate: string) {
    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.sendGenerateInterviewPdfUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postNotEligiblePdf(startDate: string, endDate: string) {
    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.sendGenerateNotEligiblePdfUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postQualifiePdf(startDate: string, endDate: string) {
    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.sendGenerateQualifiePdfUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }
  postNotQualifiePdf(startDate: string, endDate: string) {
    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);

    this.allApi.httpOptions.params = params;
    return this.http.get<any>(this.sendGenerateNotQualifiePdfUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }


  public handleError(error: HttpErrorResponse) {

    if (error.error instanceof ErrorEvent) {
      console.log("hello", error);
      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {
      console.log("hello", error);
      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
}
