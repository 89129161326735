import { NgModule } from '@angular/core';
import { CommonImportsModule} from '../CommonImportsO/common-imports.module'
import {SignInComponent} from './sign-in/sign-in.component';
import {SignUpComponent} from './sign-up/sign-up.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { SmSignInComponent } from './sm-sign-in/sm-sign-in.component';
 



@NgModule({
  declarations: [
    SignInComponent,
    SignUpComponent,
    ForgetPasswordComponent,
    SmSignInComponent,
     
  ],
  imports: [
    CommonImportsModule,
  ]
})
export class SigninSignoutOModule { }
