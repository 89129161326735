export class createRouteModel {
 name: string;
 stopage: string;
  amount: string;
  isEnable: boolean =true;
  isDestination: boolean=false;
  remark :string;

  updatedCostFrom: [{
    MonthName: string;
    amount: string ;
  }];
  session:String;
  schoolId: string;
  vehicleDetails: [{
    vehicalId: string;
    status: boolean;
    vehicleNo: string;
    registartionNo: string;
    gpsId: string;
    driverName: string;
    conducterName: string;
    
  }];

  addVehicleDetails (vehicleNo){
    var obj ={ vehicleNo: '0', vehicleType: '0', status: true ,registartionNo: '0', gpsId: '0', driverName: '0' }
    this.vehicleDetails.push()
  }
   constructor() {
    this.updatedCostFrom = [{ MonthName : 'n/a',  amount: '0' }];
    //this.vehicleDetails =[];
   this.vehicleDetails = [{
    vehicalId:"",
    status: true,
    vehicleNo: "",    registartionNo: "",
    gpsId: "",
    driverName: "",
    conducterName: "",
    
  }];
  }
}
