import { Component, OnInit ,Input ,Output , EventEmitter} from '@angular/core';

@Component({
  selector: 'app-installments',
  templateUrl: './installments.component.html',
  styleUrls: ['./installments.component.scss'],
  outputs:["childDataSource"]
})
export class InstallmentsComponent implements OnInit {
  @Input()
   //Data binding 
   private parentDataSource:any;
  @Output() 
   //Custom event
   private childDataSource = new EventEmitter();
   
  constructor() {
    //console.log("parentDataSource",this.parentDataSource);
   }

  ngOnInit() {
    this.childDataSource.emit({key:"default Obj."})
    console.log("parentDataSource",this.parentDataSource);
  }

}
