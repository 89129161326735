import { Component, OnInit } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { UserService } from "../../SigninSignoutO/Services/user.service";
import { AllApisService } from "../../all-apis.service";
import { OneStorage } from "../../GlobalDataStorage/oneStorage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AccessControlService } from "../../MasterSettingO/rollAccess/access-control.service";
import { AddStudentService } from "src/app/admission/services/add-student.service";
import { MasterServiceService } from "src/app/MasterSettingO/Services/master-service.service";
import { SharedService } from "./shared.service";
import { FeeCollectionService } from "src/app/CollectFeeO/generate-receipt22/service/fee-collection.service";
import { NotifierService } from "angular-notifier";
declare var $: any;

@Component({
  selector: "<app-dashboard1>",
  templateUrl: "dashboard.component.html",
  styleUrls: ["dashboard.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  hideBrand = false;
  preadmisionShow = true;
  schoolName: string;
  schoolUrl: string;
  schoolSession: string;
  whiteLabel = false
  allSession = [
    "2019-20",
    "2020-21",
    "2021-22",
    "2022-23",
    "2023-24",
    "2024-25",
  ];
  addSchool: boolean = false;
  listSchool: boolean = false;
  viewSchool: boolean = true;
  addUser: boolean = false;
  listUser: boolean = false;
  viewUser: boolean = true;
  userDetail: any;
  emailID = "";
  firstName = "";
  schoolObj: any;
  sideBarVar = true;
  schoolAddress: string;
  roleGrants = [];
  shopingForstudentEnable: boolean = false;
  usingRoyaltyModel: boolean = false;
  config: any;
  var1: boolean = false;
  isAgentCode = true;
  isParentAdmission = true;
  agentCode: any;
  lat: any;
  collectFeeTransport = false;
  long: any;
  hideLeftNav: boolean = false;

  newTransportFlag = false;

  createScheduling = true;
  createFine = true;
  createConcession = true;
  createCustomConcession = true;
  bulkAllotCustomConcession = true;
  onlinePayAccess = false;
  allSchoolName: any[];
  selectedSchoolId: any;
  modalData: any;
  searchQuery: string = '';
  moduleList: Array<{ name: string, url: string }> = [];
  focusedIndex: number = -1;

  constructor(
    private userService: UserService,
    private allapis: AllApisService,
    private router: Router,
    private roleServ: AccessControlService,
    public addStudentService: AddStudentService,
    public globals: OneStorage, //take this to the 'signinComponent'
    private maservice: MasterServiceService,
    public route: ActivatedRoute,
    public sharedService: SharedService,
    private _feeCollectionService: FeeCollectionService,
    public _notify: NotifierService,
  ) {
    //console.log("userDetail(from deshboard)",this.userDetail);
  }

  private callback1(res: {}, that: any) {

    that.schoolObj = res["schoolDetails"];

    that.selectedSchoolId = that.schoolObj._id;
    // that.sectionObj=res["sectionObj"];
    console.log("schoolObj 101", that.schoolObj);
    console.log("schoolObj", that.schoolObj);
    localStorage.setItem("city", that.schoolObj.adresss.city)
    localStorage.setItem("state", that.schoolObj.adresss.state)
    localStorage.setItem("pincode", that.schoolObj.adresss.pincode)

    that.newTransportFlag = that.schoolObj.usingNewTransport;
    console.log("that.schoolDetails", that.schoolObj, that.roleGrants);

    if (typeof that.schoolObj.useTransportCollection !== "undefined")
      that.collectFeeTransport = that.schoolObj.useTransportCollection;
    console.log(" this.collectFeeTransport", that.collectFeeTransport);
    if (
      that.schoolObj &&
      that.schoolObj["sourceId"] &&
      ["2112", "2113"].includes(that.schoolObj["sourceId"])
    ) {
      that.hideBrand = true;
    }
    that.userDetail = res["userDetails"];
    if (that.userDetail["roles"][0] !== "Parent" && that.roleGrants.length == 0)
      that.getRoleGrants(
        that.userDetail["roles"][0],
        that.userDetail["schoolId"][0]
      );
    if (that.userDetail["imageUrl"] !== "N/A") {
      that.userDetail["imageUrl"] =
        that.allapis.baseurl + "/" + that.userDetail["imageUrl"];
      $("#imageNew1").attr("src", that.userDetail["imageUrl"]);
      $("#imageNew2").attr("src", that.userDetail["imageUrl"]);
    } else {
      $("#imageNew1").attr("src", "assets/img/img1.png");
      $("#imageNew2").attr("src", "assets/img/img1.png");
    }
    if (that.schoolObj === undefined) {
      that.schoolName = " ";
      that.schoolSession = "2019-20";
    } else {
      that.schoolName = that.schoolObj["name"];
      if (that.schoolObj["session"]) {
        that.schoolSession = that.schoolObj["session"];
      } else {
        that.schoolSession = "2019-20";
      }
    }
    if (that.schoolObj["adresss"] === undefined) {
      // that.schoolName = " ";
      console.log("not working");
      that.schoolAddress = "";
    } else {
      that.schoolAddress = that.schoolObj["adresss"]["line1"];
    }
    // console.log(that.schoolObj);
    if (
      !that.schoolObj["logoUrl"] ||
      that.schoolObj["logoUrl"] == "" ||
      that.schoolObj["logoUrl"] == "N/A"
    ) {
      that.schoolUrl = " ";
    }
    //  else if (that.schoolObj["logoUrl"] && that.schoolObj["logoHost"]) {
    //   that.schoolUrl = that.schoolObj["logoHost"] + that.schoolObj["logoUrl"];
    // } 
    else {
      console.log("here is logo data..........else....")
      that.schoolUrl = that.schoolObj["logoUrl"];
      // "https://d3nwkpv8dwssuq.cloudfront.net/" +
      // that.schoolObj["logoUrl"].replace(/\\/g, "/");
    }
    that.addStudentService.schoolId = that.userDetail.schoolId; // Isko koi haath nhi lagayega :)
    console.log(that.userDetail);




  }
  // updateSchoolname(schoolName){
  //   this.schoolName=schoolName;
  // }
  ngOnInit() {
    this.searchQuery = '';
    if (localStorage.getItem("userType") && localStorage.getItem("userType") == "Parent") {
      this.moveToMobileOrSm();
    }
    let localHideLeftNav = localStorage.getItem("hideLeftNav");
    if (localHideLeftNav && localHideLeftNav == "Y") {
      this.hideLeftNav = true;
    }
    if (this.route.queryParams) {
      this.route.queryParams.subscribe((params) => {
        console.log("side", params.agentCode);
        if (params.agentCode) {
          this.isAgentCode = false;
          this.agentCode = params.agentCode;
          for (var key in this.map) {
            this.map[key] = false;
          }
          this.preadmisionShow = false;
          this.onlinePayAccess = false;
          this.map["agentSchool"] = true;
          return;
        }
        if (params.token) {
          localStorage.setItem("token", params.token);
        }
        if (params.lat && params.long) {
          for (var key in this.map) {
            this.map[key] = false;
          }
          this.isAgentCode = false;
          this.lat = params.lat;
          this.long = params.long;
          this.preadmisionShow = false;
          this.isParentAdmission = false;
          this.map["parentAdmission"] = true;
        }
      });
    }
    if (
      this.globals.schoolDetails === null ||
      this.globals.schoolDetails === undefined ||
      Object.entries(this.globals.schoolDetails).length === 0 ||
      this.globals.userDetails === null ||
      this.globals.userDetails === undefined ||
      Object.entries(this.globals.userDetails).length === 0
    ) {
      this.globals.initiateUserDetails(this.callback1, this);
      this.globals.updateHeaderList(undefined, this);
      // this.schoolName=this.schoolObj["name"];
    } else {
      console.log("already there", this.schoolObj);
      this.schoolObj = this.globals.schoolDetails;
      this.schoolObj.sessionForOldTransport = []
      this.schoolName = this.schoolObj["name"];
      if (
        this.schoolObj &&
        this.schoolObj["sourceId"] &&
        ["2112", "2113"].includes(this.schoolObj["sourceId"])
      ) {
        this.hideBrand = true;
      }
      this.getRoleGrants(
        this.userDetail["roles"][0],
        this.userDetail["schoolId"][0]
      );
      if (this.userDetail["imageUrl"] !== "N/A") {
        this.userDetail["imageUrl"] =
          this.allapis.baseurl + "/" + this.userDetail["imageUrl"];
        $("#imageNew1").attr("src", this.userDetail["imageUrl"]);
        $("#imageNew2").attr("src", this.userDetail["imageUrl"]);
      } else {
        $("#imageNew1").attr("src", "assets/img/img1.png");
        $("#imageNew2").attr("src", "assets/img/img1.png");
      }
    }

    this.addStudentService.getConfiguration().subscribe((res) => {
      this.config = res;
      console.log("get config", this.config);
      if (
        this.config &&
        this.config.payment &&
        this.config.payment.showHide == "Show"
      ) {
        this.var1 = true;
      }
    });

    this.getShopingForstudentEnable();

    this.fatchSchoolNamesNID();

    let white = localStorage.getItem("whitelabel");
    console.log("whitelabel", white);
    if (white && white == "Y") {
      this.whiteLabel = true;
    }
    // console.log('ngonit in dashboard', localStorage.getItem('schoolDetail'))
    // if(localStorage.getItem('schoolDetail') && localStorage.getItem('schoolDetail') != undefined && localStorage.getItem('schoolDetail') != 'undefined'){
    //         try {
    //             this.schoolObj= JSON.parse(localStorage.getItem('schoolDetail'));
    //             this.schoolName=schoolObj["name"];
    //         } catch(e) {
    //             console.log(e); // error in the above string (in this case, yes)!

    //     }
    // }

    // else{
    //     this.schoolName=" ";
    // }

    //  if(localStorage.getItem('userDetail') && localStorage.getItem('userDetail') != undefined && localStorage.getItem('userDetail') != 'undefined'){
    //         try {
    //             this.userDetail = JSON.parse(localStorage.getItem('userDetail'));
    //         } catch(e) {
    //             console.log(e); // error in the above string (in this case, yes)!

    //     }
    // }

    $(".br-sideleft").addClass(localStorage.getItem("sideleftColor"));
    $(".br-menu-sub").addClass(localStorage.getItem("menusubColor"));
    $(".br-menu-link").addClass(localStorage.getItem("hoverColor"));
    $(".br-menu-link.active").addClass(
      localStorage.getItem("menusubActiveColor")
    );
    $(".ec-handle").addClass(localStorage.getItem("themeBtnColor"));
    $(".user-profile-nav .icon ").addClass(
      localStorage.getItem("headerCtrColor")
    );
    $(".br-header-center .defaultHdrColr").addClass(
      localStorage.getItem("headerCtrColor")
    );
    $(".br-header-left .navicon-left .menu li a i").addClass(
      localStorage.getItem("headerCtrColor")
    );
    let today = new Date().toLocaleDateString()
    $(".currentDate").text(today)
    $(".show-sub + .br-menu-sub").slideDown();
    $("#btnLeftMenu").on("click", function () {
      var menuText = $(".menu-item-label,.menu-item-arrow");
      if ($("body").hasClass("collapsed-menu")) {
        $("body").removeClass("collapsed-menu");
        $(".sidebar-footer").css("visibility", "visible");
        $(".show-sub + .br-menu-sub").slideDown();
        $(".br-sideleft").one("transitionend", function (e) {
          menuText.removeClass("op-lg-0-force");
          menuText.removeClass("d-lg-none");
        });
      } else {
        $("body").addClass("collapsed-menu");
        $(".show-sub + .br-menu-sub").slideUp();
        menuText.addClass("op-lg-0-force");
        $(".br-sideleft").one("transitionend", function (e) {
          menuText.addClass("d-lg-none");
        });
      }
      return false;
    });
    $(document).on("mouseover", function (e) {
      e.stopPropagation();
      if (
        $("body").hasClass("collapsed-menu") &&
        $("#btnLeftMenu").is(":visible")
      ) {
        var targ = $(e.target).closest(".br-sideleft").length;
        if (targ) {
          $("body").addClass("expand-menu");
          // show current shown sub menu that was hidden from collapsed
          $(".show-sub + .br-menu-sub").slideDown();
          var menuText = $(".menu-item-label,.menu-item-arrow");
          menuText.removeClass("d-lg-none");
          menuText.removeClass("op-lg-0-force");
          $(".sidebar-footer").css("visibility", "visible");
        } else {
          $("body").removeClass("expand-menu");
          // hide current shown menu
          $(".show-sub + .br-menu-sub").slideUp();
          var menuText = $(".menu-item-label,.menu-item-arrow");
          menuText.addClass("op-lg-0-force");
          menuText.addClass("d-lg-none");
          $(".sidebar-footer").css("visibility", "hidden");
        }
      }
    });

    $("#btnLeftMenuMobile").on("click", function () {
      $("body").addClass("show-left");
      return false;
    });
    $(document).on("click", function (e) {
      e.stopPropagation();
      // closing left sidebar
      if ($("body").hasClass("show-left")) {
        var targ = $(e.target).closest(".br-sideleft").length;
        if (!targ) {
          $("body").removeClass("show-left");
        }
      }
      // closing right sidebar
      if ($("body").hasClass("show-right")) {
        var targ = $(e.target).closest(".br-sideright").length;
        if (!targ) {
          $("body").removeClass("show-right");
        }
      }
    });

    $(".js-search").click(function () {
      $(".search-bar").addClass("open");
    });
    $(".close-search").click(function () {
      $(".search-bar").removeClass("open");
    });

    (function ($) {
      "use strict";
      $(".ec-handle").on("click", function () {
        $(".ec-colorswitcher").trigger("click");
        $(this).toggleClass("btnclose");
        $(".ec-colorswitcher").toggleClass("sidebarmain");
        return false;
      });
    })($);

    $(".styleswitch").click(function () {
      switchStylestyle(this.getAttribute("data-rel"));
      return false;
    });

    function switchStylestyle(styleName) {
      var sideleftColrArr = [
        "color-one",
        "color-two",
        "color-three",
        "color-four",
      ];
      const menusubColrArr = [
        "cs-menu-one",
        "cs-menu-two",
        "cs-menu-three",
        "cs-menu-four",
      ];
      const menusubActiveColorArr = [
        "active-menu-one",
        "active-menu-two",
        "active-menu-three",
        "active-menu-four",
      ];
      const hoverColArr = [
        "hover-menu-one",
        "hover-menu-two",
        "hover-menu-three",
        "hover-menu-four",
      ];
      const themeBtnColArr = [
        "color-theme-one",
        "color-theme-two",
        "color-theme-three",
        "color-theme-four",
      ];
      const hederCtrColorArr = [
        "active-hdrCtr-one",
        "active-hdrCtr-two",
        "active-hdrCtr-three",
        "active-hdrCtr-four",
      ];
      for (var i = 0; i < sideleftColrArr.length; i++) {
        if (styleName === sideleftColrArr[i]) {
          if (typeof Storage !== "undefined") {
            localStorage.setItem("sideleftColor", sideleftColrArr[i]);
            localStorage.setItem("menusubColor", menusubColrArr[i]);
            localStorage.setItem("hoverColor", hoverColArr[i]);
            localStorage.setItem(
              "menusubActiveColor",
              menusubActiveColorArr[i]
            );
            localStorage.setItem("themeBtnColor", themeBtnColArr[i]);
            localStorage.setItem("headerCtrColor", hederCtrColorArr[i]);
          }
          $(".br-sideleft").addClass(sideleftColrArr[i]);
          $(".br-menu-sub").addClass(menusubColrArr[i]);
          $(".br-menu-link").addClass(hoverColArr[i]);
          $(".br-menu-link.active").addClass(menusubActiveColorArr[i]);
          $(".ec-handle").addClass(themeBtnColArr[i]);
          $(".user-profile-nav .icon ").addClass(hederCtrColorArr[i]);
          $(".br-header-center .defaultHdrColr").addClass(hederCtrColorArr[i]);
          $(".br-header-left .navicon-left .menu li a i").addClass(
            hederCtrColorArr[i]
          );
          $(".currentDate").addClass(hederCtrColorArr[i]);
        } else {
          $(".br-sideleft").removeClass(sideleftColrArr[i]);
          $(".br-menu-sub").removeClass(menusubColrArr[i]);
          $(".br-menu-link").removeClass(hoverColArr[i]);
          $(".br-menu-link.active").removeClass(menusubActiveColorArr[i]);
          $(".ec-handle").removeClass(themeBtnColArr[i]);
          $(".user-profile-nav .icon ").removeClass(hederCtrColorArr[i]);
          $(".br-header-center .defaultHdrColr").removeClass(
            hederCtrColorArr[i]
          );
          $(".br-header-left .navicon-left .menu li a i").removeClass(
            hederCtrColorArr[i]
          );
          $(".currentDate").removeClass(hederCtrColorArr[i]);
        }
      }
    }
    $("#btnAppsMenu").on("click", function () {
      console.log("Showing AppMenu...");
    });
    var date = new Date();
    var months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    var val =
      months[date.getMonth()] + " " + date.getDate() + " " + date.getFullYear();

    var time = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    $(".currentTime").text(time);
  }

  fatchSchoolNamesNID() {
    let tempResArr: Array<any>;
    let tempVarArr = [];
    console.log("tokenschool : ", localStorage.getItem("token"));
    //this.schoolNameNIDs
    this._feeCollectionService.getUserSchoolNames().subscribe(
      (res) => {
        console.log("School Names: ", res);
        tempResArr = res;
      },
      (err) => {
        console.log("School Error: ", err);
      },
      () => {
        tempResArr.forEach((schNam) => {
          tempVarArr.push(schNam);
        });

        localStorage.setItem("schoolList", JSON.stringify(tempVarArr));
      }
    );
    this.allSchoolName = tempVarArr;
  }

  onSchoolChange() {
    // localStorage.clear()
    if (this.globals.userDetails.roles[0] === "Director") {
      console.log("selectedSchoolId", this.selectedSchoolId);
      this._feeCollectionService
        .getupdatedToken(this.selectedSchoolId)
        .subscribe((res) => {
          console.log("updated token --------0----------", res);
          localStorage.removeItem("token");
          localStorage.setItem("token", res.token);
          localStorage.removeItem("schoolId");
          localStorage.setItem("schoolId", res.school._id);
          this._feeCollectionService.allApisService.updateTokenFun();
          this.globals.initiateUserDetails(this.callback1, this)
          this.sharedService.sendClickEventForSchool();
          console.log("logo", res.school.logoUrl);
          var adress = res.school.adresss.line1 + " " + res.school.adresss.line2;
          var logourl = res.school["logoHost"] + res.school["logoUrl"];
          this.updateSchoolName(
            res.school.name,
            adress,
            logourl
          );
        });
    } else {
      this._notify.notify(
        "info",
        "Changes of school only allowed for director role"
      );
    }
  }
  schoolListParent() {
    this.router.navigate(["/dashboard/admission/searchSchool/"], {
      queryParams: { lat: this.lat, long: this.long },
    });
  }
  statusParent() {
    this.router.navigate(["/dashboard/admission/parentStudentStatus/"], {
      queryParams: { lat: this.lat, long: this.long },
    });
  }
  sideAddSchool() {
    this.router.navigate(["/dashboard/admission/addSchool/"], {
      queryParams: { agentCode: this.agentCode },
    });
  }

  searchSchool() {
    this.router.navigate(["/dashboard/admission/schoolListForAgent/"], {
      queryParams: { agentCode: this.agentCode },
    });
  }

  cd() {
    console.log(this.sideBarVar);
  }

  onSessionChange() {
    console.log("session", this.globals.activeSession)
    this.sharedService.sendClickEvent(this.globals.activeSession);
  }

  onSignOut() {
    // console.log("method fired");
    this.globals.destroyVariables();
    this.allapis.logoutUser();

    // localStorage.removeItem('token');
    // localStorage.removeItem('userDetail');
    // localStorage.clear();
    // // location.reload();
    //this.router.navigate([""]);
    // console.log("Token now :"+ this.allapis.getToken())
  }

  justNavigate() {
    console.log("this.map here", this.map)
    if (localStorage.getItem("userType") == "Super Admin") {
      this.router.navigate(["/dashboard/welcomeScreen"]);
    } else if (localStorage.getItem("userType") == "Admission In-charge" && this.map["Pre Admission"]) {
      this.router.navigate(["/dashboard/admission"]);
    } else if (this.map["Collect Fee"]) {
      // this.router.navigate(["/dashboard/collect/generatereceipt"]);
      this.router.navigate(["/dashboard/collect/CollectFeeMiscComponent"]);
    } else if (this.map["School Shop"]) {
      this.router.navigate(["/dashboard/inventory/addCategory"]);
    } else if (localStorage.getItem("userType") == "Parent") {
      this.moveToMobileOrSm();
    } else {
      this.router.navigate(["/dashboard/collect/CollectFeeMiscComponent"]);
    }
  }

  moveToMobileOrSm() {
    var resObj = {}
    resObj['status'] = true
    resObj['message'] = "Failed"
    resObj['result'] = {}
    console.log('json objec', JSON.stringify(resObj));
    if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.errorViewCallBack.postMessage(JSON.stringify(resObj));
    } else if (window.Android) {
      window.Android.maketoast(JSON.stringify(resObj));
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      localStorage.clear();
      window.location.href = 'https://schoolmitra.com/';
    }
  }

  changeIcon() {
    if (this.sideBarVar === true) {
      this.sideBarVar = false;
    } else {
      this.sideBarVar = true;
    }
  }

  openModal() {
    // Use jQuery to show the modal
    // Make sure you have jQuery and Bootstrap scripts included in your project
    // Alternatively, you can use Angular's ViewChild and ElementRef to interact with the DOM
    this._feeCollectionService.getCollectionDetails(this.globals.presentSession).subscribe((res) => {
      console.log("what res", res.data.Daily, "this.globals.activeSession", this.globals.activeSession)
      this.modalData = res.data.Daily
      $('#exampleModalCenter').modal('show');
    })
  }

  getRoleGrants(role: any, schoolId: any) {
    console.log("roles", role, schoolId);
    this.map["Dashboard"] = true;
    if (role === "Parent") {
      for (var key in this.map) {
        this.map[key] = false;
      }
      this.preadmisionShow = false;
      this.onlinePayAccess = false;
      this.map["parentAdmission"] = true;
      // this.router.navigate(['/dashboard/welcomeScreen']);
    } else if (
      ["Super Admin", "Director", "Chairman", "Directorpa" , "Principal"].includes(role)
    ) {
      if(role === "Principal"){
        role = "Director"
      }
      this.map["Master Setting"] = true;
      this.onlinePayAccess = true;
    } else if (role === "PPAdmin") {
      for (var key in this.map) {
        this.map[key] = false;
      }
      this.preadmisionShow = false;
      this.map["ppSchoolList"] = true;
      this.map["ppSmsUpdates"] = true;
      this.map["ppQueryList"] = true;
      this.map["ppSyncAll"] = true;
      this.map["ppMerchantList"] = true;
      this.map["ppAgentList"] = true;
      return;
    } else if (role === "Agent") {
      for (var key in this.map) {
        this.map[key] = false;
      }
      this.onlinePayAccess = false;
      this.preadmisionShow = false;
      this.map["agentSchool"] = true;
      return;
    } else if (role === "Admission In-charge") {
      for (var key in this.map) {
        this.map[key] = false;
      }
      this.map["Pre Admission"] = true;
      this.onlinePayAccess = false;
      console.log(this.map);
      this.preadmisionShow = false;
      return;
    } else if (role === "Inventory Controller") {
      for (var key in this.map) {
        this.map[key] = false;
      }
      //this.map["Daily Book"] = true;
      this.map["Inventory"] = true;
      //this.map["School Shop"] = true;
      this.onlinePayAccess = false;
      console.log(this.map);
      this.preadmisionShow = false;
      // return;
    } else if (role === "Admin") {
      this.onlinePayAccess = true;
    }
    this.roleServ.fetchRoles(schoolId).subscribe((res) => {
      console.log(res);
      loadSidebar();
      this.roleGrants = res.roleAccesslist;
      this.roleServ.roleGrants = res.roleAccesslist;
      this.roleGrants.forEach((roleobj) => {
        if (roleobj["role"] === role) {
          roleobj.defination.forEach((elem) => {
            this.map[elem.module] = elem.inUse;
            console.log(
              "this.map[elem.module]",
              elem.module,
              this.map[elem.module]
            );
            if (elem.module == "Create Scheduling" && !elem.inUse) {
              this.createScheduling = false;
            }
            if (elem.module == "Create Fine" && !elem.inUse) {
              this.createFine = false;
            }
            if (elem.module == "Create Concession" && !elem.inUse) {
              this.createConcession = false;
            }
            if (elem.module == "Create Custom Concession" && !elem.inUse) {
              this.createCustomConcession = false;
            }
            if (elem.module == "Bulk allot Custom Concession" && !elem.inUse) {
              this.bulkAllotCustomConcession = false;
            }
          });
        }
      });
    });
    function loadSidebar() {
      $(".br-menu-link").on("click", function () {
        var nextElem = $(this).next();
        var thisLink = $(this);
        if (nextElem.hasClass("br-menu-sub")) {
          if (nextElem.is(":visible")) {
            thisLink.removeClass("show-sub");
            nextElem.slideUp();
          } else {
            $(".br-menu-link").each(function () {
              $(this).removeClass("show-sub");
            });
            $(".br-menu-sub").each(function () {
              $(this).slideUp();
            });
            thisLink.addClass("show-sub");
            nextElem.slideDown();
          }
          return false;
        } else {
          $(".br-menu-link").each(function () {
            $(this).removeClass("show-sub");
          });

          $(".br-menu-sub").each(function () {
            $(this).slideUp();
          });

          thisLink.addClass("show-sub");
        }
      });
    }
  }
  //   master_access(moduleName:any){
  //     if(this.userDetail["schoolId"][0]===undefined || this.userDetail["schoolId"][0]===null){return this.has_access(moduleName);}
  //     // if (this.userDetail !== null && this.userDetail !== undefined &&   this.userDetail["roles"] !==undefined && this.userDetail["roles"] !==null && this.userDetail["roles"].length>=0) {

  //     // var role=this.userDetail["roles"][0];
  //     // // console.log(this.roleGrants);
  //     // this.roleGrants[0].defination.forEach(elem1=>{
  //     //     if(elem1.module===moduleName){
  //     //         // console.log(moduleName);
  //     //      console.log(elem1.inUse);
  //     //       return elem1.inUse;

  //     //     }
  //     //   })
  //     // }

  //   }

  getShopingForstudentEnable() {
    var that = this
    that.maservice.getOthersInfo().subscribe((res) => {
      console.log("--getShopingForstudentEnable-", res.config[0].usingRoyaltyModel);
      if (res.config.length > 0) {
        that.shopingForstudentEnable = res.config[0].shopingForstudent;
        that.usingRoyaltyModel = res.config[0].usingRoyaltyModel;
      }
    });
  }
  updateSchoolName(test, adress, logourl) {
    this.schoolName = test;
    this.schoolAddress = adress;
    this.schoolUrl = logourl;
    console.log("school", this.schoolName)
  }
  has_access(module) {
    switch (module) {
      case "School SetUp":
        return true;
      case "User Details":
        return true;
      case "Master Setting":
        return true;
      case "General Setting":
        return true;
      default:
        return false;
    }
  }
  map = {
    "School Setup": false,
    "User Details": false,
    "Student Details": false,
    Transport: false,
    Scheduling: false,
    Inventory: false,
    "Daily Book": false,
    "School Shop": false,
    Reporting: false,
    "Master Setting": false,
    "General Setting": false,
    "Pre Admission": false,
    ppSchoolList: false,
    ppQueryList: false,
    ppSmsUpdates: false,
    ppSyncAll: false,
    ppMerchantList: false,
    parentAdmission: false,
  };

  onSearch() {

    if (this.searchQuery != "") {
      this.moduleList = [
        { name: 'Collect Fee', url: '/dashboard/collect/CollectFeeMiscComponent' },
        { name: 'Fee Category', url: '/dashboard/school/editfeecatagories' },
        { name: 'Fee Installment', url: '/dashboard/school/editinstallments' },
        { name: 'Student List', url: '/dashboard/student/studentlist' },
        { name: 'Scheduling', url: '/dashboard/collect/createscheduling' },
        { name: 'Fine', url: '/dashboard/addfine' },
        // Add more modules here
      ];
    } else {
      return this.moduleList = []
    }

    const query = this.searchQuery.toLowerCase();

    // Dummy data for modules, replace with actual data
    const allModules = [
      { name: 'Collect Fee', url: '/dashboard/collect/CollectFeeMiscComponent' },
      { name: 'Edit School', url: '/dashboard/school/edit' },
      { name: 'View Class Section', url: '/dashboard/school/stdsectionupdate' },
      { name: 'Fee Category', url: '/dashboard/school/editfeecatagories' },
      { name: 'Fee Installment', url: '/dashboard/school/editinstallments' },
      { name: 'Add User', url: '/dashboard/user/add' },
      { name: 'User List', url: '/dashboard/user/list' },
      { name: 'Student List', url: '/dashboard/student/studentlist' },
      { name: 'Add Fee', url: '/dashboard/student/studentlist' },
      { name: 'Student Fee Adjustment report', url: '/dashboard/student/feeAdjustReport' },
      { name: 'Create Scheduling', url: '/dashboard/collect/createscheduling' },
      { name: 'Create  Fine', url: '/dashboard/addfine' },
      { name: 'Day Wise Fine', url: '/dashboard/addfine' },
      { name: 'Month Wise Fine', url: '/dashboard/addfine' },
      { name: 'Interval Wise Fine', url: '/dashboard/addfine' },
      { name: 'Standard Concession', url: '/dashboard/concession/standardConcession' },
      { name: 'Custom Concession', url: '/dashboard/concession/customConcession' },
      { name: 'Create Concession', url: '/dashboard/concession/standardConcession' },
      { name: 'Create Custom Concession', url: '/dashboard/concession/createcustomConcession' },
      { name: 'Bulk Allot Custom Concession', url: '/dashboard/concession/updateBulkConcession' },
      { name: 'Transport Reconciliation Report', url: '/dashboard/collect/transportReconciliationReport' },
      { name: 'Hostel Fee Reconciliation Report', url: '/dashboard/collect/hostelFeeReconciliationReport' },
      { name: 'General Settings', url: '/dashboard/masterSettings/general_settings' },
      { name: 'Transaction History Report', url: '/dashboard/collect/transactionHistory' },
      { name: 'Fee Reconciliation Report', url: '/dashboard/collect/feeReconcelationList' },
      { name: 'Defaulter List Report', url: '/dashboard/collect/defaulterReport' },
      { name: 'Pay Slip', url: '/dashboard/collect/defaulterReport' },
      { name: 'Fee Reminder', url: '/dashboard/collect/defaulterReport' },

      { name: 'Skip Month', url: '/dashboard/masterSettings/general_settings' },
      { name: 'Receipt Settings', url: '/dashboard/masterSettings/general_settings' },
      { name: 'Session Settings', url: '/dashboard/masterSettings/general_settings' },
      { name: 'Transport Settings', url: '/dashboard/masterSettings/general_settings' },
      { name: 'Other Settings', url: '/dashboard/masterSettings/general_settings' },
      { name: 'Inventory Settings', url: '/dashboard/masterSettings/general_settings' },
      { name: 'Fee Category Group Settings', url: '/dashboard/masterSettings/general_settings' },
      { name: 'School Discount Settings', url: '/dashboard/masterSettings/general_settings' },

      { name: 'Common Discount Settings', url: '/dashboard/masterSettings/general_settings' },
      { name: 'One-Time Discount Settings', url: '/dashboard/masterSettings/general_settings' },
      { name: 'TC/ADMISSION FEE/CHEQUE Settings/Online Payment Block For Next Session', url: '/dashboard/masterSettings/general_settings' },
      { name: 'ITR Report Settings', url: '/dashboard/masterSettings/general_settings' },
      { name: 'Online Fee Collection Settings', url: '/dashboard/masterSettings/general_settings' },
      { name: 'Copy Scheduling', url: '/dashboard/masterSettings/general_settings' },



      { name: 'Add Ledger', url: '/dashboard/expense/addLedger' },
      { name: 'Add SubLedger', url: '/dashboard/expense/addSubLedger' },
      { name: 'Transaction', url: '/dashboard/expense' },
      { name: 'Transaction Report', url: '/dashboard/expense/expenseReport' },
      { name: 'Cancelled Transaction', url: '/dashboard/expense/expenseReportCancelled' },
      { name: 'Passbook', url: '/dashboard/expense/passbook' },


      { name: 'Add Category', url: '/dashboard/expense/addCategory' },
      { name: 'Add Assets', url: '/dashboard/expense/schoolProduct' },
      { name: 'Add Vendor', url: '/dashboard/expense/addVendor' },
      { name: 'Create Purchase Order', url: '/dashboard/expense/addPurchaseOrder' },


      { name: 'Purchase Order Report', url: '/dashboard/expense/purchaseOderReport' },
      { name: 'Add Location', url: '/dashboard/expense/schoolLocationAdd' },
      { name: 'Asset Entry', url: '/dashboard/expense/schoolProductDistribution' },
      { name: 'Asset Management', url: '/dashboard/expense/schoolInventoryManagement' },
      { name: 'Asset Issue/Consumption', url: '/dashboard/expense/schoolProductTransaction' },
      { name: 'Additional Asset List', url: '/dashboard/expense/assetList' },
      { name: 'Inventory Report', url: '/dashboard/expense/schoolProductTransactionReport' },
      { name: 'Sold/Donate/Damage Asset', url: '/dashboard/expense/schoolProductSold' },


      { name: 'Add Category', url: '/dashboard/inventory/addCategory' },
      { name: 'Add Product', url: '/dashboard/inventory/product' },
      { name: 'Add Variant', url: '/dashboard/inventory/variant' },
      { name: 'Stock Distribution', url: '/dashboard/inventory/shopwiseDescription' },
      { name: 'Issue/Consumption', url: '/dashboard/inventory/transactionInventory' },
      { name: 'Return/Exchange', url: '/dashboard/inventory/cancelAndExchange' },
      { name: 'Stock Management', url: '/dashboard/inventory/inventoryManagement' },
      { name: 'Transaction Report(School Shop)', url: '/dashboard/inventory/transactionReport' },
      { name: 'Cancellation Report', url: '/dashboard/inventory/cancellationReport' },
      { name: 'Due/Concession Report', url: '/dashboard/inventory/dueConcessionReport' },
      { name: 'Supply Report', url: '/dashboard/inventory/supplyReport' },
      { name: 'Configuration (school shop receipt)', url: '/dashboard/inventory/receiptConfig' },


      { name: 'Collect Fees Transport Report', url: '/dashboard/collect/transactionHistory/transport' },
      { name: 'Analytics Report', url: '/dashboard/collect/analyaticsReport' },
      { name: 'Instant Concession Transaction Report', url: '/dashboard/collect/InstancetransactioneportComponent' },

      { name: 'Transaction View and Cancel Report', url: '/dashboard/collect/TransactionHistoryViewCancelComponent' },
      { name: 'Category Wise Paid Report', url: 'dashboard/collect/categorwisepaid' },
      { name: 'Category Wise Transaction Report', url: '/dashboard/collect/TransactionHistoryCategorywiseComponent' },
      { name: 'Category Wise Summary Report', url: '/dashboard/collect/categoryWiseSummeryReport' },
      { name: 'Mode Wise Report', url: '/dashboard/collect/DailyModeWiseReport' },


      { name: 'Cheque Status Update Report', url: '/dashboard/collect/checkStatus' },
      { name: 'Online Payments Report', url: 'dashboard/schoolTransactionList' },
      { name: 'Student Reporting', url: '/dashboard/student/studentreporting' },
      { name: 'Imported Due Report', url: '/dashboard/collect/importedDue' },
      { name: 'Student Ledger Report', url: '/dashboard/collect/studentLedgerReport' },

      { name: 'Scheduled Fees Report', url: '/dashboard/collect/scheduledFeesReport' },
      { name: 'Monthwise Collection Report', url: '/dashboard/collect/StudentstransactionreportComponent' },
      { name: 'Defaulter List Report (With Config)', url: '/dashboard/collect/defaulterListWithConfig' },
      { name: 'Defaulter List Report (Fee Category Wise)', url: '/dashboard/collect/defaulterListCategoryWise' },

      { name: 'Class Wise Defaulter Report', url: '/dashboard/collect/classWiseDeafulter' },
      { name: 'Balance Sheet Report', url: '/dashboard/collect/balancesheet' },
      { name: 'Fee Report Month-Wise', url: '/dashboard/monthWiseStatus' },
      { name: 'Sibling Reconcillation Report', url: '/dashboard/collect/siblingReconciliationReport' },
      { name: 'Open Balance Report', url: '/dashboard/collect/openBalanceReport' },
      { name: 'Student Fee Category Wise Concession Report', url: '/dashboard/collect/feeCategoryWiseConcession' },
      { name: 'Custom Create Concession Report', url: '/dashboard/concession/customConcessionReport' },
      { name: 'Custom Concession Report', url: '/dashboard/concession/customConcessionView' },
      { name: 'Student Category Report', url: '/dashboard/collect/studentCategoryReport' },
      { name: 'Fee Structure Report', url: '/dashboard/collect/FeeCollectionReportComponent' },
      { name: 'Fee Summary Report', url: '/dashboard/collect/feeSummaryReportComponent' },
      { name: 'Fee Royalty Report', url: '/dashboard/collect/royaltyReportComponent' },
      { name: 'SMS History Report', url: '/dashboard/collect/smsHistory' },

      { name: 'Columns Configuration', url: '/dashboard/masterSettings/config-field' },
      { name: 'Select Fee Template', url: '/dashboard/masterSettings/selectFeeTemplate' },
      { name: 'Receipt Font Size', url: '/dashboard/masterSettings/receiptSettings' },
      { name: 'Payment Settings', url: '/dashboard/masterSettings/paymentSettings' },
      { name: 'Upload Documents', url: '/dashboard/uploadEdocs' },
      { name: 'E-Docs', url: '/dashboard/uploadEdocs' },
      // Add more modules here
    ];

    this.moduleList = allModules.filter(module =>
      module.name.toLowerCase().includes(query)
    );
  }

  navigateTo(url: string, name: string) {
    console.log("navigateto", url, name)
    this.router.navigate([url], { queryParams: { search: name } });
    // this.router.navigate([url]);
    this.clearSearch();
  }

  onKeyDown(event: KeyboardEvent) {

    loadSidebar()
    if (event.key === 'ArrowDown') {
      this.focusedIndex = (this.focusedIndex + 1) % this.moduleList.length;
      this.scrollToFocusedItem();
    } else if (event.key === 'ArrowUp') {
      this.focusedIndex = (this.focusedIndex - 1 + this.moduleList.length) % this.moduleList.length;
      this.scrollToFocusedItem();
    } else if (event.key === 'Enter' && this.focusedIndex >= 0) {
      this.navigateTo(this.moduleList[this.focusedIndex].url, this.moduleList[this.focusedIndex].name);
    }

    function loadSidebar() {
        var nextElem = $(this).next();
        var thisLink = $(this);
        if (nextElem.hasClass("br-menu-sub")) {
          if (nextElem.is(":visible")) {
            thisLink.removeClass("show-sub");
            nextElem.slideUp();
          } else {
            $(".br-menu-link").each(function () {
              $(this).removeClass("show-sub");
            });
            $(".br-menu-sub").each(function () {
              $(this).slideUp();
            });
            thisLink.addClass("show-sub");
            nextElem.slideDown();
          }
          return false;
        } else {
          $(".br-menu-link").each(function () {
            $(this).removeClass("show-sub");
          });

          $(".br-menu-sub").each(function () {
            $(this).slideUp();
          });

          thisLink.addClass("show-sub");
        }
     
    }
  }

  scrollToFocusedItem() {
    const focusedElement = document.querySelector(`.global-search ul li:nth-child(${this.focusedIndex + 1})`);
    if (focusedElement) {
      focusedElement.scrollIntoView({ block: 'nearest' });
    }
  }

  clearSearch() {
    this.searchQuery = '';
    this.moduleList = [];
  }
}
