import { Injectable } from '@angular/core';
import {Router} from '@angular/router'
import { HttpClient,HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {UserService} from '../../SigninSignoutO/Services/user.service'
import {AllApisService} from '../../all-apis.service';
// import {addFeeModel} from './ModelClass/addFeeModel';

@Injectable({
  providedIn: 'root'
})
export class SchedulingService {

  constructor(
    private _http: HttpClient ,
    private router:Router,
    private userservice : UserService,
    private allApis : AllApisService
    )
  {};
 // baseUrl = "http://192.168.0.32:8080";

  addFee = this.allApis.addFee;    //"http://localhost:8080";
 // fatchFeeDetails=this.allApis.fatchFeeDetails;
 // updateFeeDetails = this.allApis.updateFeeDetails;
  getFeeCat=this.allApis.getFeeCategory;

  // addFeeParticulars=this.allApis.fetchFeeDetails;

  errorData: {};

  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'Authorization': 'Bearer ' +localStorage.getItem('token')
  //           // 'ID': emp.UserID,
  //   })
  // };
  
  getSpecialCategory() {
    this.allApis.httpOptions.params=new HttpParams();
    return this._http.get<any>(this.allApis.specialCategory, this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  

  //Receive scheduling
  receiveFeeDeatils(cls , sec,session,specialCategoryVar='N/A',feeType){
    let params=new HttpParams();
    params=params.append('class',cls);
    params=params.append('section',sec);
    params=params.append('session',session);
    params=params.append('specialCategoryVar',specialCategoryVar);
    params = params.append('feeType',feeType);
    this.allApis.httpOptions.params=params;
    console.log("this.allApis.httpOptions : ",this.allApis.httpOptions);
    return this._http.get<any>(this.allApis.fatchFeeDetails,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  //Add scheduling
  sendFeeDetails(arr:any) {
    return this._http.post<any>(this.addFee,arr,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  //Update Scheduling
  updateFeeDeatils(updteData){
    // console.log("params : ",secClaQ);
    return this._http.patch<any>(this.allApis.updateFeeDetails,updteData,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  getFeeCategory(){
    return this._http.get<any>(this.getFeeCat,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  getInstallments(session){
    let params=new HttpParams();
    
    params=params.append('session',session);
    this.allApis.httpOptions.params=params;

       
    return this._http.get<any>(this.allApis.getInstallments,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }


// error handling
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }

}
