import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AllApisService } from '../all-apis.service'


@Injectable({
  providedIn: 'root'
})
export class PaymentListService {

  errorData: {};

  constructor(private _http: HttpClient,
    private allApi: AllApisService) { }




  getUserTransactionsListUrl = this.allApi.baseurl + '/pay/schoolTransactionList';
  updateOnlinePaymentUrl = this.allApi.baseurl+'/pay/updateStatus';
  

  getUserTransactionsList(startDate: string, endDate: string , status : string) {

    let params = new HttpParams();

    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    params = params.append('status' , status)

    this.allApi.httpOptions.params = params;

    return this._http.get<any>(this.getUserTransactionsListUrl, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  updateOnlinePaymentStatus(obj){
    return this._http.post<any>(this.updateOnlinePaymentUrl,obj);
  }

 



  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }


}
