import { Injectable } from '@angular/core';
import { SignInModel } from '../ModelClasses/signinModel'; // new SignInModule
import {RegisterModel}from '../ModelClasses/registerModel'
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AllApisService } from '../../all-apis.service'


@Injectable({
  providedIn: 'root'
})
export class UserService {
  
  errorData: {};
  fatchSchoolNames = this.allApi.schoolNameListUrl;
  signInFromMobileUrl = this.allApi.baseurl + '/user/authenticate_from_mobile';

  constructor(private _http: HttpClient,
              private allApi:AllApisService ) { }

  sendOtp(mobileNo: any, forResetPassword: boolean = false) {
    return this._http.post<any>(this.allApi.sendOtp, {mobileNo: mobileNo, forResetPassword: forResetPassword}).pipe(catchError(this.handleError));
  }

  verifyOtp(mobileNo: any, otp: any) {
    return this._http.post<any>(this.allApi.verifyOtp,{mobileNo: mobileNo, otp: otp}).pipe(catchError(this.handleError));
  }
 /*  user signin */0
  logMeIn(mailPass:SignInModel) {
    return this._http.patch<any>(this.allApi.signinUrl,mailPass).pipe(catchError(this.handleError));
  }

 /*  User signup */
  registerMe(namePassEmail:RegisterModel) {
    return this._http.post<any>(this.allApi.signupUrl,namePassEmail).pipe(catchError(this.handleError));
  }

  resetPassward(mobileNo: any, passwordVar: any, userDetailId: any) {
    return this._http.patch<any>(this.allApi.resetPass,{mobileNo: mobileNo, reset: passwordVar, userDetailId: userDetailId}).pipe(catchError(this.handleError));
  }
 
  signInWithSMToken(paramObj) {
    return this._http.post<any>(this.allApi.signInWithSMToken, paramObj).pipe(catchError(this.handleError));
  }

  signInWithMobileToken(token: string, studentId: string) {
    localStorage.setItem('token', token);

    //Initilization Token
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.allApi.getToken()
    });
    this.allApi.httpOptions.headers = headers;
    console.log(this.allApi.httpOptions);
    return this._http.post<any>(this.signInFromMobileUrl, {authToken: token, studentId: studentId}, this.allApi.httpOptions).pipe(catchError(this.handleError));
  }

  logMeInWithSm(signInModel: SignInModel) {
    return this._http.post<any>(this.allApi.signInSmUrl, signInModel).pipe(catchError(this.handleError));
  }

  // getUserRoles(){
  //   return this._http.post<any>(this.allApi.userRoleListUrl,this.allApi.httpOptions).pipe(catchError(this.handleError));
  // }

  getUserID(){
    return localStorage.getItem('user_idO')
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }

}
